<template lang='pug'>
.client-messages-tab
  h3 Messages
  .chat-container()
    //- need the key to force a new component when selected user changes
    //- which simplifies initialization
    chat-box(
      :key='`chat-c${coach.id}-u${user.id}`'
      :coach-user-id='coach.id'
      :exec-user-id='user.id'
    )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
  'chat-box': require('@/app-public/common/other/chat-box').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Client Messages',
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      coach: 'user',
      currentCoachId: 'userId',
    }),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
  },
  watch: {},
};
</script>

<style lang='less'>
.client-messages-tab {
  .chat-container {
    border: 1px solid #ddd;
    position: fixed;
    left: 320px;
    top: 242px;
    bottom: 0;
    right: 0;
    z-index: 100;

    @media @mq-small-only {
      left: 0px;
    }

    .chat {
      max-width: 600px;
      margin: auto;
      background: transparent;

      .input-bar {
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        padding: 2px;

        text-area {
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
