<template lang='pug'>
main-layout#page-executive-dashboard(
  full-width
  no-footer
)
  template(v-if='user.isExecutive && user.currentCoachId')
    template(v-if='getSessionsRequest.isPending || getCurrentCoachRequest.isPending')
      p Loading session data...
    template(v-else-if='getSessionsRequest.isError || getCurrentCoachRequest.isError')
      error-message(:request-status='getSessionsRequest')
      error-message(:request-status='getCurrentCoachRequest')
    template(v-else-if='getSessionsRequest.isSuccess && getCurrentCoachRequest.isSuccess')
      page-with-chatbox(:user='user' :coach='coach')
        template(v-slot:title)
          .title
            h2 Executive Dashboard
            p {{ creditsBalance }} credit{{ creditsBalance !== 1 ? 's' : '' }} remaining
        template(v-slot:tools)
          router-link.icon-link(:to='{name: "executive-documents"}')
            icon(name='file-upload')
        template(v-slot)
          .executive-dashboard-content
              .executive-dashboard-status
                .executive-dashboard-coach-status
                  notification-square(theme='navy')
                    .executive-dashboard-coach-status-content
                      profile-photo(
                        :user='coach' size='large'
                        placard-text="Your Coach"
                      )
                      .coach-info
                        .coach-name {{ coach.fullName }}
                        v-button(to-named-route='coach-profile' theme='white' size='large') View Profile
                        .coach-more
                          p(v-if="coachCanScheduleSessions") Your coach is allowed to schedule sessions on your behalf. You can change this in 
                            router-link(:to='{name: "account"}') Account Settings

                .executive-dashboard-questionnaire-status
                  notification-square(v-if="user.questionnaireRequired" message='Please update your progress before scheduling your next session.')
                    v-button(to-named-route='executive-progress-overview' theme='white' size='large') Update Your Progress
                  notification-square(v-else-if="user.questionnaireSelectionRequired" message='Please choose your areas of focus.')
                    v-button(to-named-route='executive-progress-latest-questionnaire' theme='white' size='large') Choose Your Areas of Focus
                  notification-square(v-else-if="!user.questionnaireDisabled" theme='white')
                    p You've chosen to work on the following topics:
                    questionnaire-selection(:questionnaire='userQuestionnaire')
                    v-button(to-named-route='executive-progress-overview' theme='white' size='large') View Progress
                    v-button(to-named-route='executive-documents' theme='white' size='large') View Documents
              .sessions-dash
                month-sessions-list(:disabled='!canSchedule' :month-index='0' @book='clickScheduleSession')
                //- .credit-rollover-notice
                //-   | On the 1st of the month, any remaining credits will rollover and you will receive your new monthly credit allowance.
                //-   router-link(:to='{name: "credits-faq"}' target='_blank')
                //-     icon(name='question-circle')
                month-sessions-list(:disabled='!canSchedule' :showScheduleSession='false' :month-index='1' @book='clickScheduleSession')
                month-sessions-list(:disabled='!canSchedule' :showScheduleSession='false' :month-index='2' show-more @book='clickScheduleSession')

      auth-user-booking-popup(
        ref='bookingPopup'
        :coach='coach'
        session-type='coaching'
      )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'page-with-chatbox': require('@/app-public/common/layout/page-with-chatbox')
    .default,
  'chat-box': require('@/app-public/common/other/chat-box').default,
  'profile-photo': require('@/components/other/profile-photo').default,
  'auth-user-booking-popup': require('@/app-public/common/other/auth-user-booking-popup').default,
  'month-sessions-list': require('./month-sessions-list').default,
  'questionnaire-selection': require('@/components/other/questionnaire-selection')
    .default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Executive Dashboard',
  },
  data() {
    return {
      clientsFilter: null,

      showChat: false,
      clientsListSearchQuery: '',
      cancelSessionPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/booking', ['creditsBalance', 'userSessions']),
    ...mapGetters('authUser/profile', {
      user: 'user',
      coach: 'userCurrentCoach',
    }),
    ...mapRequestStatuses({
      getSessionsRequest: 'authUser/booking/GET_USER_SESSIONS',
      getCurrentCoachRequest: 'authUser/profile/GET_USER_CURRENT_COACH',
    }),
    userQuestionnaire() {
      const { questionnaireSelection } = this.user;
      return {
        selection: questionnaireSelection,
      };
    },
    chatIsOpen() {
      return this.showChat;
    },
    canSchedule() {
      return !(
        this.user.questionnaireRequired
        || this.user.questionnaireSelectionRequired
      );
    },
    coachCanScheduleSessions() {
      return this.user.coachCanScheduleSessions || false;
    },
  },
  watch: {},
  methods: {
    toggleChatVisiblity() {
      this.showChat = !this.showChat;
    },

    clickScheduleSession(args) {
      if (!this.user.questionnaireRequired) {
        this.$refs.bookingPopup.open(args);
      }
    },
  },
  beforeMount() {
    let redirect;

    // redirects
    if (this.user.isExecutive) {
      // if the user does not have a coach yet, we take them back to the onboarding
      if (!this.user.currentCoachId) {
        redirect = 'welcome';
      }
    } else if (this.user.isCoach) {
      redirect = 'coach-dashboard';
    } else if (this.user.isCompanyAdmin) {
      redirect = 'company-dashboard';
    }

    if (redirect) {
      this.$router.replace({ name: redirect });
    } else {
      this.$store.dispatchApiAction('authUser/profile/GET_USER_CURRENT_COACH');
      this.$store.dispatchApiAction('authUser/booking/GET_USER_SESSIONS');
    }
  },
};
</script>

<style lang='less'>
#page-executive-dashboard {
  .main-content {
    height: 100vh;
  }

  .title {
    padding: 10px;
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0;
      font-size: 1.1em;
      line-height: 1.1em;
      padding-bottom: 2px;
    }

    p {
      margin: 0;
      padding: 0;
      padding-left: 10px;
      line-height: 1em;
    }
  }

  .executive-dashboard-status {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;

    > div {
      margin: 2px;
    }

    .notification-square {
    }

    .executive-dashboard-coach-status {
      flex: 1 1 auto;

      .executive-dashboard-coach-status-content {
        padding: 8px;
      }

      .profile-photo {
        margin: auto;
      }

      .coach-info {
        display: inline-block;
      }

      .coach-name {
        font-size: 1.2em;
        line-height: 1.2em;
        font-weight: 700;
        margin: 8px 0;
      }
      
      .coach-more {
        font-size: 0.8em;
        max-width: 300px;
      }
    }

    .executive-dashboard-questionnaire-status {
      flex: 1 1 500px;
    }
  }
}
</style>
