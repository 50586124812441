/* eslint-disable object-property-newline */

import _ from 'lodash';
import Vue from 'vue';
import Router from 'vue-router';
import promiseDelay from 'promise-delay';
import storage from 'local-storage-fallback'; // polyfill storage - falls back to cookies, memory, etc

import analytics from '@/utils/analytics';

import store from './vuex-store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {
      x: 0,
      y: 0,
    };
  },
  routes: [
    // public pages
    {
      path: '/',
      name: 'home',
      component: require('./pages/login').default,
    },
    {
      path: '/login',
      name: 'login',
      component: require('./pages/login').default,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: require('./pages/forgot-password').default,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: require('./pages/reset-password').default,
    },
    {
      path: '/accept-invite',
      name: 'accept-invite',
      component: require('./pages/accept-invite').default,
    },
    {
      path: '/360-evaluation',
      name: '360-evaluation',
      component: require('./pages/360-evaluation').default,
    },
    {
      path: '/company-signup',
      name: 'company-signup',
      component: require('./pages/company-signup').default,
    },
    {
      path: '/coach-signup',
      name: 'coach-signup',
      component: require('./pages/coach-signup').default,
    },

    /*
    {
      path: '/how-it-works',
      name: 'how-it-works',
      component: require('./pages/how-it-works').default,
    },
    {
      path: '/for-coaches',
      name: 'coach-landing',
      component: require('./pages/coach-landing').default,
    },
    {
      path: '/for-companies',
      name: 'company-landing',
      component: require('./pages/company-landing').default,
    },
    {
      path: '/for-executives',
      name: 'executive-landing',
      component: require('./pages/executive-landing').default,
    },

    {
      path: '/about-us',
      name: 'about-us',
      component: require('./pages/about-us').default,
    },
    {
      path: '/our-approach',
      name: 'our-approach',
      component: require('./pages/our-approach').default,
    },
    {
      path: '/platform',
      name: 'platform',
      component: require('./pages/platform').default,
    },
    {
      path: '/press',
      name: 'press',
      component: require('./pages/press').default,
    },
    {
      path: '/blog',
      name: 'blog',
      component: require('./pages/blog').default,
    },
    */


    {
      path: '/terms-of-service',
      name: 'terms-of-service',
      component: require('./pages/terms-of-service').default,
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: require('./pages/privacy-policy').default,
    },
    {
      path: '/faq',
      name: 'faq',
      component: require('./pages/faq').default,
    },
    {
      path: '/credits-faq',
      name: 'credits-faq',
      component: require('./pages/credits-faq').default,
    },

    // pages for logged in users
    {
      path: '/evaluations',
      name: 'executive-evaluations',
      component: require('./pages/executive-evaluations').default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/documents',
      name: 'executive-documents',
      component: require('./pages/executive-documents').default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard',
      name: 'executive-dashboard',
      component: require('./pages/executive-dashboard').default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/progress',
      component: require('./pages/executive-progress').default,
      name: 'executive-progress',
      meta: {
        requiresAuth: true,
      },
      props: true,
      children: [{
        path: 'latest',
        name: 'executive-progress-latest-questionnaire',
        component: require('./pages/executive-progress/questionnaire/latest').default,
      },
      {
        path: 'overview',
        name: 'executive-progress-overview',
        component: require('./pages/executive-progress/progress-subpage').default,
      },
      {
        name: 'executive-progress-questionnaire',
        path: 'questionnaire/:questionnaireId',
        component: require('./pages/executive-progress/questionnaire').default,
        props: true,
      },
      ],
    },
    {
      path: '/welcome',
      component: require('./pages/welcome').default,
      name: 'welcome',
      meta: {
        requiresAuth: true,
      },
      children: [{
        path: 'complete-profile',
        name: 'complete-profile',
        component: require('./pages/welcome/complete-profile-redirect').default,
      }, {
        path: 'profile',
        name: 'profile',
        component: require('./pages/welcome/profile-subpage').default,
      },
      {
        path: 'assessment',
        name: 'assessment',
        component: require('./pages/welcome/assessment-subpage').default,
      },
      {
        path: 'availability',
        name: 'availability-settings',
        component: require('./pages/welcome/availability-subpage').default,
      },
      {
        path: 'select-your-coach',
        name: 'coach-matches',
        component: require('./pages/welcome/matches-subpage').default,
      },
      {
        path: 'start-coaching',
        name: 'coach-approval',
        component: require('./pages/welcome/coach-approval-subpage').default,
      },
      {
        path: 'evaluation-respondents',
        name: 'evaluation-respondents',
        component: require('./pages/welcome/evaluation-respondents-subpage').default,
      },
      ],
    },

    {
      path: '/account',
      name: 'account',
      component: require('./pages/account').default,
      meta: {
        requiresAuth: true,
      },
    },

    // - Company admin pages
    {
      path: '/company',
      component: require('./pages/company-dashboard').default,
      meta: {
        requiresAuth: true,
      },
      children: [{
        path: '',
        name: 'company-dashboard',
        redirect: {
          name: 'company-overview',
        },
      }, {
        path: 'overview',
        name: 'company-overview',
        component: require('./pages/company-dashboard/company-overview').default,
        meta: {
          requiresAuth: true,
        },
      }, {
        path: 'profile',
        name: 'company-profile',
        component: require('./pages/company-dashboard/company-profile').default,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'users',
        name: 'company-users-dashboard',
        component: require('./pages/company-dashboard/company-users-dashboard').default,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'users/:selectedCompanyUserId',
        component: require('./pages/company-dashboard/company-users-dashboard/user-detail-page').default,
        props: true,
        children: [{
          path: '',
          name: 'company-user-details',
          redirect: {
            name: 'company-user-basic-info',
          },
        },
        {
          path: 'basic-info',
          name: 'company-user-basic-info',
          component: require('./pages/company-dashboard/company-users-dashboard/user-detail-page/basic-info-subpage').default,
          props: true,
        },
        {
          path: 'schedule',
          name: 'company-user-schedule',
          component: require('./pages/company-dashboard/company-users-dashboard/user-detail-page/schedule-subpage').default,
          props: true,
        },
        {
          path: 'coach',
          name: 'company-user-coach',
          component: require('./pages/company-dashboard/company-users-dashboard/user-detail-page/coach-subpage').default,
          props: true,
        },
        {
          path: 'auth',
          name: 'company-user-auth',
          component: require('./pages/company-dashboard/company-users-dashboard/user-detail-page/auth-subpage').default,
          props: true,
        },
        {
          path: 'data',
          name: 'company-user-data',
          component: require('./pages/company-dashboard/company-users-dashboard/user-detail-page/data-subpage').default,
          props: true,
        },
        {
          path: 'delete',
          name: 'company-user-delete',
          component: require('./pages/company-dashboard/company-users-dashboard/user-detail-page/delete-subpage').default,
          props: true,
        },
        ],
        meta: {
          requiresAuth: true,
        },
      },
      ],
    },


    {
      path: '/coach-profile/:coachSlug?',
      name: 'coach-profile',
      component: require('./pages/coach-profile').default,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/my-schedule',
      name: 'coach-schedule',
      component: require('./pages/coach-schedule').default,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    // TODO: Rename to coach-clients
    {
      path: '/my-clients',
      name: 'coach-dashboard',
      component: require('./pages/coach-dashboard').default,
      props: true,
      children: [{
        path: ':selectedClientUserId',
        component: require('./pages/coach-dashboard/executive-profile').default,
        props: true,
        children: [{
          path: '',
          name: 'coach-client-dashboard',
          redirect: {
            name: 'client-overview',
          },
          props: true,
        },
        {
          path: 'overview',
          name: 'client-overview',
          component: require('./pages/coach-dashboard/executive-profile/overview-subpage').default,
          props: true,
        },
        {
          path: 'profile',
          name: 'client-profile',
          component: require('./pages/coach-dashboard/executive-profile/profile-subpage').default,
          props: true,
        },
        {
          path: 'documents',
          name: 'client-documents',
          component: require('./pages/coach-dashboard/executive-profile/documents-subpage').default,
          props: true,
        },
        {
          path: 'messages',
          name: 'client-messages',
          component: require('./pages/coach-dashboard/executive-profile/messages-subpage').default,
          props: true,
        },
        {
          path: 'progress',
          name: 'client-progress',
          component: require('./pages/coach-dashboard/executive-profile/progress-subpage').default,
          props: true,
        },
        ],
      }],
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/oauth-callback',
      name: 'oauth-callback',
      component: require('./pages/oauth-callback').default,
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter(t, f, next) {
        analytics.track('fe_logout');
        store.dispatch('auth/browserLogout');
        return next('/');
      },
    },

    {
      path: '*',
      name: 'not-found',
      component: require('./pages/404').default,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // check all matched route records (includes parent) if we need auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['auth/userIsLoggedIn']) {
      return next({
        name: 'login',
        query: {
          redirect: to.fullPath,
        },
      });
    }

    // wait until initial get user request finishes to enter the route
    // this makes the logic in each page easier as we can assume the user is loaded

    let getUserRequest;
    let getEnumsRequest;
    do {
      /* eslint-disable no-await-in-loop */
      // TODO: handle errors, do not allow infinite loop!
      // note - we have to call the get fn on each loop to get the latest value
      getUserRequest = store.getters.requestStatus('authUser/profile/GET_USER');
      getEnumsRequest = store.getters.requestStatus('enums/GET_ENUMS');
      if (getEnumsRequest.isEmpty) store.dispatchApiAction('enums/GET_ENUMS');
      await promiseDelay(50);
    } while (getUserRequest.isPending || getEnumsRequest.isPending);
  }


  // automatically cast any integer params into proper integers
  // so that components can set params to expect a Number
  // NOTE - this is so that when we user router link or programatically navigate
  // that we can set the param as an int
  if (to.params) {
    const castNumericParams = _.mapValues(to.params, (val) => {
      if (parseInt(val).toString() === val) return parseInt(val);
      return val;
    });
    if (_.isEqual(castNumericParams, to.params)) return next();
    return next({
      ...to,
      params: castNumericParams,
    });
  }

  return next();
});

router.afterEach((to, from) => { // eslint-disable-line no-unused-vars
  const pageProps = {};
  if (to.redirectedFrom) pageProps.redirected_from = to.redirectedFrom;

  // we must do this on nextTick so we get the actual URL
  setTimeout(() => analytics.page(to.name, pageProps));

  if (to.meta.disableIntercom || storage.getItem('leq-impersonate-auth-token')) {
    window.intercomSettings = {
      hide_default_launcher: true,
    };
  } else {
    window.intercomSettings = {
      hide_default_launcher: false,
    };
  }
});

export default router;
