import _ from 'lodash';
import {
  buildApiActions,
} from '@/utils/vuex-api-utils';

function updateTargetQuestionnaire(ctx, response) {
  // Also Update Targeted Questionnaire
  const {
    questionnaires,
  } = ctx.getters;

  const questionnaireIndex = questionnaires.findIndex((x) => x.id === response.id);
  if (questionnaireIndex > -1) {
    _.assign(questionnaires[questionnaireIndex], response);
  }
}

export default {
  namespaced: true,
  state() {
    return {
      questionnairesForUserId: null,
      /**
       * Array of questionnaires.
       */
      questionnaires: null,
      newQuestionnaireId: null,
      currentQuestionnaireId: null,
      nextQuestion: null,
    };
  },
  getters: {
    userId: (s, g, rs, rg) => {
      const userId = rg[`${s._moduleParent}profile/userId`];
      return userId;
    },
    user: (s, g, rs, rg) => {
      const user = rg[`${s._moduleParent}profile/user`];
      return user;
    },
    newQuestionnaireId: (state) => state.newQuestionnaireId,
    currentQuestionnaireId: (state) => state.currentQuestionnaireId,
    questionnaires: (state) => state.questionnaires,
    currentQuestionnaire: (state) => (state.questionnaires) ? state.questionnaires.find((x) => x.id === state.currentQuestionnaireId) : null,
    hasQuestionnairesLoaded: (state, getters) => Boolean(state.questionnaires) && Boolean(getters.userId) && getters.userId === state.questionnairesForUserId,
    canLoadQuestionnaires: (state, getters) => Boolean(getters.userId),
    /**
     * Returns true if the target questionnaire is already loaded.
     */
    hasQuestionnaireLoaded: (state, getters) => {
      if (getters.hasQuestionnaireLoaded) {
        const { currentQuestionnaire } = getters;
        return Boolean(currentQuestionnaire);
      }

      return false;
    },
    incompleteQuestionnaire: (state, getters) => {
      let questionnaire;

      if (getters.hasQuestionnairesLoaded) {
        const firstQuestionnaire = getters.questionnaires[0];

        if (firstQuestionnaire && !firstQuestionnaire.completedAt) {
          questionnaire = firstQuestionnaire;
        }
      }

      return questionnaire;
    },
    latestCompletedQuestionnaire: (state, getters) => {
      let questionnaire;

      if (getters.hasQuestionnairesLoaded) {
        const [completed] = getters.questionnaires.filter((x) => Boolean(x.completedAt));
        questionnaire = completed;
      }

      return questionnaire;
    },
    previousCompletedQuestionnaire: (state, getters) => {
      let questionnaire;

      if (getters.hasQuestionnairesLoaded) {
        const [first, second] = getters.questionnaires.filter((x) => Boolean(x.completedAt));
        questionnaire = second;
      }

      return questionnaire;
    },
    // Question/Results
    nextQuestion: (state) => state.nextQuestion,
    /**
     * Returns the results for the current questionnaire.
     */
    results: (state, getter) => {
      const { results } = getter.currentQuestionnaire;
      return results;
    },
  },
  ...buildApiActions({
    /**
     * Loads all questionnaires for the user.
     */
    LOAD_QUESTIONNAIRES: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/questionnaires`,
      }),
      mutation: (state, {
        ctx,
        response,
      }) => {
        state.questionnaires = response;
        state.questionnairesForUserId = ctx.getters.userId;
      },
    },
    /**
     * Creates a new Questionnaire.
     *
     * Can only be created if the account is flagged for a questionnaire required.
     */
    CREATE_QUESTIONNAIRE: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/questionnaires`,
        params: payload,
      }),
      mutation: (state, {
        response,
      }) => {
        const questionnaire = response;
        state.questionnaires.push(questionnaire);
        state.newQuestionnaireId = questionnaire.id;
      },
    },
    /**
     * Reloads the details for the selected questionnaire.
     */
    GET_QUESTIONNAIRE: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/assessments/${ctx.getters.currentQuestionnaireId}`,
      }),
      mutation: (state, { ctx, response }) => {
        updateTargetQuestionnaire(ctx, response);
      },
    },
    /**
     * Retrieves the next questiob from the server, if applicable.
     */
    GET_NEXT_QUESTION: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/assessments/${ctx.getters.currentQuestionnaireId}/next-question`,
      }),
      mutation: (state, { response }) => {
        state.nextQuestion = response;
      },
    },
    /**
     * Submits an answer for the question.
     */
    ANSWER_QUESTION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/assessments/${ctx.getters.currentQuestionnaireId}/answer-question`,
        params: payload, // questionId, answer
        afterSuccess: async (response) => {
          // the question response tells us when the assessement is complete
          // but that info also lives on the user and is used from there elsewhere in the UI
          if (response.assessmentComplete) {
            ctx.dispatch('api-GET_QUESTIONNAIRE');
            // Reload the user too.
            ctx.dispatch(`${ctx.state._moduleParent}profile/api-GET_USER`, null, { root: true });
          }
        },
      }),
      mutation: (state, { response }) => {
        state.nextQuestion = response;
      },
    },
    /**
     * Makes a selection
     */
    MAKE_SELECTION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/assessments/${ctx.getters.currentQuestionnaireId}/make-selection`,
        params: payload, // selection { topics: [] }
      }),
      mutation: (state, { ctx, response }) => {
        updateTargetQuestionnaire(ctx, response);

        // Reload the user too.
        ctx.dispatch(`${ctx.state._moduleParent}profile/api-GET_USER`, null, { root: true });
      },
    },
    // ADMIN ONLY
    /**
     * Deletes a specific Questionnaire.
     */
    FLAG_FOR_QUESTIONNAIRE: {
      action: (ctx, { flag } = {}) => ({
        method: 'put',
        url: `/users/${ctx.getters.userId}/flag/questionnaire`,
        params: {
          flag: flag !== false,
        },
      }),
      mutation: (state, { ctx, response }) => {
        // Reload the user.
        ctx.dispatch(`${ctx.state._moduleParent}profile/api-GET_USER`, null, { root: true });
      },
    },
    // ADMIN ONLY
    /**
     * Deletes a specific Questionnaire.
     */
    DELETE_QUESTIONNAIRE: {
      action: (ctx, payload) => ({
        method: 'delete',
        url: `/users/${ctx.getters.userId}/questionnaires/${payload.questionnaireId}`,
      }),
      mutation: (state, {
        payload,
      }) => {
        state.questionnaires = state.questionnaires.filter((x) => x.id !== payload.questionnaireId);
      },
    },
  }, {
    actions: {

      // called after loading the user id from local storage on app initialization
      setCurrentQuestionnaireId: async (ctx, questionnaireId) => {
        await ctx.commit('SET_CURRENT_QUESTIONNAIRE_ID', questionnaireId);
        await ctx.dispatch('loadQuestionnaires');
        await ctx.dispatch('api-GET_NEXT_QUESTION'); // Always load the next question
      },

      /**
       * Used for loading questionnaires for the current user.
       *
       * Used to prepare access to Questionnaire getter/setters.
       */
      loadQuestionnaires: async (ctx) => {
        const loadQuestionnaires = !ctx.getters.hasQuestionnairesLoaded;

        if (loadQuestionnaires && ctx.getters.canLoadQuestionnaires) {
          await ctx.commit('CLEAR_QUESTIONNAIRES');
          await ctx.dispatch('api-LOAD_QUESTIONNAIRES');
        }
      },

      /**
       * Creates a new questionnaire for the user.
       */
      createQuestionnaire: async (ctx, newQuestionnaire) => ctx.dispatch('api-CREATE_QUESTIONNAIRE', newQuestionnaire),

      /**
       * Loads the specified questionnaire from the server and set it as the curr
       */
      loadQuestionnaire: async (ctx, { questionnaireId, force = false }) => {
        const { currentQuestionnaireId } = ctx.getters;

        if (currentQuestionnaireId !== questionnaireId) {
          await ctx.commit('SET_CURRENT_QUESTIONNAIRE_ID', questionnaireId);
        }

        if (!ctx.hasQuestionnaireLoaded || force) {
          ctx.dispatch('api-GET_QUESTIONNAIRE');
        }
      },

      /**
       * Coach / Admin Only
       */
      waiveQuestionnaire: async (ctx) => ctx.dispatch('api-FLAG_FOR_QUESTIONNAIRE', {
        flag: false,
      }),

      flagForQuestionnaire: async (ctx, { flag = true } = {}) => ctx.dispatch('api-FLAG_FOR_QUESTIONNAIRE', {
        flag,
      }),

      /**
       * Admin Only
       *
       * Deletes the target questionnaire.
       */
      deleteQuestionnaire: async (ctx, questionnaireId) => ctx.dispatch('api-DELETE_QUESTIONNAIRE', {
        questionnaireId,
      }),

    },
    mutations: {
      SET_CURRENT_QUESTIONNAIRE_ID: (state, questionnaireId) => {
        state.currentQuestionnaireId = questionnaireId;
      },
      CLEAR_QUESTIONNAIRES: (state) => {
        state.questionnaires = null;
        state.questionnairesForUserId = null;
      },
    },
  }),
};
