<template lang='pug'>
main-layout
  h3 Forgot your password?
  p No problem! What email did you use to sign up?
  form-group
    form-row
      form-input(
        type='email' v-model='email'
        label='Email' placeholder='Your email'
        required
      )
      form-input(type='container')
        v-button(
          @click='requestPasswordReset'
          :disabled='$vv.$error'
          :request-status='passwordResetRequest'
          loading-text='Requesting reset...'
        ) Send password reset link
  form-row(v-if='passwordResetRequest.isSuccess')
    .bold Check your email for a password reset link!
  error-message(:request-status='passwordResetRequest')
</template>

<script>
import { mapGetters } from 'vuex';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};


export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Forgot Password',
  },
  data() {
    return {
      email: this.user ? this.user.email : '',
    };
  },
  watch: {
    user: { handler() { this.email = this.user.email; } },
  },
  computed: {
    ...mapGetters('authUser/profile', ['user']),
    ...mapRequestStatuses({
      passwordResetRequest: 'auth/REQUEST_PASSWORD_RESET',
    }),
  },
  methods: {
    requestPasswordReset() {
      if (this.$hasError()) return;
      this.$store.dispatchApiAction('auth/REQUEST_PASSWORD_RESET', { email: this.email });
    },
  },
};
</script>

<style lang='less'>
</style>
