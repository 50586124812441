<template lang='pug'>
.questionnaire-table
  h4 Questionnaire History
  table-component.compact(
    :data="questionnairesRows"
    :show-filter="false" :show-caption="false" :sortable='false'
    filter-no-results="No questionnaires available"
  )
    table-column2(label="Completed At" show="completedAt" type="date")
    table-column2(label="Topics" show="topics")
    table-column2(label="Note" show="note")
    table-column(label="Actions")
      template(slot-scope="row")
          v-button(@click='viewQuestionnaireClicked(row.id)' size="small") View

</template>

<script>
import _, { isNumber } from 'lodash';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    questionnaires: {},
  },
  data() {
    return {
      creditsToAdd: null,
    };
  },
  computed: {

    questionnairesRows() {
      return (this.questionnaires || []).map((x) => ({
        id: x.id,
        topics: x.selection.topics.join(', '),
        note: x.selection.note,
        completedAt: x.completedAt,
      }));
    },

  },
  methods: {
    viewQuestionnaireClicked(id) {
      this.$emit('viewQuestionnaire', {
        id,
      });
    },
  },
};
</script>

<style lang='less'>
</style>
