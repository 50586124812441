<template lang='pug'>
.client-progress-tab
  h3 Progress
  form-group(label='AdvisorEY Progress')
    form-row(no-inputs)
      template(v-if='getQuestionnaireRequest.isPending')
        p loading...
      template(v-else-if='getQuestionnaireRequest.isError')
        error-message(:request-status='getQuestionnaireRequest')
      template(v-else)
        template(v-if='user.questionnaireCompletedAt')
          template(v-if='questionnaire')
            assessment-results(:assessment='questionnaire' :assessment-compare='questionnaire')
            p Wants to work on:
            questionnaire-selection(:questionnaire='questionnaire')
            br
            questionnaire-list(:questionnaires='questionnaires' @viewQuestionnaire='viewQuestionnaire')
            br
            h4 Progress Actions
            template(v-if='!user.questionnaireFlag')
              p You can request a new progress report from your client. They will not be able to schedule until it is completed.
              v-button(
                @click='requestNewProgressReportHandler' confirm-click
                icon='clipboard-check'
                size='small'
                :disabled='$vv.$error'
                :request-status='requestNewProgressReport'
                loading-text='Requesting new report...') Request New Progress Report
            template(v-else)
              p The user has been prompted to take a new progress report. They must finish this report before scheduling their next session. 
              p You can optionally waive this progress assessment.
              v-button(
                @click='waiveProgressReportHandler' confirm-click
                icon='clipboard-check'
                size='small'
                :disabled='$vv.$error'
                :request-status='waiveProgressReport'
                loading-text='Waiving report...') Waive Progress Report
        template(v-else)
          p This user has not completed their questionnaire.

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
  'assessment-results': require('@/components/other/assessment-results')
    .default,
  'questionnaire-selection': require('@/components/other/questionnaire-selection')
    .default,
  'questionnaire-list': require('@/components/other/questionnaire-list')
    .default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Client Progress',
    };
  },
  data() {
    return {
      selectedQuestionnareId: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['authUserId']),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
    ...mapGetters('selectedUser/assessment', ['assessment']),
    ...mapGetters('selectedUser/evaluations', {
      evaluations: 'userEvaluations',
    }),
    ...mapGetters('selectedUser/questionnaire', {
      latestQuestionnaire: 'latestCompletedQuestionnaire',
      questionnaires: 'questionnaires',
    }),
    ...mapRequestStatuses({
      getQuestionnaireRequest: 'selectedUser/questionnaire/LOAD_QUESTIONNAIRES',
      requestNewProgressReport: 'selectedUser/questionnaire/FLAG_FOR_QUESTIONNAIRE',
      waiveProgressReport: 'selectedUser/questionnaire/FLAG_FOR_QUESTIONNAIRE',
    }),

    selectedQuestionnaire() {
      const { selectedQuestionnareId } = this;
      return (selectedQuestionnareId != null) ? this.questionnaires.find((x) => x.id === selectedQuestionnareId) : null;
    },

    questionnaire() {
      return this.selectedQuestionnaire || this.latestQuestionnaire;
    },
  },
  watch: {},
  beforeMount() {
    this.$store.dispatch('selectedUser/questionnaire/loadQuestionnaires');
  },
  methods: {

    async requestNewProgressReportHandler() {
      await this.$store.dispatch('selectedUser/questionnaire/flagForQuestionnaire');
    },

    async waiveProgressReportHandler() {
      await this.$store.dispatch('selectedUser/questionnaire/waiveQuestionnaire');
    },

    viewQuestionnaire({ id }) {
      this.selectedQuestionnareId = id;
    },

  },
};
</script>

<style lang='less'>
.client-progress-tab {
}
</style>
