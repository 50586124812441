<template lang='pug'>
//- TODO: v-if='value' is stopping some errors when the address being passed in is undefined
//- need to fix this and how we bind here...

//- TODO make required and requiredWarning work with a single error
//- message for the whole block

form-group(:label='label' :disabled='disabled' v-if='value')
  form-row
    form-input(v-model='value.websiteUrl' type='url' label='Personal Website')
    form-input(v-model='value.twitterUsername' type='string' label='Twitter Handle')
    form-input(v-model='value.linkedinUrl' type='url' label='LinkedIn profile')

</template>


<script>
import { mapGetters } from 'vuex';

import {
  stateOptionsForCountry, postalCodeLabel, stateLabel,
} from '@/utils/local';

// TODO: fix v-model handling
// https://github.com/vuejs/vue/issues/4373
// technically we shouldnt be changing the passed in object directly
// but keeping a local copy and calling `$emit('input')` on changes

export default {
  props: {
    label: {
      type: String,
      default: 'Online Presence',
    },
    value: {
      type: Object, // passed in via v-model
      default: () => {},
    },
    compact: Boolean,
    required: Boolean,
    requiredWarning: Boolean,
    requiredMessage: String,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters('enums', ['countryOptions']),
  },
  methods: {
    stateOptionsForCountry, postalCodeLabel, stateLabel,
  },
};
</script>

<style lang='less'>


</style>
