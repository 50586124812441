<template lang='pug'>
.faq-item
  .question(@click='isOpen = !isOpen') {{q}}
  .answer(v-show='isOpen')
    slot
</template>

<script>
export default {
  props: ['q'],
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang='less'>
.faq-item {
  margin-bottom: 5px;

  .question {
    font-weight: 700;
    cursor: pointer;
    padding: 5px 0;
  }
  .answer {
    font-style: italic;
    margin-bottom: 10px;
  }
}
</style>
