<template lang='pug'>
.onboarding-profile-step

  template(v-if='userIsInDemoMode')
    error-message(warning) THIS IS A DEMO - fill in as much or as little as you like
    br

  h3 Your Profile

  //-- Basic Info
  todo-section(title='Basic Information' :complete='profileComplete' :no-action='true')
  user-identity-input(v-model='user')
  online-presence-input(v-model='user')
  address-input(v-model='user.address' required-warning)

  //-- Resume
  template(v-if='user.isExecutive || user.isCoach')
    todo-section(
      title='Resumé Information'
      details=''
      :complete='resumeComplete'
      :no-action='true'
    )
    form-row
      resume-list-editor(v-model='user.educationHistory' type='education')
    form-row
      resume-list-editor(v-model='user.careerHistory' type='career')
    form-row
      resume-list-editor(v-model='user.accoladeHistory' type='accolades')

  //-- Biography
  template(v-if='user.isExecutive || user.isCoach')
    todo-section(
      title='Your Biography'
      :complete='bioComplete'
      :no-action='true'
    )
    form-row(v-if='storeUser.isCoach')
      form-input(
        v-model='user.tagline' label='Coaching Tagline'
        instructions='This will be featured on your profile'
        required-warning
        :max-length='150'
      )
    form-row
      form-input.bio-input(
        v-model='user.bio' type='textarea'
        label='Your Short Bio / Summary'
        :max-length='1000'
        instructions='1000 characters max'
        placeholder='Remember your resume includes a lot of info already'
        required-warning
      )

  template(v-if='storeUser.isExecutive')
    todo-section(
      title='Set your matching preferences'
      :complete='execPrefsComplete'
      :no-action='true'
    )
    user-exec-preferences(v-model='user.executivePreferences' :skill-options='computedSkillOptions' :language-options='languageOptions')

    todo-section(
      title='Share Information'
      :complete='true'
      :no-action='true'
    )
    form-row
      form-input(v-model='user.shareEmailWithCoach' type='dropdown' label='Do you want to share your email with your coach?')
        form-input-option(:value='true') Share My Email With My Coach
        form-input-option(:value='false') Do Not Share My Email With My Coach

  template(v-if='storeUser.isCoach')
    todo-section(
      title='Set your coaching preferences'
      :complete='coachSettingsComplete'
      :no-action='true'
    )
    form-row
      form-input(
        v-model='user.coachSettings.languages' type='multi-checkbox'
        label='What languages are you comfortable coaching in? (Max of 4)'
        :options='languageOptions' :default-value='["en"]'
        :max-length='4'
        required
      )

    form-row
      form-input(
        v-model='user.coachSettings.industries' type='multi-checkbox'
        label='What industries are you most familiar with?'
        :options='industryOptions'
      )
      form-input(
        v-model='user.coachSettings.skills' type='multi-checkbox'
        label='What areas/skills do you coach?'
        :options='computedSkillOptions'
      )
      //- form-input(
      //-   v-if='(user.coachSettings.industries || []).includes("other")'
      //-   v-model='user.coachSettings.industriesOther'
      //-   label='Other industries?'
      //- )
    form-row
      form-input(
        v-model='user.coachSettings.companySizes' type='multi-checkbox'
        label='What company sizes are you most familiar with?'
        :options='companySizeOptions'
      )

    form-row
      form-input(v-model='user.coachSettings.gender' type='dropdown' label='Do you have a preference for the gender identity of your clients?')
        form-input-option(:value='null') No Preference
        form-input-option(value='male') Male
        form-input-option(value='female') Female

  form-row(v-if='(user.isExecutive && !user.currentCoachId) || (user.isCoach && user.coachStatus !== "activated")')
    v-button(
      to-named-route='assessment' size='large' icon='arrow-right' theme='transparent-dark'
      :disabled='!onboardingProgress.profile'
    ) Continue

  save-bar(
    ref='saveBar'
    :draft-value='user' :store-value='storeUser'
    :update-request='updateUserRequest'
    :save-disabled='$vv.$error && false'
    @save='saveButtonHandler' @reset='resetToStoreState'
  )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

const components = {
  'user-identity-input': require('@/components/forms/user-identity-input')
    .default,
  'online-presence-input': require('@/components/forms/online-presence-input')
    .default,
  'address-input': require('@/components/forms/address-input').default,
  'user-exec-preferences': require('@/components/forms/user-exec-preferences')
    .default,
  'resume-list-editor': require('@/components/other/resume-list-editor').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      user: {},
    };
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'emailSharingOptions',
      'companySizeOptions',
      'countryOptions',
    ]),
    ...mapGetters('authUser/profile', {
      storeUser: 'user',
      execPrefsComplete: 'isExecutivePreferencesComplete',
      coachSettingsComplete: 'isCoachSettingsComplete',
      resumeComplete: 'isResumeComplete',
      profileComplete: 'isBasicInfoComplete',
      bioComplete: 'isBioComplete',
      userIsInDemoMode: 'userIsInDemoMode',
    }),
    ...mapGetters('authUser/profile', ['onboardingProgress']),
    ...mapRequestStatuses({
      getUserRequest: 'authUser/profile/GET_USER',
      updateUserRequest: 'authUser/profile/UPDATE_USER',
    }),
    computedSkillOptions() {
      return buildSkillOptions(this.skillOptions, this.storeUser.company);
    },
  },
  watch: {
    storeUser: {
      // deep: true,
      handler() {
        this.resetToStoreState();
      },
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.user = _.cloneDeep(this.storeUser);
    },
    async saveButtonHandler() {
      /*
      Allow saving even if there is a requirement error.
      if (this.$hasError()) {
        return;
      }
      */
      await this.$store.dispatchApiAction(
        'authUser/profile/UPDATE_USER',
        this.user,
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    return next(this.$refs.saveBar.warnAboutUnsavedChanges());
  },
};
</script>

<style lang='less'>
.onboarding-profile-step {
  .bio-input textarea {
    min-height: 250px;
  }
}
</style>
