<template lang='pug'>
.user-details-header
  .user-details-header-details
    .user-page-header
      .user-titles-info
        .user-titles
          .user-name 
            | {{ user.firstName }} {{ user.lastName }}
          .user-roles
            span(v-if='user.cancelledAt') (Deactivated)
            span(v-if='user.isExecutive') Executive
            span(v-if='user.isCompanyAdmin') Company Admin
        .company-titles(v-if='company')
          | {{ company.name }}
      .contact-info
        a.email(v-if='user.email' :href='`mailto:${user.email}`' target='_blank')
          icon(name='envelope' bubbled=true)
          | {{ user.email }}
        a.phone(v-if='user.phone' :href='`tel:${user.phone}`' target='_blank')
          icon(name='phone' bubbled=true)
          | {{ user.phone | phone }}
      nav.account-links
  .user-details-header-content
    slot
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

const components = {};

export default {
  components,
  mixins: [],
  props: {
    user: Object,
    company: Object,
    showExtraDetails: Boolean,
  },
};
</script>

<style lang='less'>
.user-details-header {

  .user-details-header-details {
    background: @navy;
    color: white;
    padding: 10px 16px;
    border-radius: 15px;
  }

  .user-titles-info {
    margin: 8px 0;
  }

  .user-titles {
    .user-name {
      font-size: 1.4em;
    }

    .user-roles {
      >span {
        font-size: 1.1em;
        padding-right: 12px;
      }
    }
  }

  .contact-info {
    margin-top: 4px;

    > a {
      margin-top: 6px;
      display: inline-flex;
      align-items: center;

      font-size: 16px;
      line-height: 18px;
      
      color: white;
      margin-right: 25px;

      :hover {
        color: @gray-blue;
      }

      .icon-content-wrap {
        margin-right: 8px;

        .icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
</style>
