import _ from 'lodash';

export function skillOptionsFromCompany(company) {
  if (!company) {
    return null;
  }

  const customSkills = company.customSkills || {};

  // Break this into pieces.
  const customSkillsString = customSkills.custom || '';

  const customSkillsRawArray = customSkillsString.split(',');
  const customSkillsList = customSkillsRawArray.filter((x) => Boolean(x)).map((x) => ({
    key: _.snakeCase(x),
    value: _.startCase(x),
  }));

  const leadereqSkillsRawArray = customSkills.leadereq || [];
  const leadereqSkillsList = leadereqSkillsRawArray.filter((x) => Boolean(x)).map((key) => ({
    key,
    value: _.startCase(key),
  }));

  const skillOptions = {};

  leadereqSkillsList.forEach((x) => skillOptions[x.key] = x.value);
  customSkillsList.forEach((x) => skillOptions[x.key] = x.value);

  return skillOptions;
}

export function buildSkillOptions(skillOptions, company) {
  let finalSkillOptions;

  if (company && company.skillsType === 'custom') {
    finalSkillOptions = skillOptionsFromCompany(company);
  }

  return finalSkillOptions || skillOptions;
}
