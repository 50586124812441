<template lang='pug'>
.company-subscription-status
  form-group(label='Subscription Status')
    template(v-if='getCompanyRequest.isPending')
      p Loading...
    template(v-else-if='getCompanyRequest.isError')
      error-message(:request-status='getCompanyRequest')
    template(v-else-if='getCompanyRequest.isSuccess')
      form-row(no-inputs)
        //- also a "new" mode but - you shoul
        template(v-if='company.status === "new"')
          | Your company's account is not yet set up - please contact <a href='mailto:support@advisorey.com' target='_blank'>support@advisorey.com</a>
        template(v-else-if='company.status === "demo"')
          | Your company is currently in <b>DEMO</b> mode.
          | Please contact <a href='mailto:support@advisorey.com' target='_blank'>support@advisorey.com</a> to activate it.
        template(v-else-if='company.status === "active"')
          | Your company's subscription is currently <b>ACTIVE</b>.
          template(v-if='getCompanyUsersRequest.isSuccess')
            | You are using <b>{{ activeUsers.length }} out of {{ company.numExecLicenses }}</b> coaching licenses.
          br
          | To add more licenses, please reach out to <a href='mailto:support@advisorey.com' target='_blank'>support@advisorey.com</a>
        template(v-else-if='company.status === "paused"')
          | Your company's subscription is currently <b>PAUSED</b>.
          | Please contact <a href='mailto:support@advisorey.com' target='_blank'>support@advisorey.com</a> to re-activate it.
        template(v-else-if='company.status === "canceled"')
          | Your company's subscription is <b>DEACTIVATED</b>.
          | Please contact <a href='mailto:support@advisorey.com' target='_blank'>support@advisorey.com</a>.

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      addUserPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdmin: 'user',
    }),
    ...mapGetters('companies', {
      activeUsers: 'selectedCompanyActiveUsers',
      company: 'selectedCompany',
    }),
    ...mapRequestStatuses({
      getCompanyRequest: 'companies/GET_COMPANY',
      getCompanyUsersRequest: 'companies/GET_COMPANY_USERS',
    }),
  },
  beforeMount() {
    this.$store.dispatch('companies/getCompanyUsers');
  },
  methods: {},
};
</script>

<style lang='less'>
</style>
