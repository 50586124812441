<template lang='pug'>
.chat
  //- .chat-title {{ chatTitle }}
  .messages-container(ref='messages' @scroll='scrollHandler' @click='messagesClickHandler')
    template(v-if='connectionStatus === "initializing"')
      span Connecting to chat service...
    template(v-else-if='connectionStatus === "loading_channels"')
      span Loading chats...
    template(v-else-if='connectionStatus === "disabled"')
      error-message(v-if='userIsInDemoMode') Chat disabled in demo mode
      error-message(v-else) Chat disabled - please contact support
      v-button.new-chat-button(disabled) Begin a conversation
    template(v-else-if='connectionStatus === "auth_error"')
      error-message Something went wrong
    template(v-else-if='connectionStatus === "denied"')
      error-message Something went wrong (access denied)
    template(v-else-if='!twilioChannel.sid')
      .new-chat-prompt You have no chat history with this user.
      v-button.new-chat-button(
        @click='initializeChannel'
        :request-status='createChannelRequest' loading-text='Initializing chat...'
      ) Begin a conversation
    template(v-else-if='twilioChannel.status === "initializing"')
      span Loading chat history...
    template(v-else)
      .more-messages-indicator
        template(v-if='!hasMoreMessages')
          span(v-if='!twilioChannel.messages.length') No messages yet
          span(v-else) This is the beginning of the conversation
        span(v-else-if='messagesLoadingStatus === "pending"')
          icon(name='spinner')
          br
          | Loading more messages
        span(v-else-if='messagesLoadingStatus === "error"')
        span(v-else-if='messagesLoadingStatus === "complete"')
          icon(name='arrow-up')
          br
          | load older messages

      template(v-for='message, index in twilioChannel.messages')
        .time-divider(
          v-if='shouldShowTimeDividerAtIndex(index)'
        ) <b>{{ message.timestamp | date }}</b> @ {{ message.timestamp | time }}
        .message(
          :class=`{
            'is-me': message.userId === authUserId,
            'is-new': message.index > twilioChannel.myLastConsumedMessageIndex
          }`
          v-html='getFormattedMessageHtml(message.body)'
          :title='message.timestamp'
        )
      .typing-indicator(:class='whoIsTyping ? "is-active" : ""')
        .typing-indicator-inner
          .typing-indicator-icon
            .tidot
            .tidot
            .tidot
          | {{ whoIsTyping }}

  .input-bar-container
    .input-mark-read(v-if='unreadCount')
      v-button(@click='markAsRead') Mark As Read
    .input-error(v-if='errorSending')
      error-message An error occured sending your message.
    .input-bar(v-if='twilioChannel.sid')
      textarea(
        ref='chatInput'
        type='text'
        placeholder='write a message here...'
        v-model='messageDraft'
        @keydown='chatKeyDownHandler'
        :disabled='!chatEnabled || (twilioChannel.messageSendingStatus === "pending")'
      )
      .send-message-button(@click='sendMessage')
        icon(:name='(twilioChannel.messageSendingStatus === "pending") ? "spinner" : "arrow-up"' bubbled=true)

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';
import he from 'he';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const STICKY_SCROLL_BUFFER = 60;

export default {
  props: {
    coachUserId: { type: Number, required: true },
    execUserId: { type: Number, required: true },
  },
  data() {
    return {
      messageDraft: '',
      errorSending: null,
      scrolledToLatestMessage: true,
    };
  },
  computed: {
    twilioChannel() {
      return this.$store.getters['chat/getChannel'](this.coachUserId, this.execUserId);
    },
    unreadCount() {
      return this.$store.getters['chat/getUnreadMessageCount'](this.coachUserId, this.execUserId);
    },
    ...mapGetters('auth', ['authUserId']),
    ...mapGetters('profile', ['userIsInDemoMode']),
    ...mapGetters('chat', ['connectionStatus']),
    ...mapGetters('window', ['windowVisibleAndFocused']),
    ...mapRequestStatuses({
      createChannelRequest() { return ['chat/CREATE_CHAT_CHANNEL', this.coachUserId, this.execUserId]; },
    }),

    messagesLoadingStatus() {
      return this.twilioChannel.messagesLoadingStatus;
    },
    chatEnabled() {
      return this.twilioChannel.sid;
    },
    hasMoreMessages() {
      return this.twilioChannel.hasMoreMessages;
    },
    chatUsers() {
      return this.twilioChannel.users;
    },
    chatTitle() {
      if (this.connectionStatus === 'init_client') return 'Connecting to chat...';
      else if (this.connectionStatus === 'auth_error') return 'Error connecting to chat';

      if (this.getChannelRequest.isPending) return 'Loading...';
      const notMeUsers = _.reject(this.twilioChannel.users, { id: this.authUserId });
      if (notMeUsers.length === 1) return `Chat with ${notMeUsers[0].nickname}`;
      return `Chat with ${notMeUsers.length} people`;
    },
    typingUsers() {
      return _.filter(this.twilioChannel.users, { isTyping: true });
    },
    whoIsTyping() {
      const typingUsers = _.filter(this.twilioChannel.users, { isTyping: true });

      if (!typingUsers.length) return null;
      if (typingUsers.length > 1) return `${typingUsers.length} people are typing`;
      // return `${typingUsers[0].nickname} is typing`;
      return 'someone is typing';
    },
  },
  watch: {
    messageDraft() {
      // send typing when the draft actually changes
      // this filters out other keyboard activity
      if (!this.messageDraft) return;
      this.$store.dispatch('chat/sendTypingStatus', {
        channelId: this.twilioChannel.id,
      });
    },
    // this only changes if the twilio channel is not fully loaded yet when this mounts
    'twilioChannel.sid': function () {
      this.loadMessages();
    },
    windowVisibleAndFocused() { this.syncMessagesConsumed(); },
    scrolledToLatestMessage() { this.syncMessagesConsumed(); },
  },
  methods: {
    loadMessages() {
      // fetch first batch of messages when the channel gets loaded initially
      this.$store.dispatch('chat/fetchMoreMessages', {
        channelId: this.twilioChannel.id,
        onlyIfEmpty: true,
      });
    },
    shouldShowTimeDividerAtIndex(index) {
      if (index === 0) return true; // "chat started" is displayed with "no more messages" indicator
      const message = this.twilioChannel.messages[index];
      const prevMessage = this.twilioChannel.messages[index - 1];
      return dateFns.differenceInMinutes(message.timestamp, prevMessage.timestamp) > 10;
    },

    getFormattedMessageHtml(body) {
      let cleanText = he.encode(body, {
        useNamedReferences: true,
      });
      cleanText = cleanText.replace(/\n/g, '<br/>');

      // TODO: more replacements?
      // make some formatting work?
      // smileys? other emojis?
      return cleanText;
    },

    async initializeChannel() {
      await this.$store.dispatchApiAction('chat/CREATE_CHAT_CHANNEL', {
        coachId: this.coachUserId,
        userId: this.execUserId,
      });
    },

    chatKeyDownHandler(event) {
      if (event.code === 'Enter' && !event.shiftKey) {
        this.sendMessage();
        event.preventDefault();
      }
    },

    async sendMessage() {
      this.errorSending = undefined;
      const message = this.messageDraft.trim();

      if (!message) {
        return;
      }

      try {
        await this.$store.dispatch('chat/sendMessage', {
          channelId: this.twilioChannel.id,
          message: this.messageDraft,
        });

        this.messageDraft = '';
        this.errorSending = undefined;
      } catch (e) {
        this.errorSending = e;
      }
    },

    messagesClickHandler(event) {
      // this may be a bad idea, but when the user clicks the chat window, we focus on the input
      if (window.getSelection().type === 'Caret') {
        if (this.$refs.chatInput) this.$refs.chatInput.focus();
      }
    },
    scrollHandler() {
      const el = this.$refs.messages;
      const maxScroll = el.scrollHeight - el.clientHeight;
      // if within threshold, we will auto scroll to bottom when new messages come in
      this.scrolledToLatestMessage = maxScroll - el.scrollTop < STICKY_SCROLL_BUFFER;

      // if user has reached the top, load older messages
      if (el.scrollTop === 0) this.scrolledToTopHandler();
    },
    scrollToBottom() {
      const el = this.$refs.messages;
      if (this.scrolledToLatestMessage) {
        el.scrollTop = el.scrollHeight;
        this.syncMessagesConsumed();
      }
    },
    async scrolledToTopHandler() {
      if (!this.twilioChannel.hasMoreMessages) {
        return;
      }

      if (this.twilioChannel.messagesLoadingStatus !== 'complete') {
        return;
      }

      // we need to add the messages to the top but keep the scroll position where it is
      const el = this.$refs.messages;
      const previousScrollHeight = el.scrollHeight;
      await this.$store.dispatch('chat/fetchMoreMessages', { channelId: this.twilioChannel.id });
      el.scrollTop = el.scrollHeight - previousScrollHeight;
    },
    markAsRead() {
      this.$store.dispatch('chat/setMessagesConsumed', {
        channelId: this.twilioChannel.id,
      });
    },
    async syncMessagesConsumed() {
      if (this.windowVisibleAndFocused && this.scrolledToLatestMessage && this.twilioChannel.id) {
        /*
        await this.$store.dispatch('chat/setMessagesConsumed', {
          channelId: this.twilioChannel.id,
        });
        */
      }
    },

  },
  mounted() {
    this.scrollToBottom();
    if (this.twilioChannel && this.twilioChannel.sid) this.loadMessages();
  },
  beforeUpdate() {
    // set up the stick scrolly behaviour
    // but we dont know if the messages container is on the page yet
    if (!this.mutationObserver && this.$refs.messages) {
      this.mutationObserver = new MutationObserver(this.scrollToBottom);
      this.mutationObserver.observe(this.$refs.messages, {
        childList: true, // looking for new children that will change the height
      });
    }
  },
};
</script>

<style lang='less'>
@chat-blue: #1a7ae7;
.chat {
  display: flex;
  flex-direction: column;
  background: white;
  height: 100%;
  font-size: 14px;
  line-height: 1.4em;

  .chat-title {
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    background: @navy;
    padding: 5px 10px;
    color: white;
  }

  .messages-container {
    flex: 1 0 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .message {
    border-radius: 15px;
    background-color: #E4E4E4;
    padding: 5px 10px;
    max-width: 90%;
    margin-bottom: 4px;
    align-self: flex-start;
    position: relative;
    transition: background-color 2s;

    &.is-new {
      background-color: #ecb5b5;


      // &:before {
      //   content: '';
      //   position: absolute;
      //   left: -2px;
      //   top: 50%;
      //   margin-top: -3px;
      //   width: 6px;
      //   height: 6px;
      //   background: red;
      //   border-radius: 50%;
      // }
    }

    &.is-me {
      background-color: @chat-blue;
      // background: @brand-gray-blue;
      color: white;
      text-align: right;
      align-self: flex-end;
    }

  }
  .time-divider {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 0 5px;
    color: #AAA;
  }

  .input-mark-read {
    text-align: center;
    padding: 2px 0;
    
    .button {
      padding: 2px 8px;
      width: 50%;
      max-width: 200px;
    }
  }

  .input-bar-container {
    border-top: 1px solid #EEE;
  }

  .input-bar {
    display: flex;
    border-top: 1px solid #EEE;
    align-items: center;
    height: 80px;

    textarea {
      flex: 1 0 0;
      height: 100%;
      border: none;
      padding: 5px;
      font-size: 16px;
    }
  }
  .send-message-button {
    display: block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 5px;
    padding: 8px;
    background: @chat-blue;

    .icon-content-wrap {
      background: @chat-blue;
      color: white;
    }

    cursor: pointer;
    > svg {
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: #000;

    }
  }

  .more-messages-indicator, .typing-indicator, .new-chat-prompt {
    text-align: center;
    color: #a9bdcf;
    padding: 6px 0;
    line-height: 18px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .more-messages-indicator {
    height: 60px;
  }
  .typing-indicator {
    height: 30px;
    opacity: 0;
    overflow: hidden;

    .typing-indicator-inner {
      transform: translateY(10px);
    }

    &.is-active {
      transition: all .2s;
      .typing-indicator-inner {
        transition: all .2s;
        transform: translateY(0px);
      }
      opacity: 1;
    }
  }

  .new-chat-button {
    margin: 20px;
  }

}

.typing-indicator-icon {
  display: inline-block;
  margin-right: 5px;
  margin-top: -10px;
  > .tidot {
    background-color: currentColor;
    animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
    display: inline-block;
    vertical-align: middle;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-right: 2px;
    &:nth-child(1) { animation-delay: 200ms; }
    &:nth-child(2) { animation-delay: 300ms; }
    &:nth-child(3) { animation-delay: 400ms; }
  }
}

@keyframes mercuryTypingAnimation{
  0%  { transform: translateY(0px) }
  28% { transform: translateY(-5px) }
  44% { transform: translateY(0px) }
}
</style>
