<template lang='pug'>
main-layout
  h2 Account Details

  auth-settings
  br

  schedule-delegation(v-if='user.isExecutive')
  br

  credits-ledger(v-if='user.isExecutive')

</template>

<script>
import { mapGetters } from 'vuex';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'auth-settings': require('./auth-settings').default,
  'schedule-delegation': require('./schedule-delegation').default,
  'credits-ledger': require('./credits-ledger').default,
};

export default {
  components,
  metaInfo: {
    title: 'Account settings',
  },
  computed: {
    ...mapGetters('authUser/profile', ['user']),
  },
};
</script>

<style lang='less'>
.faq-section-title {
  margin-top: 30px;
}

</style>
