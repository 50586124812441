<template lang='pug'>
//- TODO: v-if='value' is stopping some errors when the address being passed in is undefined
//- need to fix this and how we bind here...

//- TODO make required and requiredWarning work with a single error
//- message for the whole block

form-group(:label='label' :disabled='disabled' v-if='value')
  template(v-if='languageOptions')
  form-row
    form-input(
      v-model='value.language' type='dropdown'
      label='What language are you most comfortable in?'
      :options='languageOptions' 
      :auto-select='autoSelectLanguage'
      required
    )
    form-input(
      v-if='value.language !== "en"'
      v-model='value.language2' type='dropdown'
      label='Any other language you are fluent in?'
      :options='languageOptions2'
      :auto-select='autoSelectLanguage'
    )
    
  form-row
    form-input(v-model='value.skills' type='multi-checkbox' label='What skills do you want to work on with your coach?' :options='skillOptions' required)
    form-input(v-model='value.inPersonOrRemote' type='radio' label='How would you like to meet with your coach?')
      form-input-option(:value='null') No preference - In person or remote is fine
      form-input-option(value='in_person') In person - I prefer face to face meetings
      form-input-option(value='remote') Remote - I prefer phone/video sessions

  form-row
    form-input(v-model='value.gender' type='dropdown' label='Do you have a preference for the gender identity of your coach?')
      form-input-option(:value='null') No Preference
      form-input-option(value='male') Male
      form-input-option(value='female') Female

  form-row
    form-input(
      v-model='value.hasPreviousCoachingExperience' type='radio'
      label='Have you had previous experience working with a coach?'
      required
    )
      form-input-option(:value='true') Yes
      form-input-option(:value='false') No
    form-input(
      v-if='value.hasPreviousCoachingExperience'
      v-model='value.previousCoachingExperienceNotes' type='textarea'
      no-label placeholder='Describe your previous experience'
    )
  slot
</template>


<script>
import { mapGetters } from 'vuex';

import _ from 'lodash';

import {
  stateOptionsForCountry, postalCodeLabel, stateLabel,
} from '@/utils/local';

// TODO: fix v-model handling
// https://github.com/vuejs/vue/issues/4373
// technically we shouldnt be changing the passed in object directly
// but keeping a local copy and calling `$emit('input')` on changes

export default {
  props: {
    label: {
      type: String,
      default: 'Exec Preferences',
    },
    value: {
      type: Object, // passed in via v-model
      default: () => {},
    },
    skillOptions: {
      type: Object,
      default: () => [],
    },
    autoSelectLanguage: {
      type: Boolean,
      default: false,
    },
    languageOptions: {
      type: Object,
    },
    compact: Boolean,
    required: Boolean,
    requiredWarning: Boolean,
    requiredMessage: String,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters('enums', ['countryOptions']),
    languageOptions2() {
      return _.omit(this.languageOptions, _.get(this.user, 'executivePreferences.language'));
    },
  },
  methods: {
    stateOptionsForCountry, postalCodeLabel, stateLabel,
  },
};
</script>

<style lang='less'>


</style>
