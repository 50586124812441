<template lang='pug'>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

const components = {};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  computed: {
    ...mapGetters('authUser/profile', [
      'user',
      'onboardingProgress',
      'userEvaluationsEnabled',
    ]),
  },
  beforeMount() {
    const { user, onboardingProgress, userEvaluationsEnabled } = this;
    const isCoachOrExecutive = user.isCoach || user.isExecutive;

    let redirect;

    if (!onboardingProgress.profile) {
      redirect = 'profile';
    } else if (isCoachOrExecutive && !onboardingProgress.assessment) {
      redirect = 'assessment';
    } else if (user.isCoach) {
      if (!onboardingProgress.availability) {
        redirect = 'availability-settings';
      } else if (!onboardingProgress.coachActivation) {
        redirect = 'coach-approval';
      }
    } else if (user.isExecutive) {
      if (
        userEvaluationsEnabled
        && !(this.userEvaluations && this.userEvaluations.length)
      ) {
        redirect = 'evaluation-respondents';
      } else if (!this.onboardingProgress.matching) {
        redirect = 'coach-matches';
      }
    }

    if (!redirect) {
      redirect = 'welcome';
    }

    console.log('Redirecting to: ', redirect);
    this.$router.replace({ name: redirect });
  },
};
</script>

<style lang='less'>
</style>
