<template lang='pug'>
form-input.resume-list-editor(
  type='container' :label='inputLabel'
  :required-warning='minRequired > 0' :required-message='`Please enter at least ${minRequired}`'
  :value='localValue.length >= minRequired ? true : undefined'
)
  todo-section(
    v-for='item, itemIndex in localValue' :key='item.id'
    :icon='iconName'
    :title='getSummaryLabel(item)' empty-title='New item'
    :start-open='!item[orgProp]'
  )
    form-row(v-if='type === "education"')
      form-input(label='School' v-model='item.school' required)
      form-input(label='Year(s)' v-model='item.years' grow='half' placeholder='ex: 2015-2017')
      form-input(label='Degree' v-model='item.degree' grow='half')
    form-row(v-else-if='type === "career"')
      form-input(label='Company' v-model='item.company' required)
      form-input(label='Year(s)' v-model='item.years' grow='half' placeholder='ex: 2015-2017')
      form-input(label='Job Title' v-model='item.jobTitle' grow='half')
    form-row(v-else-if='type === "accolades"')
      form-input(label='Program / Org' v-model='item.organization' required)
      form-input(label='Year' v-model='item.years' grow='half' placeholder='ex: 2015')
      form-input(label='Title' v-model='item.title' grow='half')
    form-row
      form-input(label='Details' v-model='item.description' type='textarea')
    form-row
      form-input(type='container' label='Reorder')
        v-button(
          inline icon='arrow-up' theme='transparent-dark'
          :disabled='itemIndex === 0'
          @click='() => moveItemUp(itemIndex)'
        )
        v-button(
          inline icon='arrow-down' theme='transparent-dark'
          :disabled='itemIndex === localValue.length - 1'
          @click='() => moveItemDown(itemIndex)'
        )
      form-input(type='container')
        v-button(
          inline icon='trash' theme='transparent-red'
          @click='() => removeItem(itemIndex)'
        ) remove
  todo-section(
    action-type='add'
    :title='`Add another ${addLabel}`'
    :click-action='addItem'
  )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import uuid from 'uuid/v4';

const components = {
};


export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    value: Array,
    type: String,
  },
  data() {
    return {
      localValue: this.addIdsToItemsArray(this.value),
    };
  },
  computed: {
    inputLabel() {
      return {
        education: 'Education History',
        career: 'Career History',
        accolades: 'Awards & Certifications',
      }[this.type];
    },
    addLabel() {
      return {
        education: 'school / degree',
        career: 'company / position',
        accolades: 'award / certification',
      }[this.type];
    },
    iconName() {
      return {
        education: 'university',
        career: 'business-time',
        accolades: 'award',
      }[this.type];
    },
    orgProp() {
      return {
        education: 'school',
        career: 'company',
        accolades: 'organization',
      }[this.type];
    },
    titleProp() {
      return {
        education: 'degree',
        career: 'jobTitle',
        accolades: 'title',
      }[this.type];
    },
    minRequired() {
      return {
        education: 1,
        career: 1,
        accolades: 0,
      }[this.type];
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        this.$emit('input', _.cloneDeep(this.localValue));
      },
    },
    value: {
      handler() {
        if (!_.isEqual(this.value, this.localValue)) {
          this.localValue = _.cloneDeep(this.addIdsToItemsArray(this.value));
        }
      },
    },
  },
  beforeMount() {
  },
  methods: {
    // TODO: remove this when everything has ids
    addIdsToItemsArray(arrayOfItems) {
      return _.map(arrayOfItems, (item) => ({
        ...!item.id && { id: uuid() },
        ...item,
      }));
    },
    addItem() {
      this.$emit('input', [...this.localValue, { id: uuid() }]);
      const newIndex = this.localValue.length;
      // setTimeout(() => {
      //   this.$refs[`item${newIndex}`][0].open();
      // });
    },
    removeItem(itemIndex) {
      this.$emit('input', _.reject(this.localValue, (v, i) => i === itemIndex));
    },
    moveItemUp(i) {
      const a = this.localValue;
      this.$emit('input', [...a.slice(0, i - 1), a[i], a[i - 1], ...a.slice(i + 1)]);
    },
    moveItemDown(i) {
      const a = this.localValue;
      this.$emit('input', [...a.slice(0, i), a[i + 1], a[i], ...a.slice(i + 2)]);
    },
    getSummaryLabel(item) {
      let s = item[this.orgProp];
      if (s && item.years) s += ` (${item.years})`;
      if (s && item[this.titleProp]) s += ` - ${item[this.titleProp]}`;
      return s;
    },
  },
};
</script>

<style lang='less'>

</style>
