export function makeSeoRefs(url, cannonical = true) {
  const baseUrl = 'https://app.advisorey.com/';
  const href = baseUrl + url;

  const links = [{
    rel: 'alternate',
    href,
    hreflang: 'en-us',
  }];

  if (cannonical) {
    links.push({
      rel: 'canonical',
      href,
    });
  }

  return links;
}
