<template lang='pug'>
main-layout
  h3 Choose your new password

  error-message(v-if='!$route.query.resetToken') Invalid password reset link

  form-row(v-else-if='resetPasswordRequest.isSuccess' no-inputs)
    p
      strong Password reset successful!
      br
      router-link(:to='{name: "login"}') Log in here

  form-group(v-else)
    form-row
      form-input(
        type='email' v-model='email'
        label='Email' placeholder='Your email'
        required
      )
      form-input(
        type='password' v-model='password' allow-show-password
        label='New password' placeholder='Your new password'
        check-password-strength required
      )
    form-row
      error-message(:request-status='resetPasswordRequest')
      v-button(
        @click='submitPasswordReset'
        :disabled='$vv.$error'
        :request-status='resetPasswordRequest'
        loading-text='Saving your new password...'
      ) Set new password


</template>

<script>
import { mapGetters } from 'vuex';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};


export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Reset password',
  },
  data() {
    return {
      email: this.user ? this.user.email : '',
      password: '',
    };
  },
  computed: {
    ...mapGetters('authUser/profile', ['user']),
    ...mapRequestStatuses({
      resetPasswordRequest: 'auth/SUBMIT_PASSWORD_RESET',
    }),
  },

  methods: {
    submitPasswordReset() {
      if (this.$hasError()) return;
      this.$store.dispatchApiAction('auth/SUBMIT_PASSWORD_RESET', {
        email: this.email,
        newPassword: this.password,
        resetToken: this.$route.query.resetToken,
      });
    },
  },
};
</script>
