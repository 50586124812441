<template lang='pug'>
main-layout#page-company-dashboard(
  no-footer
)
  //- company status and number of licenses
  template(v-if='getCompanyRequest.isPending')
    //- Loading...
  template(v-else-if='getCompanyRequest.isError')
    error-message(:request-status='getCompanyRequest')
  template(v-else-if='getCompanyRequest.isSuccess')
    .company-dashboard
      .company-dashboard-nav
        button-nav-container(title='Company' :showBottomBorder='true')
          router-link(:to='{name: "company-overview"}') Overview
          router-link(:to='{name: "company-users-dashboard"}') Users
          router-link(:to='{name: "company-profile"}') Profile
      .company-dashboard-content
        router-view

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      addUserPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdmin: 'user',
    }),
    ...mapGetters('companies', {
      company: 'selectedCompany',
    }),
    ...mapRequestStatuses({
      getCompanyRequest: 'companies/GET_COMPANY',
    }),
  },
  beforeMount() {
    this.$store.dispatch(
      'companies/setSelectedCompanyId',
      this.currentAdmin.companyId,
    );
  },
  methods: {},
};
</script>

<style lang='less'>
</style>
