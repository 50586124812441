<template lang='pug'>
.button-nav-container(:class='(showBottomBorder) ? "button-nav-container-bordered" : ""')
  nav.button-nav-links
    slot
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

const components = {};

export default {
  components,
  mixins: [],
  props: {
    showBottomBorder: Boolean,
  },
  computed: {},
  methods: {},
  beforeMount() {},
};
</script>

<style lang='less'>
.button-nav-container {
  &.button-nav-container-bordered {
    padding: 8px 0;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-bottom: 6px;
  }

  .button-nav-links {

    a {
      padding: 5px;
      margin: 2px;
      display: inline-block;
      text-align: center;

      border-radius: 4px;
      background: white;
      color: @navy;
      border-bottom: 1px solid #ddd;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

      &.router-link-active {
        background: lighten(@navy, 25%);
        color: white;
      }

      /* color: white; */
      // font-size: 0.9em;
      /*
        padding: 5px;
        margin: 2px;
        display: inline-block;
        border: 1px solid red;
        border-radius: 8px;
        min-width: 100px;
        text-align: center;

        */
    }
  }
}
</style>
