<template lang='pug'>
#schedule-delegation
  h3 Schedule Delegation
  template(v-if='coachCanScheduleSessions')
    h4.schedule-delegation-on Coach Schedule Delegation Is Enabled
    p Your coach is allowed to schedule sessions on your behalf.
    v-button(@click='confirmDisableScheduleDelegation' confirm-click
            :request-status='toggleDelegationRequest' icon='minus-circle' size="small") Disable Schedule Delegation
  template(v-else)
    h4.schedule-delegation-off Coach Schedule Delegation Is Disabled
    p You can allow your coach schedule events on your behalf. This can make scheduling easier when coordinating with your coach.
    v-button(@click='confirmEnableScheduleDelegation' confirm-click 
            :request-status='toggleDelegationRequest' icon='plus-circle' size="small") Enable Schedule Delegation
</template>

<script>
import _, { isNumber } from 'lodash';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    delegationSettings: { type: Object },
    handleToggleDelegation: { type: Function },
    toggleDelegationRequest: {},
  },
  data() {
    return {};
  },
  computed: {
    coachCanScheduleSessions() {
      return (this.delegationSettings || {}).coachCanScheduleSessions || false;
    },
  },
  methods: {
    async confirmEnableScheduleDelegation() {
      await this.handleToggleDelegation(true);
    },
    async confirmDisableScheduleDelegation() {
      await this.handleToggleDelegation(false);
    },
  },
};
</script>

<style lang='less'>

.schedule-delegation-on {
  color: @green;
}

.schedule-delegation-off {
  color: @red;
}
</style>
