<template lang='pug'>
//- TODO: v-if='value' is stopping some errors when the address being passed in is undefined
//- need to fix this and how we bind here...

//- TODO make required and requiredWarning work with a single error
//- message for the whole block

form-group(:label='label' :disabled='disabled' v-if='value')
  form-row
    form-input(
      v-model='value.skillsType' type='dropdown'
      label='Company Skills'
      instructions='If your company has a different set of skills that you need to target, you can change them here.'
      :options='companySkillsOptions'
    )
  template(v-if='showCustomSkills')
    form-row
      form-input(
        v-model='value.customSkills.leadereq' type='multi-checkbox'
        label='AdvisorEY Skills'
        instructions='Select the default AdvisorEY skills you want to display.'
        :options='skillOptions'
      )
    form-row
      form-input(type='textarea' v-model='value.customSkills.custom'
        label='Custom Skills'
        instructions='Add your custom skills separated by comma. (Ex: Learning, Leadership, etc.)'
      )
  slot
</template>

<script>
import { mapGetters } from 'vuex';

import {
  stateOptionsForCountry, postalCodeLabel, stateLabel,
} from '@/utils/local';

// TODO: fix v-model handling
// https://github.com/vuejs/vue/issues/4373
// technically we shouldnt be changing the passed in object directly
// but keeping a local copy and calling `$emit('input')` on changes

export default {
  props: {
    label: {
      type: String,
      default: 'Target Skills',
    },
    value: {
      type: Object, // passed in via v-model
      default: () => {},
    },
    compact: Boolean,
    required: Boolean,
    requiredWarning: Boolean,
    requiredMessage: String,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters('enums', ['companySkillsOptions', 'skillOptions']),
    showCustomSkills() {
      const { skillsType } = this.value;
      console.log('Skills type: ', skillsType);
      return skillsType === 'custom';
    },
  },
  methods: {
    stateOptionsForCountry, postalCodeLabel, stateLabel,
  },
};
</script>

<style lang='less'>


</style>
