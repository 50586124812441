<template lang='pug'>
main-layout
  h2 Frequently Asked Questions

  h3.faq-section-title How it works
  faq-question(q='What is AdvisorEY?')
    p We are a platform that makes executive coaching easy.
  faq-question(q='Why do my executives need coaching?')
    p Because they do?


  h3.faq-section-title Using Zoom.us - remote sessions
  faq-question(q='How do I join the call?')
    p Our remote coaching sessions are hosted using <a href='https://zoom.us' target='_blank'>zoom.us</a>. You can join using your computer or your mobile device using the Zoom apps. Alternatively you can dial in using the number provided.

  hr
  p If you need anything, please do not hesitate to contact us directly at <a href='mailto:info@advisorey.com'>info@advisorey.com</a> or <a href='tel:+14155094324' target='_blank'>415.509.4324</a>!
</template>

<script>
const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'faq-question': require('./faq-question').default,
};

export default {
  components,
  metaInfo: {
    title: 'Frequently asked questions',
  },
};
</script>

<style lang='less'>
.faq-section-title {
  margin-top: 30px;
}

</style>
