<template lang='pug'>
.user-auth-form
  h3 Company Data

  template(v-if='isDifferentUser')
    template(v-if='user.isExecutive && userCoachingIsEnabled')
      //- TODO: ...
      //- form-group(label='Private Notes To Coach')

      form-group(label='Company Documents')
        form-row(no-inputs)
          template(v-if='user.isExecutive')
            .documents-content
              template(v-if='getDocumentsRequest.isPending')
                h3 Loading documents list...
              template(v-else-if='getDocumentsRequest.isError')
                error-message(:request-status='getDocumentsRequest')
              template(v-else-if='getDocumentsRequest.isSuccess')
                .page-header
                  p This is a list of documents uploaded by the company. They can be viewed by everyone, but only removed by the company. You cannot view documents uploaded by the user or their coach.
                  document-list(:current-user-id='userId'
                    :is-company-admin='isCompanyAdmin'
                    no-documents-message='No Company Documents'
                    :handle-delete-document='handleDeleteDocument' :delete-document-request='deleteDocumentRequest'
                    :handle-edit-document='handleEditDocument' 
                    :documents='documents')
                  document-upload(
                    label='Upload Company Document'
                    :handle-upload='handleUploadFile')
    template(v-else)
      p This user is not an active executive.
  template(v-else)
    p You are not allowed to view your own company data.

</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'document-list': require('@/components/other/document-list').default,
  'document-upload': require('@/components/other/document-upload').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'User Company Data',
    };
  },
  data() {
    return {
      setPasswordPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdminId: 'userId',
      authUser: 'user',
    }),
    ...mapGetters('selectedUser/profile', ['user', 'userId']),
    ...mapGetters('selectedUser/documents', ['documents', 'hasDocumentsLoaded']),
    ...mapGetters('companies', {
      company: 'selectedCompany',
      companyUsers: 'selectedCompanyUsers',
      activeUsers: 'selectedCompanyActiveUsers',
      passiveUsers: 'selectedCompanyPassiveUsers',
      companyCanAddExec: 'companyCanAddExec',
    }),
    ...mapRequestStatuses({
      getEnumsRequest: 'enums/GET_ENUMS',
      getDocumentsRequest: 'selectedUser/documents/LOAD_DOCUMENTS',
      uploadDocumentRequest: 'selectedUser/documents/UPLOAD_DOCUMENT',
      deleteDocumentRequest: 'selectedUser/documents/DELETE_DOCUMENT',
    }),
    isCompanyAdmin() {
      return this.authUser.isCompanyAdmin;
    },
    isDifferentUser() {
      return this.userId !== this.currentAdminId;
    },
    userCoachingIsEnabled() {
      return !this.user.execLicensePausedAt;
    },
  },
  watch: {
    user() {
      this.$store.dispatch('selectedUser/documents/loadDocuments');
    },
  },
  mounted() {
  },
  methods: {
    async handleDeleteDocument(document) {
      return this.$store.dispatch('selectedUser/documents/deleteDocument', document.id);
    },
    async handleEditDocument() {
      // TODO: Add Edit Document later if necessary.
    },
    async handleUploadFile(file) {
      return this.$store.dispatch('selectedUser/documents/uploadFile', file);
    },
  },
  beforeMount() {
    console.log('Before mount: ', this.user);
    this.$store.dispatch('selectedUser/documents/loadDocuments');
  },
};
</script>

<style lang='less'>
</style>
