import _ from 'lodash';
import Vue from 'vue';

import { buildApiActions } from '@/utils/vuex-api-utils';

const TwilioChat = require('twilio-chat');

const NUM_MESSAGES_PER_PAGE = 50;

let twilioClient;
let twilioClientReady;

const twilioChannelObjects = {};

const createChannelId = (coachId, userId) => `c${coachId}_u${userId}`;

function parseRawMessage(m) {
  return {
    sid: m.sid,
    index: m.index,
    timestamp: m.timestamp,
    body: m.body,
    userId: parseInt(m.author.split(':')[1]),
  };
}

function getUnreadMessageCountForChannel(state, channel) {
  if (channel.status !== 'loaded') {
    return 0;
  } else if (channel.lastMessageIndex === undefined) {
    return 0;
  } else if (channel.myLastConsumedMessageIndex === null) {
    return channel.lastMessageIndex + 1;
  }

  return channel.lastMessageIndex - channel.myLastConsumedMessageIndex;
}

export default {
  namespaced: true,
  state() {
    return {
      twilioAuthToken: null,
      twilioConnectionStatus: null,
      channels: {},
    };
  },
  getters: {
    twilioAuthToken: (state) => state.twilioAuthToken,
    allChannelsLoaded: (state) => _.every(state.channels, { status: 'loaded' }),
    connectionStatus: (state, getters) => {
      if (state.twilioConnectionStatus !== 'connected') return state.twilioConnectionStatus;
      return getters.allChannelsLoaded ? 'connected' : 'loading_channels';
    },
    windowInFocus: (state) => state.windowInFocus,
    getChannel: (state) => (coachUserId, execUserId) => {
      const channelId = createChannelId(coachUserId, execUserId);
      return state.channels[channelId] || {};
    },
    getUnreadMessageCount: (state, getters) => (coachUserId, execUserId) => {
      const channelId = createChannelId(coachUserId, execUserId);
      const c = state.channels[channelId];

      if (!c || !c.users) {
        return 0;
      }

      return getUnreadMessageCountForChannel(state, c);
    },
    allChannels: (state) => _.values(state.channels),
    totalUnreadMessageCount: (state, getters) => _.sumBy(getters.allChannels, (c) => getUnreadMessageCountForChannel(state, c)),
  },
  ...buildApiActions({
    GET_TWILIO_AUTH_TOKEN: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/twilio-auth',
      }),
      mutation: (state, { response }) => {
        state.twilioAuthToken = response.token;
      },
    },
    CREATE_CHAT_CHANNEL: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/chats/${createChannelId(payload.coachId, payload.userId)}`,
        keyRequestStatusBy: [payload.coachId, payload.userId],
      }),
      mutation: (state, { response }) => {
        // DO NOTHING HERE!
        // we get the twilio channel being joined before this mutation runs
        // which means this was overwriting the good info we got from twilio
        // Vue.set(state.channels, response.id, response);
      },
    },

  }, {
    actions: {
      async init(ctx) {
        if (await ctx.dispatch('api-GET_TWILIO_AUTH_TOKEN')) {
          ctx.commit('UPDATE_TWILIO_CONNECTION_STATUS', 'initializing');
          twilioClientReady = TwilioChat.Client.create(ctx.state.twilioAuthToken);
          twilioClient = await twilioClientReady;
          window.twilioClient = twilioClient; // for debugging

          twilioClient.on('connectionStateChanged', (newState) => {
            ctx.commit('UPDATE_TWILIO_CONNECTION_STATUS', newState);
          });

          // handle a new channel being joined
          twilioClient.on('channelJoined', (channel) => {
            ctx.dispatch('initializeChannel', channel);
          });

          // ctx.dispatch('refreshChannels');
        } else {
          const authRequest = ctx.rootGetters.requestStatus('chat/GET_TWILIO_AUTH_TOKEN');
          if (_.get(authRequest, 'error.type') === 'ChatDisabled') {
            ctx.commit('UPDATE_TWILIO_CONNECTION_STATUS', 'disabled');
          } else {
            ctx.commit('UPDATE_TWILIO_CONNECTION_STATUS', 'auth_error');
          }
        }
      },

      // this fires for each channel when the twilio client gets initialized
      // and (hopefully) if a user ends up joining a new channel
      async initializeChannel(ctx, twilioChannelObject) {
        const channelId = twilioChannelObject.uniqueName.split(':')[1];

        twilioChannelObjects[channelId] = twilioChannelObject;
        ctx.commit('INITIALIZE_CHANNEL', channelId);

        // the twilio library was already making this request (and probably more)
        // so we await this call here to know when it is done being loaded
        const members = await twilioChannelObject.getMembers();

        // listen for new messages
        twilioChannelObject.on('messageAdded', (message) => {
          ctx.commit('ADD_CHANNEL_MESSAGE', { channelId, message: parseRawMessage(message) });
        });

        // receiving typing status from remote
        twilioChannelObject.on('typingStarted', (member) => {
          ctx.commit('SET_CHANNEL_MEMBER_TYPING_STATUS', { channelId, member, status: true });
        });
        twilioChannelObject.on('typingEnded', (member) => {
          ctx.commit('SET_CHANNEL_MEMBER_TYPING_STATUS', { channelId, member, status: false });
        });
        twilioChannelObject.on('memberUpdated', ({ member, updateReasons }) => {
          ctx.commit('UPDATE_CHANNEL_MEMBER', {
            channelId,
            member,
            authUserId: ctx.rootGetters['auth/authUserId'],
          });
        });
        await ctx.commit('CHANNEL_LOADED', channelId);
      },

      async fetchMoreMessages(ctx, { channelId, onlyIfEmpty }) {
        const channelInStore = ctx.state.channels[channelId];
        if (onlyIfEmpty && channelInStore.messagesLoadingStatus === 'complete') return;
        if (channelInStore.messagesLoadingStatus === 'pending') return;

        await ctx.commit('SET_CHANNEL_MESSAGES_LOADING_STATUS', { channelId, status: 'pending' });
        const twilioChannel = twilioChannelObjects[channelId];
        try {
          // by default it fetches from newest to oldest
          // get the index (from twilio) of the oldest message we have loaded
          const oldestIndex = _.get(ctx.state.channels[channelId], 'messages[0].index', undefined);
          const rawMessages = await twilioChannel.getMessages(NUM_MESSAGES_PER_PAGE, oldestIndex);
          const messages = _.map(rawMessages.items, parseRawMessage);
          await ctx.commit('ADD_CHANNEL_MESSAGES', {
            channelId,
            messages,
            hasMoreMessages: rawMessages.hasPrevPage,
          });
        } catch (err) {
          console.log(err);
          await ctx.commit('SET_CHANNEL_MESSAGES_LOADING_STATUS', { channelId, status: 'error' });
        }
      },
      async sendMessage(ctx, { channelId, message }) {
        if (message === '/x') {
          await twilioChannelObjects[channelId].setNoMessagesConsumed();
          return null;
        } else if (message === '/r') {
          await twilioChannelObjects[channelId].setAllMessagesConsumed();
          return null;
        }

        ctx.commit('SET_CHANNEL_MESSAGE_SENDING_STATUS', { channelId, status: 'pending' });
        try {
          await twilioChannelObjects[channelId].sendMessage(message);
          ctx.commit('SET_CHANNEL_MESSAGE_SENDING_STATUS', { channelId, status: 'success' });
          ctx.dispatch('setMessagesConsumed', { channelId });

          // twilioChannelObjects[channelId].setAllMessagesConsumed();
          return true;
        } catch (err) {
          ctx.commit('SET_CHANNEL_MESSAGE_SENDING_STATUS', { channelId, status: 'error' });
          throw err;
        }
      },
      async sendTypingStatus(ctx, { channelId }) {
        await twilioChannelObjects[channelId].typing();
      },
      async setMessagesConsumed(ctx, { channelId }) {
        const channel = ctx.state.channels[channelId];
        // do nothing if no messages or already up to date
        if (!channel.lastMessageIndex) return;
        if (channel.lastMessageIndex === channel.myLastConsumedMessageIndex) return;

        // set our local read index right away for snappier UI
        // this avoids flashes of the "red circle" both for the user's own messages
        // and for messages that come in while the user is scrolled to the latest message
        const { lastMessageIndex } = channel;
        ctx.commit('SET_CHANNEL_CONSUMED_INDEX', { channelId, index: lastMessageIndex });
        // send to the server to sync...
        twilioChannelObjects[channelId].advanceLastConsumedMessageIndex(lastMessageIndex);
      },
    },
    mutations: {
      UPDATE_TWILIO_CONNECTION_STATUS(state, status) {
        state.twilioConnectionStatus = status;
      },
      INITIALIZE_CHANNEL(state, channelId) {
        const channel = twilioChannelObjects[channelId];
        Vue.set(state.channels, channelId, {
          id: channelId,
          sid: channel.sid,

          status: 'initializing',
          metadata: {},

          lastMessageIndex: _.get(channel, 'lastMessage.index'),
          lastMessageTimestamp: _.get(channel, 'lastMessage.timestamp'),

          myLastConsumedMessageIndex: channel.lastConsumedMessageIndex,
          theirLastConsumedMessageIndex: null,

          users: {},
          messages: [],
          hasMoreMessages: true,
          messagesLoadingStatus: null,

          messageSendingStatus: null,
        });
      },

      CHANNEL_LOADED(state, channelId) {
        const channel = twilioChannelObjects[channelId];

        const users = {};
        channel.members.forEach((member) => {
          const userId = parseInt(member.identity.split(':')[1]);
          users[userId] = _.pick(member, [
            'isTyping',
            'lastConsumedMessageIndex',
            'lastConsumptionTimestamp',
          ]);
        });

        state.channels[channelId].users = users;
        state.channels[channelId].status = 'loaded';
      },

      SET_CHANNEL_MESSAGE_SENDING_STATUS(state, { channelId, status }) {
        state.channels[channelId].messageSendingStatus = status;
      },
      SET_CHANNEL_MESSAGES_LOADING_STATUS(state, { channelId, status }) {
        state.channels[channelId].messagesLoadingStatus = status;
      },
      SET_CHANNEL_CONSUMED_INDEX(state, { channelId, index, isNewMessage }) {
        state.channels[channelId].myLastConsumedMessageIndex = index;
        if (isNewMessage) {
          state.channels[channelId].lastMessageIndex = index;
        }
      },
      ADD_CHANNEL_MESSAGES(state, { channelId, messages, hasMoreMessages }) {
        state.channels[channelId].messagesLoadingStatus = 'complete';
        state.channels[channelId].status = 'loaded';
        if (messages.length) state.channels[channelId].messages.unshift(...messages);
        state.channels[channelId].hasMoreMessages = hasMoreMessages;
      },
      ADD_CHANNEL_MESSAGE(state, { channelId, message }) {
        state.channels[channelId].messages.push(message);
        state.channels[channelId].lastMessageIndex = message.index;
        state.channels[channelId].lastMessageTimestamp = message.timestamp;
      },
      SET_CHANNEL_MEMBER_TYPING_STATUS(state, { channelId, member, status }) {
        const userId = parseInt(member.identity.split(':')[1]);
        state.channels[channelId].users[userId].isTyping = status;
      },
      UPDATE_CHANNEL_MEMBER(state, { channelId, member, authUserId }) {
        const userId = parseInt(member.identity.split(':')[1]);
        const userInState = state.channels[channelId].users[userId];
        if (userId === authUserId) {
          state.channels[channelId].myLastConsumedMessageIndex = member.lastConsumedMessageIndex;
        }
        // Vue.set(userInState, 'lastConsumedMessageIndex', member.lastConsumedMessageIndex);
      },
    },
  }),
};
