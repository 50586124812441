/*
Place one instance of this component on the page where the user triggers oauth
login to start and another on a page that is used as the Oauth redirect url.
ie: https://example.com/oauth-callback

On the callback page component, pass the querystring (as an object) to the
`callback-payload` param. Assuming you are using vue-router, it would look like:

  (using pug)
  `oauth-connect(:callback-payload='this.$route.query')`

*/

<template lang='pug'>
  div
</template>

<script>
/* eslint-disable no-param-reassign */

import storage from 'local-storage-fallback'; // polyfill storage - falls back to cookies, memory, etc

const ua = navigator.userAgent.toLowerCase().replace(/\s+/, '');
const isBrowserIosWebview = ua.match(/ip(hone|od|ad)/) && (ua.indexOf('safari') === -1 || ua.indexOf('crios') > 0);

// see https://www.w3schools.com/jsref/met_win_open.asp
const defaultPopupSpecs = {
  location: 0,
  status: 0,
  width: 650,
  height: 700,
};

function buildSpecString(options) {
  const specs = [];
  Object.keys(options).forEach((key) => {
    if (options[key] !== undefined) {
      specs.push(`${key}=${options[key]}`);
    }
  });
  return specs.join(',');
}

export default {
  components: {},
  props: {
    popupWindowName: { type: String, default: 'ConnectWithOAuth' },
    providerConfig: { type: Object },
    callbackPayload: { type: Object },
  },
  data() {
    return {};
  },
  computed: {
    popupWindowSpec() {
      return buildSpecString({
        ...defaultPopupSpecs,
        // overrides
      });
    },
  },
  methods: {
    launch(provider, overrides = {}) {
      const config = this.providerConfig[provider];
      if (!config) {
        throw new Error(`Missing oauth config for provider - ${provider}`);
      }

      let url = overrides.authorizeUri ? overrides.authorizeUri : config.authorizeUri;
      url += url.indexOf('?') > 0 ? '&' : '?';
      url += `client_id=${config.clientId}`;

      // TODO: some don't need this - make optional?
      url += `&response_type=${config.responseType || 'code'}`;

      const wl = window.location;
      if (config.redirectUri === true) {
        this.redirectUri = `${wl.protocol}//${wl.host}${wl.pathname}`;
        url += '&redirect_uri=';
      } else if (config.redirectUri) {
        if (config.redirectUri.indexOf('http') === 0) {
          this.redirectUri = `${config.redirectUri}`;
        } else {
          this.redirectUri = `${wl.protocol}//${wl.host}${config.redirectUri}`;
        }
      } else {
        this.redirectUri = null;
      }

      if (this.redirectUri) {
        url += `&redirect_uri=${this.redirectUri}`;
      }

      const scopes = [
        ...config.scope ? [config.scope] : [],
        ...config.scopes || [],
        ...overrides.additionalScopes || [],
      ];

      if (scopes.length) {
        url += `&scope=${scopes.join(config.scopesDelimiter || ',')}`;
      }

      if (config.sub) {
        url += `&sub=${config.sub}`;
      }

      if (config.prompt) {
        url += `&prompt=${config.prompt}`;
      }

      if (config.accessType) {
        url += `&access_type=${config.accessType}`;
      }

      if (config.grantOptions) {
        config.grant_options.forEach((o) => {
          url += `&grant_options[]=${o}`;
        });
      }

      if (config.useStateNone !== false) {
        this.nonce = `nonce${Math.random() * +new Date()}`;
        url += `&state=${this.nonce}`;
      }

      this.activeProvider = provider;

      // make callback globally accessible so that popup window can call it
      // if overrides.noPopup exists then treat as a no-popup execution
      if (overrides.noPopup) {
        storage.setItem('oauth-provider', this.activeProvider);
        storage.setItem('oauth-redirect-route', overrides.redirectRoute);
        window.location = url;
      } else {
        // TODO: handle ios webview scenario using a redirect rather than popup
        window.oauthCallback = this.oauthCallbackHandler;

        if (isBrowserIosWebview) {
          throw new Error('Oauth login is not yet supported in a webview.');
        }

        this.popupWindow = window.open(url, this.popupWindowName, this.popupWindowSpec);

        this.checkWindowInterval = setInterval(() => {
          if (!this.popupWindow
            || this.popupWindow.closed
            || this.popupWindow.closed === undefined
          ) {
            this.popupClosedHandler();
          }
        }, 1000);
      }
    },
    popupClosedHandler() {
      if (this.checkWindowInterval) {
        clearInterval(this.checkWindowInterval);
        this.checkWindowInterval = null;
      }
      if (!this.expectClose) {
        this.$emit('error', {
          provider: this.activeProvider,
          error: 'window_closed',
        });
      }
      this.expectClose = false;
    },
    oauthCallbackHandler(payload) {
      payload.provider = this.activeProvider || payload.provider;
      payload.redirectUri = this.redirectUri;
      this.expectClose = true;
      // TODO: check payload.state === nonce if option is activated
      if (payload.error) {
        // if provider exists in storage, then treat it as a no-popup execution
        if (storage.getItem('oauth-provider')) {
          const redirectRoute = storage.getItem('oauth-redirect-route');
          this.$router.push({ name: redirectRoute, query: { error: payload.error } });
          return;
        }
        // payload.error will contain an error code like "access_denied"
        this.$emit('error', payload);
      } else if (payload.code) {
        // if provider exists in storage, then treat it as a no-popup execution
        // and redirect back to `redirectRoute` page with new query params
        if (storage.getItem('oauth-provider')) {
          const provider = storage.getItem('oauth-provider');
          const redirectRoute = storage.getItem('oauth-redirect-route');
          this.$router.push({ name: redirectRoute, query: { ...payload, provider } });
          return;
        }
        // payload.code will contain the oauth code
        this.$emit('code', payload);
      }
    },
  },
  mounted() {
    // on the oauth callback page, this component should receive the query
    // string params as the `callbackPayload` param. If it is present, try to
    // pass this back to the parent window
    if (this.callbackPayload) {
      // TODO: handle ios webview scenario using storage
      if (isBrowserIosWebview) {
        throw new Error('Oauth login is not yet supported in a webview.');
        // if provider exists in storage, then treat as a no-popup execution
      } else if (storage.getItem('oauth-provider')) {
        this.oauthCallbackHandler(this.callbackPayload);
        // try to locate the parent window
      } else if (window.opener) {
        window.opener.oauthCallback(this.callbackPayload);
        window.close();
      } else {
        // eslint-disable-next-line no-alert
        alert('Something went wrong - please contact support');
      }
    }
  },
};
</script>
