<template lang='pug'>
.todo-section(
  :class="classes"
)
  component.todo-section__header(
    :is='headerComponentType'
    :to="routerLinkTo"
    @click='headerClickHandler'
  )
    .todo-section__status-icon
      slot(name='status-icon')
        icon(:name='statusIcon')
    .todo-section__header-text
      h4.todo-section__title
        slot(name='title')
          span(v-if='title') {{ title }}
          i(v-else-if='emptyTitle') {{ emptyTitle }}

      .todo-section__details
        slot(v-if='!isOpen' name='closed-details')
        slot(name='details')
          template(v-if='details')
            span(v-if='_isString(details)') {{ details }}
            ul(v-else-if='_isArray(details) && details.length')
              li(v-for='detail in details') {{ detail }}

    .todo-section__action-icon(v-if='actionIconComputed')
      icon(:name='actionIconComputed')
    .todo-section__progress-bar
      .todo-section__progress-bar-fill
  transition(name='slide-open')
    .todo-section__content(v-if='isOpen && !disabled')
      slot
</template>

<script>
import _ from 'lodash';

const components = {};

export default {
  components,
  props: {
    pending: Boolean,
    complete: Boolean,
    optional: Boolean,
    error: Boolean,
    title: String,
    emptyTitle: String,
    label: String,
    details: [Array, String],
    linkTo: [String, Object], // passed to router-link props
    actionType: String,
    icon: String,
    actionIcon: String,
    noAction: Boolean,
    clickAction: Function,
    disabled: Boolean,
    startOpen: Boolean,
    theme: String,
  },
  data() {
    return {
      isOpen: this.startOpen,
    };
  },
  computed: {
    headerComponentType() {
      if (this.linkTo) return 'router-link';
      return 'header';
    },
    routerLinkTo() {
      if (!this.linkTo) return false;
      return typeof this.linkTo === 'string'
        ? { name: this.linkTo }
        : { ...this.linkTo };
    },
    statusIcon() {
      // pass in an icon to show it
      if (this.icon) {
        return this.icon;
      } else if (this.actionType === 'add') {
        return 'plus-circle';
      } else if (this.error) {
        // otherwise use error/complete to show a status
        return 'exclamation-circle';
      } else if (this.pending) {
        return 'exclamation-circle';
      } else if (this.complete) {
        return 'check-circle';
      } else if (this.optional) {
        return 'minus-circle';
      }

      return 'circle';
    },
    actionIconComputed() {
      if (this.actionIcon) {
        return this.actionIcon;
      } else if (this.noAction) {
        return null;
      } else if (this.linkTo) {
        return 'external-link-square-alt';
      } else if (this.actionType === 'call') {
        return 'phone';
      } else if (this.actionType === 'fix') {
        return 'wrench';
      } else if (this.expandable && !this.disabled) {
        return 'angle-right';
      }

      return null;
    },
    computedTheme() {
      if (this.theme) return this.theme;
      if (this.disabled) return 'disabled';
      if (this.pending) return 'yellow';
      if (this.complete) return 'green';
      if (this.error) return 'red';
      if (this.actionType === 'add') return 'add';
      return 'default';
    },
    classes() {
      return {
        'is-open': this.isOpen,
        'is-closed': !this.isOpen,
        'no-action': this.noAction,
        'has-action': !this.noAction,
        [`todo-section--theme-${this.computedTheme}`]: true,
      };
    },
    expandable() {
      if (this.clickAction) return false;
      return true;
    },
  },
  methods: {
    _isString: _.isString,
    _isArray: _.isArray,
    headerClickHandler() {
      if (this.noAction) {
        return;
      }

      if (this.clickAction) {
        this.clickAction();
      } else {
        this.isOpen = !this.isOpen;
      }
    },
    toggleOpen() {
      this.headerClickHandler();
    },
    open() {
      if (!this.clickAction && !this.disabled) this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang='less'>
.todo-section {
  padding: 5px;
  margin-bottom: 2px;
  border-radius: 5px;
  display: block;
  transition: background-color 0.5s;

  &.has-action {
    border: 1px solid #cccccc;
  }
  
  &.has-action:hover {
    // background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
  
  a.todo-section__header {
    color: inherit;
  }

  .todo-section__header {
    padding: 3px 3px 3px;
    display: flex;
    align-items: center;
    position: relative;

    a {
      color: inherit;
      text-decoration: underline;
      .icon {
        &:hover {
          color: #000;
        }
      }
    }
  }

  .todo-section__header-text {
    flex: 1 0 0;
  }

  .todo-section__title {
    color: currentColor;
    font-size: 18px;
  }

  .todo-section__details {
    font-size: 14px;
    line-height: 1.5em;

    ul {
      margin: 5px;
      padding-left: 20px;
      // padding: 0;
    }
  }

  .todo-section__status-icon {
    margin-right: 10px;
    flex: 0 0 auto;
    height: 30px;

    .icon {
      width: 30px;
      height: 30px;
    }
  }

  .todo-section__action-icon {
    flex: 0 0 1;
    margin-left: 20px;

    .icon {
      width: 30px;
      height: 30px;
      transition: all 0.25s;
    }
  }

  .todo-section__content {
    background: white;
    color: @bluegray-text;
    // padding: 10px;
    overflow: hidden;
    border-radius: 3px;

    > .form-row:first-child {
      border-top: none;
    }
  }

  // .todo-section__progress-bar {
  //   height: 5px;
  //   width: 100%;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   background: rgba(0,0,0,.2);
  //   overflow: hidden;
  //   border-radius: 2px;

  // }
  // .todo-section__progress-bar-fill {
  //   width: 55%;
  //   height: inherit;
  //   background: @brand-blue;
  // }

  &.is-open {
    margin-bottom: 4px;
    > .todo-section__header {
      padding-bottom: 8px;
    }
    // have to be specific to handle if these are nested
    > .todo-section__header > .todo-section__action-icon .icon {
      transform: rotate(90deg);
    }
    > .todo-section__content {
    }
  }
  &.is-closed {
    > .todo-section__content {
      // height: 0px;
    }
  }

  .slide-open-enter-active,
  .slide-open-leave-active {
    // transition: all .25s;
    // height: 200px;
    // height: auto;
  }
  .slide-open-enter,
  .slide-open-leave-to {
    height: 0;
    opacity: 0;
  }

  // Colors / Themes
  &.todo-section--theme-default {
    // background: lighten(@navy, 20);
  }
  &.todo-section--theme-green {
    // background: @green;
  }
  &.todo-section--theme-yellow {
    // background: #daa601;
  }
  &.todo-section--theme-red {
    // background: #820901;
  }
  &.todo-section--theme-add {
    // background: lighten(@navy, 38);
    // background: #6da8ca;
    // .todo-section__header { color: #333; }
  }
  &.todo-section--theme-gray-blue {
    // background: @brand-gray-blue;
  }
  &.todo-section--theme-transparent {
    // background: fade(@navy, 50);
  }

  &.todo-section--theme-disabled {
    // background: #aaa;
    .todo-section__header {
      cursor: not-allowed;
      pointer-events: none;
    }
    opacity: 0.4;
    // .todo-section__header { color: #333; }
  }

  // if these are nested, make children smaller?
  .todo-section {
    padding: 4px;

    .todo-section__title {
      font-size: 15px;
    }
    .todo-section__status-icon,
    .todo-section__action-icon {
      .icon {
        width: 26px;
        height: 26px;
      }
    }
  }
}
</style>
