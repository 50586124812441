<template lang='pug'>
main-layout#page-coach-schedule()
  .client-dash-home
    h2 Welcome back {{ coach.firstName }}
    h3 Upcoming sessions
    template(v-if='getSessionsRequest.isPending')
      div Loading...
    error-message(v-else-if='getSessionsRequest.isError' :request-status='getSessionsRequest')
    template(v-else)
      template(v-if='upcomingSessions.length')
        session-details(
          v-for='session in upcomingSessions' :key='session.id'
          :session='session'
          view-mode='coach'
        )
      template(v-else)
        p No upcoming sessions.
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'session-details': require('@/app-public/common/other/session-details')
    .default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {},
  computed: {
    ...mapGetters('authUser/profile', {
      coach: 'user',
      currentCoachId: 'userId',
    }),
    ...mapGetters('authUser/coaching', [
      'clientsList',
      'upcomingSessions',
      'selectedClientUpcomingSessions',
    ]),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
    ...mapRequestStatuses({
      getSessionsRequest: 'authUser/coaching/GET_COACH_SESSIONS',
    }),
  },
  beforeMount() {
    let redirect;

    if (!this.user.isCoach) {
      // redirect = 'welcome';
    }

    if (redirect) {
      console.log('REDIRECT: ', redirect);
      this.$router.replace({ name: redirect });
    } else {
      this.$store.dispatchApiAction('authUser/coaching/GET_COACH_SESSIONS');
    }
  },
};
</script>

<style lang='less'>
#page-coach-schedule {
  .main-content {
    height: 100vh;
  }

  .client-dash-home {
    max-width: 1000px;
    padding: 20px;
    margin: 0 auto;

    h2 {
      margin: 0;
    }
  }

  .client-details-wrap {
    padding: 0 20px;
  }

  .potential-match-info {
    background: white;
    border: 1px solid @navy;
    color: @navy;
    padding: 5px;
    // padding-left: 50px;
    border-radius: 10px;
    // color: white;
    position: relative;
    margin: 20px 0;

    .icon {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }

  .match-percent {
    position: absolute;
    left: -10px;
    top: -10px;
    font-weight: 700;
    font-size: 18px;
    background: @navy;
    color: white;
    display: inline-block;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
  }
}
</style>
