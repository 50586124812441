<template lang='pug'>
.back-heading-button
  router-link(:to='to || { name: toRoute }')
    icon(name='arrow-left' scale='1.2')
    span {{ text }}
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

const components = {};

export default {
  components,
  mixins: [],
  props: {
    text: {
      type: String,
      default: 'Back',
    },
    to: Object,
    toRoute: String,
  },
};
</script>

<style lang='less'>
.back-heading-button {
  margin: 8px 0;
  text-align: center;

  a {
    height: 32px;
    display: inline-flex;
    align-items: center;
  }

  span {
    padding-left: 8px;
    font-size: 16px;
  }
}
</style>
