<template lang='pug'>
.assessment-subpage

  template(v-if='userIsInDemoMode')
    error-message(warning) THIS IS A DEMO - you will see a short sample of our LeaderMatch assessment questions and results
    br

  div(v-if='getQuestionRequest.isPending') Loading...
  div(v-else-if='getQuestionRequest.isError')
    error-message(:request-status='getQuestionRequest')

  template(v-else-if='user.assessmentCompletedAt || (question && question.assessmentComplete)')
    h3 Thank you for completing the LeaderMatch assessment!

    template(v-if='getAssessmentRequest.isPending')
      p Loading your results...
      icon(scale='10' name='spinner')
    template(v-else-if='getAssessmentRequest.isError')
      error-message(:request-status='getAssessmentRequest')
    template(v-else)
      p.bold We use this profile to help match you with your ideal coach.</b>
      p.small Please note that there is no "ideal" score, so don't worry about any trait being scored too high or low.

      assessment-results(:assessment='assessment')

      form-row(v-if='(user.isExecutive && !user.currentCoachId) || (user.isCoach && user.coachStatus !== "activated")')
        v-button(
          :to-named-route='user.isCoach ? "availability-settings" : "coach-matches"' size='large' icon='arrow-right' theme='transparent-dark'
          :disabled='!onboardingProgress.assessment'
        ) Continue

  template(v-else-if='question')
    .total-questions Question \#{{ question.currentIndex + 1 }} of {{ question.totalQuestions }}
    .progress-bar
      .progress-bar-fill(:style='{width: `${progressPercent}%`}')

    .current-question(:class='emptyResponseWarning ? "is-missing-answer" : ""')
      .question-prompt {{ question.prompt }}
      .question-options
        label.question-response-option(v-for='index in [0,1,2,3,4,5]' :key='`q-${question.id}-${index}`')
          //- .answer-quick-key {{ keysArray[index].toUpperCase() }}
          input.answer-input(
            type='radio' name='currentQuestion' :value='index'
            @change='radioChangeHandler'
            :checked='index === currentQuestionAnswer'
          )
          .answer-radio {{ keysArray[index].toUpperCase() }}
          .answer-label(v-if='index === 0') Disagree<br/>Strongly
          .answer-label(v-if='index === 5') Agree<br/>Strongly

      .likert-instruction choose the option that best describes you

      v-button.submit-answer-button(
        @click='submitAnswerButtonHandler'
        :request-status='answerQuestionRequest' loading-text='Saving Response...'
        size='large'
      ) Submit Response
      .keyboard-instructions
        | Want to go faster? Use your keyboard!
        br
        | Hit the number key to select an option and submit your response.

  template(v-else)
    h3 Take the assessment
    p We use a proprietary personality assessment to match like-minded coaches and executives.

    p Please take the questions seriously, but don't worry if you accidentally submit a "wrong" answer. There are many questions, so it will all even out.
    p
      | <b>It should take 10-15 minutes to complete this assessment.</b>
      br
      | You do not have to complete it one sitting, but we recommend that you do!

    form-row
      v-button(size='large' @click='loadFirstQuestion') Begin the assessment



</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'assessment-results': require('@/components/other/assessment-results').default,
};

const INPUT_KEYS = '123456'.split('');

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Take the LeaderMatch personality assessment',
  },
  data() {
    return {
      currentQuestionAnswer: null,
      emptyResponseWarning: false,
    };
  },
  computed: {
    ...mapGetters('authUser/profile', [
      'user',
      'onboardingProgress',
      'userIsInDemoMode',
    ]),
    ...mapGetters('authUser/assessment', {
      question: 'nextAssessmentQuestion',
      assessment: 'assessment',
    }),
    ...mapRequestStatuses({
      getAssessmentRequest: 'authUser/assessment/GET_ASSESSMENT_DETAILS',
      getQuestionRequest: 'authUser/assessment/GET_NEXT_ASSESSMENT_QUESTION',
      answerQuestionRequest: 'authUser/assessment/ANSWER_ASSESSMENT_QUESTION',
    }),
    keysArray() {
      return INPUT_KEYS;
      // if (!this.question || !this.question.options) return [];
      // const numOptions = this.question.options.length;
      // const numKeysPerSide = Math.floor(numOptions / 2);
      // return [
      //   ...INPUT_KEYS.slice(0, numKeysPerSide),
      //   ...INPUT_KEYS.slice(-numKeysPerSide),
      // ];
    },
    progressPercent() {
      return (
        (100 * (this.question.currentIndex + 1)) / this.question.totalQuestions
      );
    },
  },
  beforeMount() {
    // Redirect if not allowed to see this page.
    if (!(this.user.isExecutive || this.user.isCoach)) {
      this.$router.replace({ name: 'welcome' });
      return;
    }

    if (this.user.assessmentCompletedAt) {
      this.loadResults();
    } else if (this.user.assessmentCreatedAt) {
      this.loadFirstQuestion();
    }

    window.addEventListener('keypress', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.keyHandler);
  },
  methods: {
    radioChangeHandler(event) {
      this.currentQuestionAnswer = parseInt(event.target.value);
    },
    async keyHandler($event) {
      if (this.answerQuestionRequest.isPending) return;
      if (this.getQuestionRequest.isPending) return;

      // some checks copied from vue router
      const {
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
        button,
        defaultPrevented,
      } = $event;
      // don't handle with modifier keys
      if (metaKey || altKey || ctrlKey || shiftKey) return;
      if (defaultPrevented) return; // don't handle when preventDefault called

      const answer = this.keysArray.indexOf($event.key.toLowerCase());
      if (answer === -1) return;
      this.currentQuestionAnswer = answer;
      this.submitAnswerButtonHandler();
    },
    loadFirstQuestion() {
      this.$store.dispatchApiAction(
        'authUser/assessment/GET_NEXT_ASSESSMENT_QUESTION',
      );
    },
    async submitAnswerButtonHandler() {
      if (this.currentQuestionAnswer === null) {
        this.emptyResponseWarning = true;
        return;
      }
      await this.$store.dispatchApiAction(
        'authUser/assessment/ANSWER_ASSESSMENT_QUESTION',
        {
          questionId: this.question.id,
          answer: this.currentQuestionAnswer,
        },
      );
      this.currentQuestionAnswer = null;
      this.emptyResponseWarning = false;
    },
    async loadResults() {
      this.$store.dispatchApiAction(
        'authUser/assessment/GET_ASSESSMENT_DETAILS',
      );
    },
  },
};
</script>

<style lang='less'>
.assessment-subpage {
  .current-question {
    text-align: center;
  }

  .question-options {
    display: flex;
    .question-response-option {
      display: block;
      flex: 1 0 0;
      text-align: center;
      cursor: pointer;
      padding: 10px;
      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }
      .answer-label {
        margin-top: 8px;
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
      }
      .answer-radio {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        background: white;
        border: 2px solid @navy;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        font-weight: normal;
        color: #bbb;
        position: relative;

        // radio fill
        &:after {
          content: '';
          display: block;
          position: absolute;
          background: @brand-gray-blue;
          top: 2px;
          bottom: 2px;
          right: 2px;
          left: 2px;
          border-radius: 50%;
          opacity: 0;
        }
      }
      input:checked ~ .answer-radio {
        &:after {
          opacity: 1;
        }
      }

      &:nth-child(1),
      &:nth-child(6) {
        .answer-radio {
          width: 60px;
          height: 60px;
          line-height: 60px;
          margin-top: 0px;
        }
      }
      &:nth-child(2),
      &:nth-child(5) {
        .answer-radio {
          width: 50px;
          height: 50px;
          line-height: 50px;
          margin-top: 5px;
        }
      }

      &:hover {
        .answer-radio:after {
          opacity: 0.3;
        }
      }
    }
  }
  .progress-bar {
    height: 10px;
    width: 100%;
    background: #555;
    border-radius: 5px;
    overflow: hidden;
    .progress-bar-fill {
      height: inherit;
      background-color: @brand-gray-blue;
      transition: width 0.5s;
      border-radius: inherit;
    }
  }

  .likert-instruction {
    // font-style: italic;
  }
  // user tried to click submit without selecting an answer
  .is-missing-answer {
    .likert-instruction {
      color: @error-red-text;
      font-weight: 700;
    }
  }

  .question-prompt {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.3em;
    margin: 2em 1em;
  }

  .keyboard-instructions {
    color: #aaa;
    @media @mq-small-only {
      display: none;
    }
  }

  .submit-answer-button {
    width: 100%;
    margin: 20px 0;
  }
  .select-response-warning {
    text-align: center;
    color: @error-red-text;
  }
}
</style>
