<template lang='pug'>
.document-upload
  .document-upload-form
    form-row
      form-input(
        type='file'
        :label='label'
        v-model='file'
      )
    .document-upload-form-submit
      v-button(size='large' icon='file-upload' theme='dark'
        :disabled='!file && !uploading'
        @click='uploadFile'
    ) {{ !uploading ? 'Upload Document' : 'Uploading...' }}
  .document-upload-progress(v-if='uploading')
    icon(name="spinner" scale="2")
  .document-upload-result
    .document-upload-success(v-if='!file && success') 
      p Uploaded document successfully.
    .document-upload-error(v-if='errorMessage') 
      p {{ errorMessage }}
</template>

<script>
import _ from 'lodash';

const components = {};

export default {
  components,
  data() {
    return {
      file: '',
      uploading: false,
      success: false,
      errorMessage: '',
    };
  },
  props: {
    handleUpload: { type: Function },
    label: { type: String, default: 'Upload Document' },
  },
  computed: {
    computedStyles() {
      return {};
    },
  },
  methods: {
    async uploadFile() {
      if (this.uploading || !this.file) {
        return;
      }

      console.log('Upload file...');

      this.uploading = true;
      this.success = false;

      try {
        await this.handleUpload(this.file);
        this.file = undefined; // Clear the file.
        this.success = true;
      } catch (e) {
        // Failed uploading.
        this.errorMessage = 'An error occured while uploading.';
      }

      this.uploading = false;
    },
  },
};
</script>

<style lang='less'>
.document-upload {
  padding: 20px 0;

  .document-upload-progress {
    display: inline-block;
    margin-left: 8px;
  }

  .document-upload-result {
    .document-upload-error {
      color: red;
      font-weight: 700;
    }
  }

  .document-upload-form-submit {
    margin-left: 12px;
  }
}
</style>