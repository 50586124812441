import _ from 'lodash';
import Vue from 'vue';
// import * as Sentry from '@sentry/browser';

import { buildApiActions } from '../utils/vuex-api-utils';

const allPropsFilled = (obj, props) => obj && _.every(props, (p) => !_.isNil(obj[p]));

const REQUIRED_GOOGLE_SCOPES = [
  'https://www.googleapis.com/auth/calendar.events.readonly',
  'https://www.googleapis.com/auth/calendar.readonly',
];

export default {
  namespaced: true,
  state() {
    return {
      user: {},
      userMatchingCoaches: {},
      userCurrentCoach: null,
      coaches: {},
    };
  },
  getters: {
    userId: (state) => state.user.id,
    companyId: (state) => state.user.companyId,

    user: (state) => state.user,
    company: (state) => _.get(state, 'user.company', {}),

    userEvaluationsEnabled: (state, getters) => {
      const { assessmentType = null } = getters.company;
      let evaluationsEnabled = true;

      if (assessmentType) {
        evaluationsEnabled = assessmentType !== 'disabled';
      }

      return evaluationsEnabled;
    },

    bestName: (state) => state.user.firstName || 'Friend',
    userCurrentCoach: (state) => state.userCurrentCoach,
    userMatchingCoaches: (state) => _.values(state.userMatchingCoaches),
    userDataIsLoaded: (state) => state.user.createdAt,
    userIsInDemoMode: (state) => _.get(state, 'user.company.status') === 'demo',
    isBasicInfoComplete: (state) => {
      if (
        !allPropsFilled(state.user, [
          'firstName',
          'lastName',
          'email',
          'address', // 'phone',
        ])
      ) return false;
      if (
        !allPropsFilled(state.user.address, [
          'line1',
          'city',
          'state',
          'country',
          'postalCode',
        ])
      ) return false;
      if (state.user.isExecutive && !state.user.jobTitle) return false;
      if (state.user.isCoach && !state.user.profilePic) return false;
      if (state.user.isCoach) {
        if (!allPropsFilled(state.user, ['phone'])) return false;
      }
      return true;
    },
    isExecutivePreferencesComplete: (state) => {
      const prefs = state.user.executivePreferences;
      if (!prefs) return false;
      if (!_.isBoolean(prefs.hasPreviousCoachingExperience)) return false;
      if (!prefs.skills || !prefs.skills.length) return false;
      return true;
    },
    isResumeComplete: (state) => {
      const { careerHistory, educationHistory, accoladeHistory } = state.user;
      if (!careerHistory || !careerHistory.length) return false;
      if (!educationHistory || !educationHistory.length) return false;
      return true;
    },
    isBioComplete: (state) => {
      if (state.user.isCoach && !state.user.tagline) return false;
      if (!state.user.bio) return false;
      return true;
    },
    isCoachSettingsComplete: (state) => {
      const settings = state.user.coachSettings || {};
      if (!settings.industries || !settings.industries.length) return false;
      if (!settings.companySizes || !settings.companySizes.length) return false;
      if (!settings.skills || !settings.skills.length) return false;
      return true;
    },

    isScheduleComplete: (state) => {
      // at least one day must have some hours
      if (_.every(state.user.availability, (hours) => !hours)) return false;
      return true;
    },

    isEvaluationRespondentsComplete: (state) => {
      const { evaluationRespondents } = state.user;

      if (!evaluationRespondents) {
        return false;
      }

      const evaluationRespondentsByRole = _.groupBy(
        evaluationRespondents,
        'role',
      );
      const bosses = evaluationRespondentsByRole.boss;
      const peers = evaluationRespondentsByRole.peer;
      const directReports = evaluationRespondentsByRole.direct_report;

      if (bosses.length < 1 || peers.length < 2 || directReports.length < 2) {
        return false;
      }
      return true;
    },

    coachCalendarIsShared: (state) => {
      const { coachGoogleCalendarPublic } = state.user;
      return Boolean(coachGoogleCalendarPublic);
    },

    coachGoogleCalendarId: (state) => {
      const { coachGoogleCalendarId } = state.user;
      return coachGoogleCalendarId;
    },

    icalCoachGoogleCalendarUrl: (state, getters) => {
      const { coachGoogleCalendarId } = getters;

      if (coachGoogleCalendarId) {
        return `https://calendar.google.com/calendar/ical/${coachGoogleCalendarId}/public/basic.ics`;
      }

      return undefined;
    },

    coachGoogleCalendarUrl: (state, getters) => {
      const { coachGoogleCalendarId } = getters;

      if (coachGoogleCalendarId) {
        const { timezone } = state.user;
        return `https://calendar.google.com/calendar/embed?src=${coachGoogleCalendarId}&ctz=${timezone}`;
      }
      return undefined;
    },

    linkCoachGoogleCalendarUrl: (state, getters) => {
      const { coachGoogleCalendarId } = getters;

      if (coachGoogleCalendarId) {
        const { timezone } = state.user;
        return `https://calendar.google.com/calendar/r?cid=${coachGoogleCalendarId}&ctz=${timezone}`;
        // return `https://calendar.google.com/calendar/embed?src=${coachGoogleCalendarId}&ctz=${timezone}`;
      }
      return undefined;
    },

    calendarConnectionStatus: (state) => {
      if (!_.get(state, 'user.googleAuthDetails.id')) {
        return 'not_connected';
      }

      const scopes = _.get(state, 'user.googleAuthDetails.scopes', []);
      if (_.difference(REQUIRED_GOOGLE_SCOPES, scopes).length > 0) {
        return 'insufficient_permissions';
      }
      if (
        !_.find(state.user.externalCalendarDetails, {
          affectsAvailability: true,
        })
      ) {
        return 'no_calendars_selected';
      }
      return 'connected';
    },

    isCalendarConnectComplete: (state, getters) => getters.calendarConnectionStatus === 'connected'
      || getters.coachCalendarIsShared,

    onboardingProgress: (state, getters) => ({
      profile: _.every([
        getters.isBasicInfoComplete,
        !state.user.isExecutive || getters.isExecutivePreferencesComplete,
        !state.user.isCoach || getters.isCoachSettingsComplete,
        getters.isBioComplete,
        getters.isResumeComplete,
      ]),
      availability: getters.isScheduleComplete,
      assessment: !!_.get(state, 'user.assessmentCompletedAt'),
      matching: !!state.user.currentCoachId,
      coachActivation: state.user.coachStatus === 'activated',
    }),

    getCoachProfileBySlug: (state) => (slug) => _.find(state.coaches, { slug }),
  },
  ...buildApiActions(
    {
      GET_USER: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/users/${ctx.getters.userId}`,
        }),
        mutation: (state, { response }) => {
          state.user = response;

          // add user/business ids to Sentry errors
          // Sentry.configureScope((scope) => {
          //   scope.setUser({
          //     id: response.id,
          //     companyId: response.companyId,
          //     email: response.email,
          //   });
          // });
        },
      },

      UPDATE_USER: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/users/${ctx.getters.userId}`,
          params: _.omit(payload, 'id'),
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      UPDATE_USER_EVAL_RESPONDENTS: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/users/${ctx.getters.userId}/evaluation-respondents`,
          params: _.omit(payload, 'id'),
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      // LEADEREQ/COMPANY ADMIN ONLY
      ADMIN_DELETE_USER: {
        action: (ctx, payload) => ({
          method: 'delete',
          url: `/users/${ctx.getters.userId}`,
          params: {
            fullDelete: payload.fullDelete || false,
          },
        }),
        mutation: (state, { response }) => {
          state.user = {};
        },
      },

      ADMIN_SET_COACH: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/select-coach`,
          params: {
            coachId: payload.coachId,
          },
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      // TODO: ADMIN_SET_USER_MONTHLY_CREDITS_COUNT

      // PUBLIC SITE ONLY
      ADD_USER_AGREEMENT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/legal-agreement`,
          params: payload,
        }),
        mutation: (state) => {
          // TODO: the endpoint currently returns nothing so we set this here instead
          // but we should make the endpoint return a response and use it
          state.user.legalAgreementVersion = process.env.TERMS_AND_POLICY_UPDATED_AT;
        },
      },
      UPDATE_USER_PASSWORD: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/set-password`,
          params: payload,
        }),
        mutation: (state, { response }) => {},
      },
      // ADD_USER_AGREEMENT: {
      //   action: (ctx, payload) => ({
      //     method: 'post',
      //     url: `/users/${ctx.getters.userId}/legal-agreement`,
      //     params: payload,
      //   }),
      // },

      // COACHES /////////////////////////////////////////////////////////////////////////////////////
      TRIGGER_USER_MATCHING: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/match`,
          afterSuccess() {
            ctx.dispatch('api-GET_USER_MATCHING_COACHES');
          },
        }),
      },
      SELECT_COACHES_MATCHING: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/setmatch`,
          params: payload, // coachId1, coachId2, coachId3
          afterSuccess() {
            ctx.dispatch('api-GET_USER_MATCHING_COACHES');
          },
        }),
      },
      GET_USER_MATCHING_COACHES: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/users/${ctx.getters.userId}/matching-coaches`,
        }),
        mutation: (state, { response }) => {
          state.userMatchingCoaches = _.keyBy(response, 'id');
          _.each(response, (coach) => {
            Vue.set(state.coaches, coach.id, coach);
          });
        },
      },

      GET_USER_CURRENT_COACH: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/users/${ctx.state.user.id}/coach`,
        }),
        mutation: (state, { response }) => {
          state.userCurrentCoach = response;
          Vue.set(state.coaches, response.id, response);
        },
      },

      GET_COACH_BY_SLUG: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/coach-profiles/${payload.slug}`,
          keyRequestStatusBy: payload.slug,
        }),
        mutation: (state, { response }) => {
          Vue.set(state.coaches, response.id, response);
        },
      },

      SELECT_USER_COACH: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/select-coach`,
          params: payload, // coachId
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
      FIRE_USER_COACH: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/fire-coach`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      TOGGLE_COACH_SCHEDULE_DELEGATION: {
        action: (ctx, payload) => ({
          method: 'put',
          url: `/users/${ctx.getters.userId}/delegation/scheduling`,
          params: {
            enabled: payload.enabled,
          },
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      // CALENDARS ///////////////////////////////////////////////////////////////////////////////////
      CONNECT_CALENDAR_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/connect-calendar`,
          params: payload, // code -- from oauth flow
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
      REFRESH_CALENDARS: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/refresh-calendars`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
      TOGGLE_SHARE_CALENDAR: {
        action: (ctx, payload) => ({
          method: 'put',
          url: `/users/${ctx.getters.userId}/toggle-share-calendar`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
      UPDATE_CALENDAR: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/users/${ctx.getters.userId}/calendars/${payload.id}`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.user.externalCalendarDetails = [
            ..._.reject(state.user.externalCalendarDetails, {
              id: response.id,
            }),
            response,
          ];
        },
      },

      CREATE_COACHING_CALENDAR: {
        action: (ctx) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/setup-shared-calendar`,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      DESTROY_COACHING_CALENDAR: {
        action: (ctx) => ({
          method: 'post',
          url: `/users/${ctx.getters.userId}/destroy-shared-calendar`,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      TOGGLE_COACH_PAUSE: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/coaches/${ctx.getters.userId}/${
            payload.pause ? 'pause' : 'unpause'
          }`,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },

      // COACH APPROVAL FLOW /////////////////////////////////////////////////////////////////////////
      ACCEPT_COACH: {
        action: (ctx) => ({
          method: 'post',
          url: `/coaches/${ctx.getters.userId}/accept`,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
      REJECT_COACH: {
        action: (ctx) => ({
          method: 'post',
          url: `/coaches/${ctx.getters.userId}/reject`,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
      ACTIVATE_COACH: {
        action: (ctx) => ({
          method: 'post',
          url: `/coaches/${ctx.getters.userId}/activate`,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
      UNACCEPT_COACH: {
        action: (ctx) => ({
          method: 'post',
          url: `/coaches/${ctx.getters.userId}/unaccept`,
        }),
        mutation: (state, { response }) => {
          state.user = response;
        },
      },
    },
    {
      actions: {
        // called after loading the user id from local storage on app initialization
        setUserId: async (ctx, userId) => {
          await ctx.commit('SET_USER_ID', userId);
          await ctx.dispatch('api-GET_USER');
        },
        // called directly after login as the login payload includes user data
        setUserData: async (ctx, user) => {
          await ctx.commit('SET_USER_DATA', user);
        },
        removeEvaluationRespondentByEmail: async (ctx, email) => {
          await ctx.dispatch('api-UPDATE_USER_EVAL_RESPONDENTS', {
            evaluationRespondents: _.reject(
              ctx.state.user.evaluationRespondents,
              { email },
            ),
          });
        },
        loadCurrentUserCoach: async (ctx) => {
          const { userCurrentCoach } = ctx.getters;

          if (!userCurrentCoach) {
            await ctx.dispatch('api-GET_USER_CURRENT_COACH');
          }
        },
        toggleCoachScheduleDelegation: async (ctx, enabled = true) => {
          await ctx.dispatch('api-TOGGLE_COACH_SCHEDULE_DELEGATION', {
            enabled,
          });
        },
      },
      mutations: {
        SET_USER_ID: (state, userId) => {
          state.user = { id: userId };
        },
        SET_USER_DATA: (state, user) => {
          state.user = user;
        },
        CLEAR_USER: (state) => {
          state.user = {};
        },
      },
    },
  ),
};
