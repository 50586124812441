<template lang='pug'>
form-row
  form-input(
    type='container'
    :instructions='`Please select at least ${minimum}`'
    :label='sectionLabel'
    required-warning :required-message='`You must enter at least ${minimum}`'
    :value='respondents.length >= minimum ? true : undefined'
  )
    todo-section(
      v-for='item, itemIndex in respondents' :key='`respondent-${type}-${itemIndex}`'
      icon='user'
      :title='showName(item)' :empty-title='`New ${typeLabel}`'
      :start-open='!item.firstName'
    )
      form-row
        error-message(v-if='openEvaluation' warning)
          | Saving a new respondent or editing an email will add them to your current open evaluation.
        form-input(label='First name' v-model='item.firstName' required)
        form-input(label='Last name' v-model='item.lastName' required)
        form-input(label='Email' v-model='item.email' required type='email')
      form-row
        form-input(
          v-model='item.description' type='textarea'
          label='More context about your relationship'
          placeholder='Friends outside of work? Problems working together?'
        )
      form-row
        v-button(
          @click='removeRespondent(itemIndex)'
          icon='trash' theme='red'
        ) Remove this respondent
    todo-section(
      action-type='add'
      :title='"Add New " + name'
      :click-action='addRespondent'
    )
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

export default {
  mixins: [vuelidateGroupMixin],
  props: {
    minimum: Number,
    type: String,
    name: String,
    openEvaluation: Boolean,
    value: Array,
  },
  data() {
    return {
      respondents: _.cloneDeep(this.value),
    };
  },
  watch: {
    respondents: {
      deep: true,
      handler() {
        this.$emit('input', _.cloneDeep(this.respondents));
      },
    },
    value: {
      handler() {
        if (!_.isEqual(this.value, this.respondents)) {
          this.respondents = _.cloneDeep(this.value);
        }
      },
    },
  },
  computed: {
    typeLabel() {
      return {
        peer: 'Peer',
        boss: 'Boss',
        direct_report: 'Direct Report',
      }[this.type];
    },
    sectionLabel() {
      return {
        peer: 'Peers - colleagues at roughly the same level',
        boss: 'Bosses - those who you report to',
        direct_report: 'Direct reports - those who report to you directly',
      }[this.type];
    },
  },
  methods: {
    showName(item) {
      if (item.firstName && item.lastName) {
        return `${item.firstName} ${item.lastName}`;
      } else if (item.firstName) {
        return item.firstName;
      }
      return null;
    },
    addRespondent() {
      this.$emit('input', [...this.respondents, { role: this.type }]);
    },
    removeRespondent(index) {
      this.$emit('input', _.reject(this.respondents, (v, i) => i === index));
    },
  },
};
</script>

<style lang='less'>
  .center > .form-row-inner {
    justify-content: center;
  }
</style>
