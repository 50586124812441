<template lang='pug'>
.user-auth-form
  h3 Delete User
  p You can disable or delete the user from here.

  form-row
    form-input(type='container' label='Disable Access')
      p You can disable access to a user and prevent them from logging in here. Disabled executive accounts still count against the subscription limit.
      p(v-if='!userIsEnabled') This user is currently disabled.
      v-button(
        v-if='userIsEnabled' icon='user-tie'
        :disabled='!canToggleDisableUser'
        theme='transparent-red'
        @click='disableUserButtonHandler' confirm-click
      ) Deactivate User
      v-button(
        v-else icon='user-tie' theme='transparent-blue'
        :disabled='!canToggleDisableUser' confirm-click
        @click='enableUserButtonHandler' 
      ) Activate User

  form-row
    form-input(type='container' label='Delete User')
      p You can permenantly delete a user. The account and all associated data will be deleted immediately. This cannot be undone.
      form-row
        v-button(@click='deleteUserButtonHandler'
        ) Delete User

  popup(ref='deleteUserPopup' title='Confirm Delete User')
    form-row
      form-input(
        v-model='deleteUserConfig.confirm'
        label='Confirm Delete' 
        type='checkbox'
        :required='deleteUserConfig.confirm'
        required-message='You must confirm deleting this user.'
      ) Confirm Delete
      
    form-row
      v-button(@click='confirmDeleteUserButtonHandler' icon='trash') Delete User Now
      error-message(:request-status='deleteUserRequest')

</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Delete User',
    };
  },
  data() {
    return {
      deleteUserConfig: {
        confirm: false,
        fullDelete: true,
      },
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdmin: 'user',
    }),
    ...mapGetters('selectedUser/profile', ['user']),
    ...mapRequestStatuses({
      updateUserRequest: 'selectedUser/profile/UPDATE_USER',
      deleteUserRequest: 'selectedUser/profile/ADMIN_DELETE_USER',
    }),
    userIsEnabled() {
      return !this.user.cancelledAt;
    },
    canToggleDisableUser() {
      return this.currentAdmin.isCompanyAdmin;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {

    async enableUserButtonHandler() {
      await this.$store.dispatchApiAction('selectedUser/profile/UPDATE_USER', {
        cancelToggle: false,
      });
    },

    async disableUserButtonHandler() {
      await this.$store.dispatchApiAction('selectedUser/profile/UPDATE_USER', {
        cancelToggle: true,
      });
    },

    //
    deleteUserButtonHandler() {
      this.$refs.deleteUserPopup.open();
    },

    async confirmDeleteUserButtonHandler() {
      if (this.$hasError() || !this.deleteUserConfig.confirm) {
        return;
      }

      const currentUser = this.user;

      await this.$store.dispatchApiAction('selectedUser/profile/ADMIN_DELETE_USER', this.deleteUserConfig);
      if (this.deleteUserRequest.isSuccess) {
        this.$refs.deleteUserPopup.close();

        // Redirect back to the company.
        this.$router.push({
          name: 'company-users-dashboard',
          params: { companyId: currentUser.companyId },
        });
      }
    },
  },
};
</script>

<style lang='less'>
</style>
