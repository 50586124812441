import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

import { initializeStore } from '@/utils/vuex-api-utils';

Vue.use(Vuex);

const store = initializeStore({
  state: {},
  modules: {
    auth: require('@/shared-vuex-modules/auth-module').default,
    enums: require('@/shared-vuex-modules/enums-module').default,
    window: require('@/shared-vuex-modules/window-module').default,

    companies: require('@/shared-vuex-modules/companies-module').default,

    chat: require('./vuex-modules/chat-module').default,

    // stores things related to the current logged in user
    authUser: {
      namespaced: true,
      modules: {
        coaching: require('./vuex-modules/coaching-module').default,

        // things we could care about for the current user OR selected user
        profile: require('@/shared-vuex-modules/profile-module').default,
        assessment: require('@/shared-vuex-modules/assessment-module').default,
        booking: require('@/shared-vuex-modules/booking-module').default,
        evaluations: require('@/shared-vuex-modules/evaluations-module').default,
        documents: require('@/shared-vuex-modules/documents-module').default,
        questionnaire: require('@/shared-vuex-modules/questionnaire-module').default,
      },
    },

    // stores things related to the currently "selected user"
    // ie - you are a coach or company admin viewing things about a specific user
    selectedUser: {
      namespaced: true,
      modules: {
        profile: require('@/shared-vuex-modules/profile-module').default,
        assessment: require('@/shared-vuex-modules/assessment-module').default,
        booking: require('@/shared-vuex-modules/booking-module').default,
        evaluations: require('@/shared-vuex-modules/evaluations-module').default,
        documents: require('@/shared-vuex-modules/documents-module').default,
        questionnaire: require('@/shared-vuex-modules/questionnaire-module').default,
      },
    },

  },
  strict: process.env.NODE_ENV !== 'production',
});
window.store = store;

export default store;
