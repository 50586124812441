<template lang='pug'>
.chat-notifications
  //- | {{ connectionStatus }} - {{ windowInFocus }}
</template>

<script>
import { mapGetters } from 'vuex';
import dateFns from 'date-fns';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
};

export default {
  components,
  computed: {
    ...mapGetters({
      authUserId: 'auth/authUserId',
      connectionStatus: 'chat/connectionStatus',
      windowInFocus: 'window/windowVisibleAndFocused',
    }),
    ...mapRequestStatuses({
      getChatAuthRequest: 'chat/GET_TWILIO_AUTH_TOKEN',
    }),
  },
  watch: {
    authUserId() { this.$store.dispatch('chat/init'); },
  },
  mounted() {
    if (this.authUserId) this.$store.dispatch('chat/init');
  },
};
</script>

<style lang='less'>
.chat-notifications {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  background: rgba(0,0,0,.5);
  color: white;
  z-index: 20000;
  text-align: center;
}

</style>