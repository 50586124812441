<template lang='pug'>
main-layout#executive-evaluations
  h2 360 Evaluations

  template(v-if='getUserEvaluationsRequest.isPending')
    h2 Loading...
  template(v-if='getUserEvaluationsRequest.isError')
    h4 There was an error retrieving your evaluations.
    error-message(:request-status='getUserEvaluationsRequest')

  template(v-else-if='!userEvaluations')

  template(v-else)

    template(v-if='!userEvaluations.length')
      h4 No evaluations exist yet
      p Go to your <router-link :to='{name: "evaluation-respondents"}'>profile</router-link> to set respondents and send out requests.
    template(v-else)
      p Need to edit your evaluation respondents or send out evaluation requests? Go to your <router-link :to='{name: "evaluation-respondents"}'>profile</router-link> page.

    //- open evaluation
    template(v-if='openEvaluation && openEvaluationResponses.length')
      h4 Your latest evaluation is still in progress
      table-component(
        :data='openEvaluationResponses' ref='table'
        :show-filter='false' :show-caption='false'
      )
        table-column(label='Name')
          template(slot-scope='row') {{ row.respondentFirstName }} {{ row.respondentLastName }}
        table-column(label='Role')
          template(slot-scope='row') {{ row.respondentRole | desnake }}
        //- table-column2(label='Sent' show='createdAt' type='date')
        table-column2(label='Completed' show='completedAt' type='date')
        table-column2(label='Last Reminded?' show='lastRemindedAt' type='timeago')
        table-column(label='Actions')
          template(slot-scope='row')
            v-button(
              v-if='!row.completedAt && row.respondentRole !== "self"'
              size='small' icon='paper-plane' theme='transparent-dark'
              :disabled='remindedRecently(row.lastRemindedAt)'
              @click='sendEvaluationReminderButtonHandler(row)'
              :request-status='sendEvalRemindersRequests[row.id]'
              loading-text='Sending...'
            ) Send Reminder
            v-button(
              v-if='!row.completedAt && row.respondentRole !== "self"'
              size='small' icon='times' theme='transparent-red'
              @click='$refs.removeRespondentPopup.open(row)'
            ) Remove
            v-button(
              v-if='!row.completedAt && row.respondentRole === "self"'
              size='small' icon='clipboard-check'
              :to='{name: "360-evaluation", query: { token: row.token }}'
            ) Take survey

    //- Completed evals
    template(v-if='completedEvaluations.length')
      h3 Completed evaluations

      todo-section(
        v-for='evaluation in completedEvaluations' :key='`evaluation-${evaluation.id}`'
        :icon='evaluation.unlockedByCoachAt ? "clipboard-check" : "lock"'
        :click-action='() => $refs.evalResultsPopup.open(evaluation.id)'
        :disabled='!evaluation.unlockedByCoachAt'
      )
        template(slot='title') {{ evaluation.date | friendly-date }}
        template(slot='details')
          template(v-if='evaluation.unlockedByCoachAt') View evaluation results
          template(v-else) Your coach will review the results with you during your next session

      evaluation-results-popup(ref='evalResultsPopup' view-mode='self')

    remove-respondent-popup(ref='removeRespondentPopup')

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import * as dateFns from 'date-fns';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'remove-respondent-popup': require('./remove-respondent-popup').default,
  'evaluation-results-popup': require('@/app-public/common/other/evaluation-results-popup').default,
};

export default {
  components,
  computed: {
    ...mapGetters('auth', ['userIsLoggedIn']),
    ...mapGetters('authUser/profile', ['user']),
    ...mapGetters('authUser/evaluations', ['userEvaluations']),
    ...mapRequestStatuses({
      getUserEvaluationsRequest: 'authUser/evaluations/GET_EVALUATIONS',
      sendEvalRemindersRequests: 'authUser/evaluations/SEND_EVAL_REMINDER/*',
    }),
    evaluationRespondents() {
      return this.user.evaluationRespondents || [];
    },
    completedEvaluations() {
      return _.filter(this.userEvaluations, (evaluation) => evaluation.completedAt);
    },
    openEvaluation() {
      return _.find(this.userEvaluations, (evaluation) => !evaluation.completedAt);
    },
    openEvaluationResponses() {
      return _.values(this.openEvaluation.evaluationResponses);
    },
  },
  methods: {
    async getUserEvaluations() {
      await this.$store.dispatchApiAction('authUser/evaluations/GET_EVALUATIONS');
    },
    sendEvaluationReminderButtonHandler(evaluationResponse) {
      this.$store.dispatchApiAction('authUser/evaluations/SEND_EVAL_REMINDER', { evaluationResponse });
    },
    remindedRecently(lastRemindedAt) {
      if (!lastRemindedAt) return false;
      const dayReminded = dateFns.parseISO(lastRemindedAt);
      // disable reminder button for 1 day after reminder is sent
      return dateFns.differenceInDays(new Date(), dayReminded) < 1;
    },
  },
  beforeMount() {
    this.getUserEvaluations();
  },
};
</script>

<style lang='less'>
#executive-evaluations{
}
</style>
