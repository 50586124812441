<template lang='pug'>
.page-with-sidebar
  .sidebar-page-container
    a.sidebar-toggle(
      href='#' @click.prevent='toggleSidebarVisiblity'
      :title='((sidebarIsOpen) ? "show" : "hide") + " " + sidebarName'
    )
      slot(name='sidebar-icon')
      icon(:name='(sidebarIsOpen) ? closeSidebarIcon : openSidebarIcon')
    .sidebar-page-header(:class='sidebarIsOpen ? "sidebar-open" : ""')
      .sidebar-page-link
        slot(name='link')
      .sidebar-page-title
        slot(name='title')
      .sidebar-page-tools
        slot(name='tools')
    .sidebar-page-content-wrapper(:class='sidebarIsOpen ? "sidebar-open" : ""')
      .sidebar-container(:class='sidebarIsOpen ? "sidebar-open" : ""')
        slot(name='sidebar')
      .sidebar-page-content(:class='sidebarIsOpen ? "sidebar-open" : ""')
        slot
        
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    sidebarOpen: Boolean, // Input way to trigger sidebar.
    sidebarName: String,
    openSidebarIcon: String,
    closeSidebarIcon: String,
  },
  data() {
    return {
      showSidebar: false,
    };
  },
  computed: {
    sidebarIsOpen() {
      return this.showSidebar || this.sidebarOpen;
    },
  },

  watch: {
    sidebarOpen: {
      handler() {
        this.showSidebar = this.sidebarOpen;
      },
    },
  },
  methods: {
    toggleSidebarVisiblity() {
      this.showSidebar = !this.showSidebar;
      this.$emit('toggle', this.showSidebar);
    },
  },
};
</script>

<style lang='less'>
.page-with-sidebar {
  .sidebar-page-content-wrapper {
    .sidebar-open {
    }
  }

  .sidebar-page-link,
  .sidebar-page-tools {
    a.icon-link {
      display: inline-flex;
      align-items: center;
      height: 100%;

      color: white;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

      .icon {
        width: 60px;
        height: 60px;
        padding: 15px;
      }

      .icon-link-text {
        margin-left: -5px;
        padding-right: 15px;
        font-size: 16px;
      }
    }
  }

  .sidebar-toggle {
    display: inline-block;
    width: 60px;
    color: white;
    position: fixed;
    right: 0;
    top: @sidebar-page-top-bar-offset;
    z-index: 1000;

    .icon {
      width: 60px;
      height: 60px;
      padding: 15px;
    }
  }

  .sidebar-page-header {
    position: fixed;
    top: @sidebar-page-top-bar-offset;
    left: 0;
    right: 0;
    background: lighten(@navy, 15%);
    color: white;
    height: @sidebar-page-top-bar-height;
    z-index: 100;
    display: flex;

    .sidebar-page-tools {
      flex: 1 0 0;
      text-align: right;
      margin-right: @sidebar-page-top-bar-height;
    }

    &.sidebar-open {
      .sidebar-page-tools {
        margin-right: @sidebar-page-sidebar-width;
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }

  .sidebar-page-content {
    max-width: 1150px;
    margin: 0 auto;
    padding: 25px;
    margin-top: 60px;

    &.sidebar-open {
      @media @mq-small-only {
        display: none;  // Don't scroll when sidebar is open and max width.
        overflow: hidden;
        height: 0;
      }
    }
  }

  .sidebar-page-content-wrapper {
    &.sidebar-open {
      margin-right: @sidebar-page-sidebar-width;
    }

    .sidebar-container {
      width: @sidebar-page-sidebar-width;
      position: fixed;
      background: white;

      top: @sidebar-page-top-bar-offset;
      bottom: 0;
      right: -@sidebar-page-sidebar-width;
      z-index: 100;

      &.sidebar-open {
        right: 0px;

        @media @mq-small-only {
          left: 0;
          width: 100%;

          .coach-info-card {
            width: 100%;
          }
        }
      }

      .messages-container {
        margin-top: @sidebar-page-top-bar-height;
      }
    }
  }

  .sidebar-page-status {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;

    > div {
      margin: 2px;
    }

    .notification-square {
    }

    .sidebar-page-coach-status {
      flex: 1 1 auto;

      .sidebar-page-coach-status-content {
        padding: 8px;
      }

      .profile-photo {
        margin: auto;
      }

      .coach-info {
        display: inline-block;
      }

      .coach-name {
        font-size: 1.2em;
        line-height: 1.2em;
        font-weight: 700;
        margin: 8px 0;
      }
    }

    .sidebar-page-questionnaire-status {
      flex: 1 1 500px;
    }
  }
}
</style>
