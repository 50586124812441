<template lang='pug'>
todo-section.users-list-item(
  :title='user.fullName'
  :link-to='{ name: "company-user-basic-info", params: { selectedCompanyUserId: user.id }}'
)
  div(slot='status-icon')
    profile-photo(:user='user')

  div(slot='details')
    .user-roles
      span(v-if='user.isExecutive') Executive
      span(v-if='user.isCompanyAdmin') Company Administrator

</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {
  'profile-photo': require('@/components/other/profile-photo').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    user: Object,
  },
  data() {
    return {

    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang='less'>
.users-list-item {
  .user-roles {
    > span {
      margin-right: 10px;
      display: inline-block;
    }
  }
}

</style>
