<template lang='pug'>
  popup#evaluation-results-popup(
    ref='popup'
    :title='`Evaluation Results - ${evaluation ? evaluation.date : "loading"}`'
    full-screen
  )
    template(v-if='getResultsRequest.isPending')
      form-row(no-inputs)
        h2 Loading...
    template(v-else-if='getResultsRequest.isSuccess')
      form-row(
        no-inputs
      )
        template(v-if='viewMode === "coach"')
          template(v-if='evaluation.unlockedByCoachAt')
            p These results are unlocked and viewable by your client on their dashboard.
            p
              b Please review them together during a coaching session.
              br
              span.italic Keep in mind there are some responses that you can see but they can't.

          template(v-else)
            p These results are not available for your client yet. You should unlock them when you are ready to review them together during a coaching session.

            v-button(
              icon='unlock'
              :request-status='unlockRequest'
              @click='unlockResultsButtonHandler'
              confirm-click
            ) Unlock results for your client

        template(v-else-if='viewMode === "self"')
          p Your colleagues participated voluntarily in order to help you grow. Responses are anonymous and scores are combined by type of working relationship (boss, peer, etc).

          p.bold Please review these results together with your coach and use it to help guide your self-development.

      form-row(no-inputs)
        todo-section.survey-category(
          v-for='category, slug in EVAL_CATEGORIES' :key='`category-${slug}`'
          v-if='evaluation.results[slug]'
          :title='category.label'
        )
          //- adding this empty slot hides the icon
          div(slot='status-icon')

          div(slot='details')
            template(v-if='evaluation.results[slug].summary')
              eval-score-bars(:scores='evaluation.results[slug].summary' size='large')
            template(v-else)
              | Open to view details

          form-row.eval-question(
            v-for='response, qid in evaluation.results[slug]' :key='`question-${qid}-results`'
            v-if='qid !== "summary"'
          )
            form-input(
              type='container'
            )
              div(slot='label') {{ questionsById[qid].label | capitalize }}
              div(slot='instructions' v-if='questionsById[qid].showResultToCoachOnly')
                icon(name='lock')
                |  This question is only visible to you - the coach

              .question-full-prompt {{ questionsById[qid].prompt }}

              div(v-if='questionsById[qid].type === "likert"')
                eval-score-bars(:scores='response')

              ul(v-if='questionsById[qid].type === "text"')
                li(v-if='!response.length') - no responses -
                li(
                  v-for='comment, index in response' :key='`q-${qid}-${index}`'
                ) {{ comment }}

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { EVAL_CATEGORIES } from '@/data/eval-categories';

export const REPLACE_EXP = new RegExp(/(%\[(.*?)\]%)/g);
export const replaceWord = (string, roleIndex) => {
  if (string.match(REPLACE_EXP)) {
    return string.match(REPLACE_EXP)[0].slice(2, -2).split(',')[roleIndex];
  }
  return string;
};

export const chooseWords = (string, roleIndex) => {
  const firstPass = _.replace(string, /(%\[(.*?)\]%)/, replaceWord(string, roleIndex));
  if (firstPass.match(REPLACE_EXP)) {
    return chooseWords(firstPass, roleIndex);
  }
  return firstPass;
};
const components = {
  'eval-score-bars': require('./eval-score-bars').default,
};

export default {
  components,
  props: {
    viewMode: String,
  },
  data() {
    return {
      selectedRespondent: 0,
      sortBy: 'respondent',
      selectedQuestion: null,
    };
  },
  watch: {
  },
  computed: {
    modulePath() { return this.viewMode === 'self' ? 'authUser/' : 'selectedUser/'; },
    evalUser() {
      return this.$store.getters[`${this.modulePath}profile/user`];
    },
    evaluation() {
      return this.$store.getters[`${this.modulePath}evaluations/evaluation`];
    },
    ...mapGetters('auth', ['authUserId']),
    ...mapRequestStatuses({
      getResultsRequest() {
        return `${this.modulePath}evaluations/GET_EVAL_RESULTS`;
      },
      unlockRequest() { return `${this.modulePath}evaluations/UNLOCK_EVALUATION`; },
    }),

    EVAL_CATEGORIES() { return EVAL_CATEGORIES; },

    answersByQuestionId() {
      return _.keyBy(this.answers, 'evaluationQuestionId');
    },

    answersByResponseIdAndQuestionId() {
      const asArray = _.map(this.evaluation.evaluationResponses, (responseGroup) => ({
        responseId: responseGroup.id,
        answersByQuestion: _.keyBy(responseGroup.answers, 'evaluationQuestionId'),
      }));

      return _.keyBy(asArray, 'responseId');
    },
    questionsById() {
      return _.keyBy(this.formattedQuestions, 'id');
    },
    formattedQuestions() {
      if (!this.evaluation) return [];
      const questions = _.cloneDeep(this.evaluation.evaluationQuestions);

      // always show the third person pronouns and verbs in this view
      const roleIndex = 0;
      _.each(questions, (question) => {
        question.prompt = _.replace(question.prompt, /\[name\]/g, this.evalUser.firstName);
        question.prompt = _.replace(question.prompt, /\[company\]/g, this.evalUser.company.name);
        question.prompt = chooseWords(question.prompt, roleIndex);
      });

      return questions;
    },
  },
  methods: {
    open(evaluationId) {
      this.$store.dispatchApiAction(`${this.modulePath}evaluations/GET_EVAL_RESULTS`, { evaluationId });
      this.$refs.popup.open();
    },
    close() { this.$refs.popup.close(); },
    unlockResultsButtonHandler() {
      if (this.evaluation.unlockedByCoachAt) return;
      this.$store.dispatchApiAction(`${this.modulePath}evaluations/UNLOCK_EVALUATION`, {
        evaluationId: this.evaluation.id,
      });
    },
  },
};
</script>

<style lang='less'>
#evaluation-results-popup {
  .question-label {
    font-weight: 700;
  }
  .question-full-prompt {
    font-style: italic;
    font-size: 14px;
    line-height: 1.4em;
    color: #999;
  }
  .survey-category {
    background: @brand-purple;
  }
  .eval-question {
    ul {
      margin: 0;
      padding-left: 20px;
    }
  }
}
</style>
