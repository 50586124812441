<template lang='pug'>
main-layout#page-404(full-width)
  h2 Not found...

</template>

<script>
const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
};
</script>

<style lang='less'>
#page-404 {

}
</style>
