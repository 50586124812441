<template lang='pug'>
#page-company-user-detail
  back-heading-button(toRoute="company-users-dashboard" text="Back To Users Dashboard")
  template(v-if='getUserRequest.isPending')
  template(v-else-if='getUserRequest.isError')
    error-message(:request-status='getUserRequest')
  template(v-else-if='getUserRequest.isSuccess')
    template(v-if='user.id')
      //- Header and Navigation
      user-details-header(:user='user')
        button-nav-container
          router-link(:to='{name: "company-user-basic-info"}') Profile
          router-link(:to='{name: "company-user-schedule"}') Schedule
          router-link(:to='{name: "company-user-coach"}') Coach
          router-link(:to='{name: "company-user-data"}') Data
          router-link(:to='{name: "company-user-auth"}') Auth
          router-link(:to='{name: "company-user-delete"}') Delete
      //- Content View
      router-view
    template(v-if='!user')
      p User Not Found

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'user-details-header': require('@/components/other/user-details-header')
    .default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    selectedCompanyUserId: Number,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('selectedUser/profile', ['user']),
    ...mapRequestStatuses({
      getUserRequest: 'selectedUser/profile/GET_USER',
    }),
  },
  async beforeMount() {
    await this.$store.dispatch(
      'selectedUser/profile/setUserId',
      this.selectedCompanyUserId,
    );
  },
  watch: {
    selectedCompanyUserId() {
      console.log('Set Company User: ', this.selectedCompanyUserId);
      this.$store.dispatch(
        'selectedUser/profile/setUserId',
        this.selectedCompanyUserId,
      );
    },
  },
  methods: {},
};
</script>

<style lang='less'>
#page-company-user-detail {
  margin-bottom: 40px;
}
</style>