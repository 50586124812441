<template lang='pug'>
.user-coach-tab
  h3 Coach
  template(v-if='user.currentCoachId')
    template(v-if='getCoachRequest.isPending') 
      p Loading...
    template(v-else-if='getCoachRequest.isError')
      error-message(:request-status='getCoachRequest')
    template(v-else)
      coach-info(v-if='coach' :coach='coach' noActions=true)
  template(v-else)
    notification-square
      p This user does not have a coach!
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

const components = {
  'coach-info': require('@/app-public/common/other/coach-info').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Coach',
    };
  },
  computed: {
    ...mapGetters('selectedUser/profile', {
      user: 'user',
      coach: 'userCurrentCoach',
    }),
    ...mapRequestStatuses({
      getCoachRequest: 'selectedUser/profile/GET_USER_CURRENT_COACH',
    }),
  },
  methods: {},
  mounted() {
    this.$store.dispatch('selectedUser/profile/loadCurrentUserCoach');
  },
};
</script>

<style lang='less'>
.user-coach-tab {
  
}
</style>
