<template lang='pug'>
.eval-score-bars(:class='computedClasses')
  .eval-score-bars__bar.eval-score-bars__self(:style='{width: `${scores.self * 100}%`}')
  .eval-score-bars__bar.eval-score-bars__boss(:style='{width: `${scores.boss * 100}%`}')
  .eval-score-bars__bar.eval-score-bars__peer(:style='{width: `${scores.peer * 100}%`}')
  .eval-score-bars__bar.eval-score-bars__report(:style='{width: `${scores.direct_report * 100}%`}')
</template>

<script>
export default {
  props: {
    scores: Object,
    size: { type: String, default: 'small' },
  },
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
    computedClasses() {
      return {
        [`eval-score-bars--size-${this.size}`]: true,
      };
    },
  },
  methods: {
  },
};
</script>

<style lang='less'>
.eval-score-bars {
  position: relative;
  margin: 5px 0;
  > .eval-score-bars__bar {
    height: 10px;
    background: @brand-gray-blue;
    border-radius: 0 10px 10px 0;
    position: relative;
    min-width: 55px;
    // margin-bottom: -3px;
    // margin-left: 55px;
    margin-bottom: 1px;
    &:before {
      position: absolute;
      font-size: 9px;
      line-height: 10px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      top: 1px;
      // left: -55px;
      width: 55px;
      // text-align: right;
      padding-left: 3px;
    }
  }

  &.eval-score-bars--size-large {
    > .eval-score-bars__bar {
      height: 16px;
      &:before {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }

  .eval-score-bars__self {
    background: darken(@brand-gray-blue, 20%);
    &:before {
      content: 'Self';
    }
  }
  .eval-score-bars__boss {
    background: lighten(@brand-gray-blue, 0%);
    &:before {
      content: 'Bosses';
    }
  }
  .eval-score-bars__peer {
    background: lighten(@brand-gray-blue, 8%);
    &:before {
      content: 'Peers';
    }
  }
  .eval-score-bars__report {
    background: lighten(@brand-gray-blue, 16%);
    &:before {
      content: 'Reports';
    }
  }
}
</style>