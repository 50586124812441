<template lang='pug'>
router-link.client-list-item(
  :to='{ name: "coach-client-dashboard", params: { selectedClientUserId: user.id }}'
  :class='computedClasses'
)
  profile-photo(:user='user' :placard-text='isPotentialClient ? "Match" : "Client"' size='small')
  chat-unread-indicator(:exec-user-id='user.id')
  .client-name-wrap
    .client-name {{ user.fullName }}
    .company-name {{ user.company.name }}
    .scheduling
      div(v-if='user.nextSession') {{ nextSessionDescription }}
      div(v-else-if='user.lastSession') {{ lastSessionDescription }}
      .no-sessions(v-else) {{ noSessionDescription }}
</template>

<script>
const dateFns = require('date-fns');

const components = {
  'profile-photo': require('@/components/other/profile-photo').default,
  'chat-unread-indicator': require('@/app-public/common/other/chat-unread-indicator').default,
};

export default {
  components,
  props: {
    user: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    computedClasses() {
      return {
        'is-potential-client': this.isPotentialClient,
      };
    },
    isPotentialClient() { return !this.user.currentCoachId; },

    lastSessionDescription() {
      /* eslint-disable consistent-return */
      if (!this.user.lastSession) return;
      const startAt = dateFns.parseISO(this.user.lastSession.startAtUtc);
      const numDays = dateFns.differenceInCalendarDays(new Date(), startAt);
      let whenStr = `${numDays} days ago`;
      if (numDays === 0) whenStr = 'earlier today';
      else if (numDays === 1) whenStr = 'yesterday';
      if (this.user.lastSession.type === 'interview') return `Interviewed ${whenStr}`;
      else if (this.user.lastSession.type === 'coaching') return `Last session - ${whenStr}`;
    },
    nextSessionDescription() {
      /* eslint-disable consistent-return */
      if (!this.user.nextSession) return;
      const startAt = dateFns.parseISO(this.user.nextSession.startAtUtc);
      const numDays = dateFns.differenceInCalendarDays(startAt, new Date());
      let dateStr = dateFns.format(startAt, 'MMM d');
      const timeStr = dateFns.format(startAt, 'h:mm a').replace(':00', '');
      if (numDays === 0) dateStr = 'Today';
      else if (numDays === 1) dateStr = 'Tomorrow';
      if (this.user.nextSession.type === 'interview') return `Interview ${dateStr} @ ${timeStr}`;
      else if (this.user.nextSession.type === 'coaching') return `Next session - ${dateStr} @ ${timeStr}`;
    },
    noSessionDescription() {
      if (this.user.currentCoachId) return 'No sessions scheduled';
      if (!this.user.currentCoachId) return 'No interview scheduled';
    },
  },
  methods: {
  },
};
</script>

<style lang='less'>
.client-list-item {
  background: white;
  padding: 8px 6px 11px;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid #DDD;
  color: @navy;
  position: relative;
  display: flex;

  cursor: pointer;

  &:hover {
    background: #EEE;
    color: @navy;
  }
  
  &.router-link-active {
    background: @navy-lightest;
    color: white;
  }
  &.is-potential-client {
    // background: #EEE;
  }

  .client-name-wrap {
    overflow: hidden;
  }
  .client-name {
    padding-top: 5px;
    font-weight: 700;
  }
  .client-name, .company-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .profile-photo {
    margin-right: 8px;
  }
  .chat-unread-indicator {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .scheduling {
    padding-top: 4px;
    width: 100%;
    font-size: 12px;

    .no-sessions {
      font-style: italic;
    }
  }
}
</style>
