export const SELF_REPORTED_SOURCE_OPTIONS = {
  fb: 'Facebook',
  linkedin: 'Linkedin',
  ig: 'Instagram',
  press: 'Blog or news site',
  print: 'Newspaper or Magazine',
  friend: 'From a friend',
  event: 'At a conference or event',
  direct: 'We contacted you',
  other: 'Other',
};

export const SELF_REPORTED_SOURCE_DETAIL_PROMPTS = {
  fb: 'Ads or a friend?',
  linkedin: 'Ads or a friend?',
  other: 'How?',
  event: 'Which event?',
  friend: 'Who?',
  press: 'Which?',
  print: 'Which?',
};
