import _ from 'lodash';
import Vue from 'vue';
import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  namespaced: true,
  state() {
    return {
      documentsForUserId: null,
      documents: null,
    };
  },
  getters: {
    userId: (s, g, rs, rg) => {
      const userId = rg[`${s._moduleParent}profile/userId`];
      return userId;
    },

    documents: (state) => state.documents,

    hasDocumentsLoaded: (state, getters) => Boolean(state.documents) && Boolean(getters.userId) && getters.userId === state.documentsForUserId,
    canLoadDocuments: (state, getters) => Boolean(getters.userId),
  },
  ...buildApiActions({
    /**
     * Loads all documents for the user.
     */
    LOAD_DOCUMENTS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/documents`,
      }),
      mutation: (state, { ctx, response }) => {
        state.documents = response.documents;
        state.documentsForUserId = ctx.getters.userId;
      },
    },
    /**
     * Uploads a new document.
     */
    UPLOAD_DOCUMENT: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/documents`,
        params: payload,
        options: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      }),
      mutation: (state, { response }) => {
        const document = response;
        state.documents.push(document);
      },
    },
    /**
     * Deletes a specific document
     */
    DELETE_DOCUMENT: {
      action: (ctx, payload) => ({
        method: 'delete',
        url: `/users/${ctx.getters.userId}/documents/${payload.documentId}`,
      }),
      mutation: (state, { payload }) => {
        state.documents = state.documents.filter((x) => x.id !== payload.documentId);
      },
    },
  }, {
    actions: {

      /**
       * Used for loading the current user into the coaching module.
       *
       * Used to prepare access to document getter/setters.
       */
      loadDocuments: async (ctx) => {
        const loadDocuments = !ctx.getters.hasDocumentsLoaded;

        // console.log('Load documents...', loadDocuments);
        // console.log('User: ', ctx.getters.userId);

        if (loadDocuments && ctx.getters.canLoadDocuments) {
          await ctx.commit('CLEAR_DOCUMENTS');
          await ctx.dispatch('api-LOAD_DOCUMENTS');
        }
      },

      uploadFile: async (ctx, file) => {
        const formData = new FormData();
        formData.append('file', file);
        return ctx.dispatch('api-UPLOAD_DOCUMENT', formData);
      },

      deleteDocument: async (ctx, documentId) => ctx.dispatch('api-DELETE_DOCUMENT', {
        documentId,
      }),

    },
    mutations: {
      CLEAR_DOCUMENTS: (state) => {
        state.documents = null;
        state.documentsForUserId = null;
      },
    },
  }),
};
