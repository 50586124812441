<template lang='pug'>
nav.onboarding-nav
  router-link.onboarding-nav__item(
    v-for='item in navItems' :key='`onboarding-nav-${item.routeName}`'
    :to='{name: item.routeName}',
    :class='item.complete ? "is-complete" : ""'
    v-if='item.showIf || item.showIf === undefined'
  )
    .onboarding-nav__circle
      icon(v-if='item.complete' name='check')
    .onboarding-nav__item-label {{ item.label }}
</template>

<script>

const components = {};

export default {
  components,
  props: {
    navItems: Array,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang='less'>


.onboarding-nav {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;

  .onboarding-nav__item {
    color: @brand-blue;
    color: white;
    cursor: pointer;
    flex: 120px 0 0;
    padding: 8px 15px;
    text-align: center;

    &.router-link-active {
      color: white;
      .onboarding-nav__circle {
        border-width: 2px;
        background: fade(@brand-gray-blue, 70);
      }
      // &:after {
      //   content: '';
      //   margin-top: 2px;
      //   height: 2px;
      //   display: block;
      //   background: white;
      // }
    }
  }
  .onboarding-nav__item-label {
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
  }
  .onboarding-nav__circle {
    border: 2px solid currentColor;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto 4px;


    .icon {
      width: 100%;
      height: 100%;
      padding: 5px;
    }
  }
}
</style>
