import _, { update } from 'lodash';
import * as dateFns from 'date-fns';
import Vue from 'vue';

import {
  buildApiActions,
} from '../utils/vuex-api-utils';

export default {
  namespaced: true,
  state() {
    return {
      availability: {},
      sessions: {},
      creditsBalance: 0,
      creditsHistory: [],
    };
  },
  getters: {
    userId: (s, g, rs, rg) => rg[`${s._moduleParent}profile/userId`],

    availability: (state) => state.availability,
    userSessions: (state) => _.sortBy(_.values(state.sessions), 'startAtUtc'),
    userUpcomingSessions: (state, getters) => _.filter(getters.userSessions, (s) => dateFns.parseISO(s.endAtUtc) > new Date()),
    getAllCoachInterviewSessions: (state, getters) => _.filter(getters.userSessions, {
      type: 'interview',
      cancelledAt: null,
    }),
    getInterviewWithCoach: (state, getters) => (coachUserId) => _.find(getters.userSessions, {
      type: 'interview',
      coachUserId,
      cancelledAt: null,
    }),

    creditsBalance: (state) => state.creditsBalance,
    creditsHistory: (state) => state.creditsHistory,
  },
  ...buildApiActions({
    GET_COACH_AVAILABILITY: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/coach-profiles/${payload.coachUserId}/availability`,
        params: _.omit(payload, 'coachUserId'),
      }),
      mutation: (state, {
        payload,
        response,
      }) => {
        state.availability = response;
      },
    },

    CREATE_SESSION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/coach-profiles/${payload.coachUserId}/sessions`,
        params: {
          execUserId: ctx.getters.userId,
          ..._.omit(payload, 'coachId'),
        },
      }),
      mutation: (state, {
        payload,
        response,
      }) => {
        Vue.set(state.sessions, response.session.id, response.session);
        state.creditsBalance = response.newCreditsBalance || 0;
      },
    },

    // SESSIONS /////////////////////////////////
    GET_USER_SESSIONS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/sessions`,
        params: payload,
      }),
      mutation: (state, {
        response,
      }) => {
        state.sessions = _.keyBy(response.sessions, 'id');
        state.creditsBalance = response.creditsBalance || 0;
      },
    },
    CANCEL_SESSION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/sessions/${payload.sessionId}/cancel`,
        params: _.omit('payload', 'sessionId'),
        keyRequestStatusBy: payload.sessionId,
      }),
      mutation: (state, {
        response,
      }) => {
        state.sessions[response.session.id] = response.session;
        state.creditsBalance = response.newCreditsBalance;
      },
    },

    // CREDITS HISTORY / LEDGER ///////////////////
    GET_USER_CREDITS_HISTORY: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/credits-history`,
      }),
      mutation: (state, {
        response,
      }) => {
        state.creditsBalance = response.creditsBalance;
        state.creditsHistory = response.ledger;
      },
    },

    // ADMIN ONLY
    ADD_CREDITS: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/credits`,
        params: {
          creditsToAdd: payload.creditsToAdd,
        },
      }),
      mutation: (state, {
        payload,
      }) => {
        // Reload.
      },
    },

    MARK_CREDIT_DELETED: {
      action: (ctx, payload) => ({
        method: 'patch',
        url: `/users/${ctx.getters.userId}/credits/${payload.creditId}`,
        params: {
          markDeleted: payload.markDeleted,
        },
      }),
      mutation: (state, {
        payload,
      }) => {
        const updated = state.creditsHistory.find((x) => x.creditId === payload.creditId);
        updated.deletedAt = (payload.markDeleted) ? new Date() : undefined;

        const balanceChange = updated.numHours * ((updated.deletedAt) ? -1 : 1);
        state.creditsBalance += balanceChange;
      },
    },

  }, {

    actions: {

      // Admin
      addCredits: async (ctx, creditsToAdd) => {
        await ctx.dispatch('api-ADD_CREDITS', {
          creditsToAdd,
        });

        // Reload credits history.
        await ctx.dispatch('api-GET_USER_CREDITS_HISTORY');
      },

      deleteCredit: async (ctx, creditId) => ctx.dispatch('api-MARK_CREDIT_DELETED', {
        creditId,
        markDeleted: true,
      }),

      undoDeleteCredit: async (ctx, creditId) => ctx.dispatch('api-MARK_CREDIT_DELETED', {
        creditId,
        markDeleted: false,
      }),

    },

  }),
};
