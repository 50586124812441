/* eslint-disable no-param-reassign */

import Axios from 'axios';

import camelizeKeysDeep from 'camelcase-keys-deep';
import decamelizeKeysDeep from 'decamelize-keys-deep';

const api = Axios.create({
  timeout: process.env.API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: '/api',
});
window.api = api; // useful for dev

api.interceptors.request.use((config) => {
  // `state.adminAuthToken` only exists in admin "app" for now
  // but we should add it to the main app once we switch over all api calls
  // to the new API

  if (window.store.state.auth.adminToken) {
    config.headers['x-auth'] = window.store.state.auth.adminToken;
  } else if (window.store.state.auth.token) {
    config.headers['x-auth'] = window.store.state.auth.token;
  }
  return config;
});
api.interceptors.response.use((response) => {
  if (response.config.url.match(/.*\/v[12]\/.*/)
    && response.headers['content-type'].indexOf('application/json') === 0
  ) {
    response.data = camelizeKeysDeep(response.data);
  }
  return response;
});

export default api;
