<template lang='pug'>
.change-coach-view
  p.bold Not happy with your coach?
  p We recommend you try to give your coach a chance and stick with them for at least a month.
  template(v-if='user.isCoachLocked')
    p If you really want to change your coach, please contact support.
  template(v-else)
    p If you really want to change, we will give you new matches and you can select someone else.
    v-button(theme='red' @click='fireCoachButtonHandler') Select a new coach

  popup(ref='fireCoachPopup' title='Are you sure?')
    form-row(no-inputs)
      p
        b {{ userCurrentCoach.firstName }} will no longer be your coach!
        br
        | You will need to interview new matches and select a new one.

    form-row(slot='bottom')
      error-message(:request-status='fireCoachRequest')
      v-button(
        @click='confirmFireCoachButtonHandler'
        :request-status='fireCoachRequest' loading-text='Removing your coach...'
      ) Yes - Find me a new coach

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {};

export default {
  components,
  metaInfo() {
    return {
      title: `Coach Profile - ${_.get(this.coach, 'fullName', this.coachSlug)}`,
    };
  },
  props: {
    coachSlug: { type: String, required: false },
  },
  data() {
    return {
      showChat: false,
    };
  },
  computed: {
    ...mapGetters('authUser/profile', [
      'userIsInDemoMode',
      'user',
      'userCurrentCoach',
      'userMatchingCoaches', 'onboardingProgress',
    ]),
    ...mapRequestStatuses({
      fireCoachRequest: 'authUser/profile/FIRE_USER_COACH',
    }),
  },
  watch: {},
  beforeMount() {},
  methods: {
    fireCoachButtonHandler() {
      this.$refs.fireCoachPopup.open();
    },
    async confirmFireCoachButtonHandler() {
      await this.$store.dispatchApiAction('authUser/profile/FIRE_USER_COACH');

      if (this.fireCoachRequest.isSuccess) {
        this.$refs.fireCoachPopup.close();
        // Redirect to the coach selection view.
      }
    },
  },
};
</script>

<style lang='less'>
.change-coach-view {
}
</style>
