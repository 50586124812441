<template lang='pug'>
.user-auth-form
  h3 User Auth Settings
  form-row
    form-input(type='container' label='Coaching Access')
      p(v-if='userCoachingIsEnabled') Coaching is Enabled
      p(v-else) Coaching is Paused as of {{ user.execLicensePausedAt | date() }}
      p(v-if='!user.isExecutive && !companyCanAddExec') Your company is at the max number of licenses.
      v-button(
        v-if='userCoachingIsEnabled' icon='user-tie'
        :disabled='!canPauseCoaching'
        theme='transparent-red'
        @click='disableCoachingButtonHandler' confirm-click
      ) Pause Coaching
      v-button(
        v-else icon='user-tie' theme='transparent-blue'
        :disabled='!canUnpauseCoaching' confirm-click
        @click='enableCoachingButtonHandler' 
      ) Unpause Coaching

    form-input(type='container' label='Administrator Access')
      p {{ user.isCompanyAdmin ? "Admin Access Granted" : "No Admin Access" }}
      v-button(
        v-if='user.isCompanyAdmin' icon='users-cog'
        :disabled='user.id === currentAdmin.id'
        theme='transparent-red'
        @click='removeAdminAccessButtonHandler' confirm-click
      ) Remove admin access
      v-button(
        v-else icon='users-cog' theme='transparent-blue'
        @click='grantAdminAccessButtonHandler' confirm-click
      ) Grant admin access
      
</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Company User Auth',
    };
  },
  data() {
    return {
      setPasswordPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdmin: 'user',
    }),
    ...mapGetters('selectedUser/profile', ['user']),
    ...mapGetters('companies', {
      company: 'selectedCompany',
      companyUsers: 'selectedCompanyUsers',
      activeUsers: 'selectedCompanyActiveUsers',
      passiveUsers: 'selectedCompanyPassiveUsers',
      companyCanAddExec: 'companyCanAddExec',
    }),
    ...mapRequestStatuses({
      getCompanyRequest: 'companies/GET_COMPANY',
      getCompanyUsersRequest: 'companies/GET_COMPANY_USERS',
      inviteUserRequest: 'companies/INVITE_COMPANY_USER',
    }),
    userCoachingIsEnabled() {
      return !this.user.execLicensePausedAt;
    },
    canPauseCoaching() {
      return this.currentAdmin.isCompanyAdmin;
    },
    canUnpauseCoaching() {
      return this.currentAdmin.isCompanyAdmin && this.companyCanAddExec;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {

    enableCoachingButtonHandler() {
      this.$store.dispatch('companies/triggerCompanyAction', {
        id: this.user.id,
        action: 'unpause-license',
      });
    },

    disableCoachingButtonHandler() {
      this.$store.dispatch('companies/triggerCompanyAction', {
        id: this.user.id,
        action: 'pause-license',
      });
    },

    removeAdminAccessButtonHandler() {
      this.$store.dispatchApiAction('companies/UPDATE_COMPANY_USER', {
        id: this.user.id,
        isCompanyAdmin: false,
      });
    },

    grantAdminAccessButtonHandler() {
      this.$store.dispatchApiAction('companies/UPDATE_COMPANY_USER', {
        id: this.user.id,
        isCompanyAdmin: true,
      });
    },

  },
};
</script>

<style lang='less'>
</style>
