<template lang='pug'>
component.button(
  :is='tagType'
  :to='routerLinkTo'
  :href='href'
  :class='classes'
  :target='targetBlank ? "_blank" : "_top"'
  :type='buttonType'
  @click='clickHandler'
  ref='button'
)
  template(v-if='computedLoading')
    icon(name='spinner')
    span.button-text {{ this.loadingText }}
  template(v-else-if='showSuccess')
    icon(name='check')
    span.button-text
      slot(name='success') {{ successText }}

  template(v-else)
    icon(v-if='icon' :name='icon')
    span.button-text
      slot(v-if='confirmClick && confirmFirstClickAt' name='confirm-click') {{ confirmClickText }}
      slot(v-else)
</template>

<script>
// NOTE: we can't call it "button" due to name collision with the native html button
import _ from 'lodash';

const components = {
  icon: require('./icon').default,
};

const BUTTON_SIZES = 'small medium large xlarge'.split(' ');
const BUTTON_THEMES = [
  'gray-blue dark light white white-with-border',
  'blue green red',
  'transparent-light transparent-dark transparent-blue transparent-red transparent-green',
].join(' ').split(' ');

const SHOW_SUCCESS_DELAY = 2000;

export default {
  components,
  props: {
    size: {
      type: String,
      validator: (val) => BUTTON_SIZES.includes(val),
    },
    theme: {
      type: String,
      validator: (val) => BUTTON_THEMES.includes(val),
      default: 'gray-blue',
    },
    href: String, // passes through to <a>
    to: [String, Object], // passes through to <router-link>
    toNamedRoute: String,
    targetBlank: Boolean,
    disabled: Boolean,
    requestStatus: Object,
    loading: Boolean,
    loadingText: {
      type: String,
      default: 'Loading...',
    },
    icon: String,
    inline: Boolean,
    confirmClick: Boolean,
    confirmClickText: { type: String, default: 'You sure? Click again to confirm' },
    successText: { type: String, default: 'Success!' },
  },
  data() {
    return {
      confirmFirstClickAt: null,
      showSuccess: false,
    };
  },
  computed: {
    tagType() {
      if (this.href) return 'a';
      if (this.to || this.toNamedRoute) return 'router-link';
      return 'button';
    },
    buttonType() {
      // otherwise <button> defaults to type=submit and makes forms submit
      return this.tagType === 'button' ? 'button' : undefined;
    },
    classes() {
      return {
        'is-disabled': this.disabled,
        'is-loading': this.computedLoading,
        ...this.size && { [`button--${this.size}`]: true },
        ...this.theme && { [this.theme]: true },
        ...this.inline && { 'button--inline-important': true },
      };
    },
    routerLinkTo() {
      return this.toNamedRoute ? { name: this.toNamedRoute } : this.to;
    },
    computedLoading() { return this.loading || _.get(this, 'requestStatus.isPending'); },
  },
  watch: {
    requestStatus() {
      if (this.requestStatus && this.requestStatus.isSuccess && this.successText) {
        this.showSuccess = true;
        setTimeout(() => { this.showSuccess = false; }, SHOW_SUCCESS_DELAY);
      }
    },
  },
  methods: {
    clickHandler() {
      if (this.confirmClick) {
        if (this.confirmFirstClickAt) {
          // check if the 2 clicks are super close together and ignore if they are
          // this is to help ignore some users who always double click everything
          if (new Date() - this.confirmFirstClickAt < 300) {
            return;
          }

          this.confirmFirstClickAt = null;
          clearTimeout(this.confirmClickTimeout);
          this.$emit('click');
        } else {
          this.confirmFirstClickAt = new Date();
          this.confirmClickTimeout = setTimeout(this.confirmClickTimeoutHandler, 3000);
        }
      } else {
        this.$emit('click');
      }
    },
    confirmClickTimeoutHandler() {
      this.confirmFirstClickAt = null;
    },
  },
};
</script>

<style lang='less'>

.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0,0,0,0);

  text-align: center;

  text-shadow: none;

  transition: all .25s;
  transition-property: color, background-color, border-color;

  font-family: inherit;
  text-transform: uppercase;
  font-weight: 700;
  user-select: none;
  margin-right: 3px;
  margin-bottom: 1px;
  white-space: nowrap;

  font-size: 14px;
  .text-crop();

  .button-text {
    display: inline-block;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .icon-content-wrap ~.button-text {
    padding-left: 10px;
  }

  .icon-content-wrap ~.button-text:empty {
    padding-left: 0px;
  }

  &:focus {
    outline: none;
  }

  &:hover {

  }

  // Size options
  padding: 17px 20px;
  &.button--small {
    padding: 10px 10px;
    .icon {
      width: 16px;
      height: 16px;
    }
  }
  &.button--large {
    // font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;
    // font-size: 16px;
  }
  &.button--xlarge {
    padding-left: 60px;
    padding-right: 60px;
    max-width: 100%;
    font-size: 16px;
  }

  &.full {
    width: 100%;
  }

  // does not work because pointer-events: none
  // &.is-disabled {
  //   cursor: not-allowed;
  // }
  // &.is-loading {
  //   cursor: wait;
  // }
  &.is-disabled, &.is-loading {
    opacity: .3;
    pointer-events: none;
  }
  &.is-loading {
    opacity: .5;
    // border-color: @dark-gray;
    color: @dark-gray;
    background: rgba(0,0,0,0);
  }

  // Color Theme options
  .create-theme(@color) {
    background-color: @color;
    color: contrast(@color);

    &:hover when (lightness(@color) > 50%) {
      background: darken(@color, 10%);
    }
    &:hover when (lightness(@color) < 50%) {
      background: lighten(@color, 10%);
    }
  }
  .create-transparent-theme(@color) {
    border-color: @color;
    color: @color;
    background: fade(@color, 0);
    &:hover {
      background: fade(@color, 10);
      // color: contrast(@color);
      border-color: @color;

    }
  }

  &.gray-blue { .create-theme(@brand-gray-blue); }

  &.blue { .create-theme(@cta-blue); }
  &.green { .create-theme(@green); }
  // &.green { .create-theme(#1aca8d); }
  &.red { .create-theme(@error-red-bg); }
  &.dark { .create-theme(@navy); }
  &.light { .create-theme(@gray-blue); }
  &.white { .create-theme(#FFFFFF); }
  &.white-with-border {
    .create-theme(#FFFFFF);
    border-color: @dark-gray;
  }
  &.header {
    background-color: #019BC9;
    color: white !important;
    &:hover {
      background-color: #06ABDC;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
      text-shadow: none;
      }
    &:active {
      background-color: #008AB3;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    }
  }

  &.transparent-dark { .create-transparent-theme(@dark-gray); }
  &.transparent-light { .create-transparent-theme(#FFFFFF); }
  &.transparent-blue { .create-transparent-theme(@cta-blue); }
  &.transparent-red { .create-transparent-theme(@error-red-bg); }
  &.transparent-green { .create-transparent-theme(@green); }
  &.transparent-gray-blue { .create-transparent-theme(@brand-gray-blue); }


  &.button--inline-important {
    width: auto !important;
  }

}

</style>
