<template lang='pug'>
.social-links-widget(:class='`theme--${theme}`')
  ul
    li(
      v-for='config, fieldName in SOCIAL_FIELDS_CONFIG'
      v-if='entity[fieldName]'
    )
      a.icon-wrap(:href='config.getUrl ? config.getUrl(entity[fieldName]) : entity[fieldName]' target='_blank')
        icon(:name='config.icon')
</template>

<script>

const components = {
};

const SOCIAL_FIELDS_CONFIG = {
  email: {
    icon: 'envelope', getUrl: (email) => `mailto://${email}`,
  },
  phone: {
    icon: 'phone', getUrl: (phone) => `tel://${phone}`,
  },
  websiteUrl: { icon: 'external-link-alt' },
  linkedinUrl: { icon: 'brands/linkedin-in' },
  facebookUrl: { icon: 'brands/facebook-f' },
  twitterUsername: {
    icon: 'brands/twitter', getUrl: (username) => `https://twitter.com/${username}`,
  },
  instagramUsername: {
    icon: 'brands/instagram', getUrl: (username) => `https://instagram.com/${username}`,
  },
};

export default {
  components,
  props: {
    user: { type: Object },
    company: { type: Object },
    theme: { type: String, default: 'dark' },
  },
  computed: {
    SOCIAL_FIELDS_CONFIG: () => SOCIAL_FIELDS_CONFIG,
    entity() { return this.user || this.company; },
  },
};
</script>

<style lang='less'>
.social-links-widget {
  ul {
    .unstyled-list();
  }

  li {
    line-height: 40px;
    display: inline-block;
    margin-right: 10px;

    .icon-wrap {
      width: 40px;
      height: 40px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
    }

    .icon {
      width: 100%;
      height: 100%;
      padding: 9px;
    }

    .label {
      display: none;
    }
    a {
      transition: none;
    }
  }

  &.theme--dark {
    li .icon-wrap {
      background: @navy;
      color: white;
    }
    li:hover .icon-wrap {
      background: @brand-gray-blue;
      color: #FFF;
    }
  }
  &.theme--light {
    li .icon-wrap {
      background: @brand-blue;
      color: @navy;
    }
    li:hover .icon-wrap {
      background: @brand-gray-blue;
      color: #FFF;
    }
  }
  &.theme--gray-blue {
    li .icon-wrap {
      background: @brand-gray-blue;
      color: white;
    }
    li:hover .icon-wrap {
      background: #000;

    }
  }
}

</style>




