<template lang='pug'>
footer.site-footer
  .aardor
    p &copy; Agency360SF, DBA AdvisorEY | All Rights Reserved
    ul.unstyled
      li: router-link(:to='{ name: "terms-of-service" }') Terms of service
      li: router-link(:to='{ name: "privacy-policy" }') Privacy policy
</template>

<script>
import { mapGetters } from 'vuex';

const components = {};

export default {
  components,
  props: {},
  computed: {
    ...mapGetters('auth', ['userIsLoggedIn']),
    ...mapGetters('authUser/profile', ['bestName']),
    supportEmail() {
      return this.userIsLoggedIn
        ? 'support@advisorey.com'
        : 'info@advisorey.com';
    },
  },
  methods: {},
};
</script>

<style lang='less'>
.site-footer {
  background: @navy;
  color: white;

  @media @mq-small-only {
    padding: 20px 10px 0;
    // avoided flexbox here because of some weird issues
  }

  @media @mq-medium {
    // padding: 60px 15px 0;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 4fr 3fr 5fr;
    grid-gap: 1rem;
  }

  li > a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: @brand-gray-blue;
      transition: 0.2s all;
    }
  }

  h3 {
    margin-top: 0;
    font-size: 20px;
    line-height: 1.4em;
    margin-bottom: 1rem;
    color: currentColor;
  }

  .footer-social-block {
    grid-column: 1;
    grid-row: 2;

    @media @mq-small-only {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .footer-contact-block {
    grid-column: 1;
    grid-row: 1;

    a {
      display: inline-block;
      margin-bottom: 8px;
    }

    @media @mq-small-only {
      float: left;
      padding-right: 30px;
      min-width: 50%;
    }
  }

  .footer-blurb-block,
  .footer-account-block {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    p {
      margin-top: 0;
    }

    @media @mq-small-only {
      padding-bottom: 20px;
      .button {
        width: 100%;
      }
    }
  }

  .footer-nav {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;

    @media @mq-small-only {
      grid-column: 3;
      grid-row-start: 1;
      grid-row-end: 4;

      float: left;
      padding-left: 15px;
      padding-bottom: 20px;
    }
    ul > li > ul {
      padding-left: 0px;
      li {
        &:before {
          content: '>';
          margin-right: 8px;
        }
      }
    }
  }

  .legal {
    grid-column: span 3;
    grid-row: 3;
    // border-top: 1px solid fade(#fff, 30);
    display: flex;
    line-height: 1.4em;
    padding: 2em 0 1em;

    @media @mq-small-only {
      font-size: 10px;
      margin-top: 20px;
      clear: both;
    }

    @media @mq-medium {
      font-size: 11px;
    }

    li {
      display: inline-block;
    }
    a {
      padding-left: 1rem;
    }

    .copyright {
      margin-right: auto;
    }
  }

  .aardor {
    margin: 12px 0;
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 4;
    font-size: 12px;
    text-align: center;
  }

  p.small {
    font-size: 11px;
  }

  // Social links
  .social-links {
    .unstyled-list();
  }

  .social-link {
    line-height: 36px;
    display: inline-block;
    margin-right: 0px;

    .icon-wrap {
      width: 50px;
      height: 50px;
      padding: 5px;
      border-radius: 35px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      color: darken(@navy, 5%);
      background: fade(@brand-blue, 10%);
      color: #fff;
    }

    .icon {
      width: 100%;
      height: 100%;
      padding: 9px;
    }

    .label {
      display: none;
    }

    &:hover {
      .icon-wrap {
        // z-index: 1;
        position: relative;
        background: @brand-blue;
        color: #000;
      }
    }
  }
}
</style>
