<template lang='pug'>
.save-bar
  .spacer
  .save-bar-content(:class='classObject')
    .status(v-if='updateRequest.isPending')
      icon(name='spinner')
      | Saving your changes...
    .status(v-else-if='saveSuccessWasRecent')
      icon(name='check')
      | Save success!
    template(v-else)
      .status(v-if='updateRequest.isError')
        icon(name='exclamation-circle')
        | {{this.updateRequest.error.message}}

      .buttons(v-if='hasUnsavedChanges')
        v-button(@click='$emit("reset")' theme='transparent-light') Undo
        v-button(@click='saveButtonHandler' :disabled='saveDisabled' theme='gray-blue') Save Changes
</template>

<script>
import _ from 'lodash';

const components = {};

const SHOW_SAVE_DELAY = 2000;

export default {
  components,
  props: {
    draftValue: { type: [Object, Array] },
    storeValue: { type: [Object, Array] },
    updateRequest: Object,
    saveDisabled: Boolean,
  },
  data() {
    return {
      now: Date.now(),
    };
  },
  computed: {
    hasUnsavedChanges() {
      return _.keys(this.changesToSave).length > 0;
    },
    changesToSave() {
      // const simpleHasChanges = !_.isEqual(this.storeValue, this.draftValue);

      if (this.draftValue === undefined || this.storeValue === undefined) return {};

      // pick anything in our draft object that differs from the original
      // we only care about the keys present in the draft because we use PATCH requests and the
      // absence of keys will not delete anything
      return _.pickBy(this.draftValue, (draftVal, key) => {
        let dv;
        let sv;

        switch (typeof draftVal) {
          case 'boolean':
            return draftVal !== this.storeValue[key];
          default:
            // we filter out keys pointing to null values here
            // because our dropdowns will set values to `null` if on the empty/placeholder value
            // ex: `{ address: {} }` and `{ address: { country: null }}` are considered equal
            // NOTE - this only works one level deep
            dv = JSON.stringify(_.pickBy(draftVal, (val) => val !== null));
            sv = JSON.stringify(
              _.pickBy(this.storeValue[key], (val) => val !== null),
            );
            // if (dv !== sv) console.log(`${key} -- ${dv} ?? ${sv}`);
            return dv !== sv;
        }
      });
    },
    classObject() {
      return {
        'is-visible': this.hasUnsavedChanges || this.saveSuccessWasRecent,
        'is-error': this.updateRequest.isError,
        'is-success': this.saveSuccessWasRecent,
      };
    },
    statusMessage() {
      if (this.updateRequest.isPending) return 'Saving changes...';
      if (this.updateRequest.isSuccess) return 'Save success!';
      if (this.hasUnsavedChanges) return 'Ready to save?';
      return 'Everything in sync.';
    },
    saveSuccessWasRecent() {
      if (this.updateRequest.isSuccess) {
        const timeSinceSave = this.now - this.updateRequest.receivedAt;
        return timeSinceSave < SHOW_SAVE_DELAY;
      }
      return false;
    },
  },
  methods: {
    warnAboutUnsavedChanges() {
      if (this.updateRequest.isPending) {
        return false;
      } else if (this.hasUnsavedChanges) {
        const warningMessage = 'Are you sure you want to leave? You will lose your unsaved changes.';
        return window.confirm(warningMessage); // eslint-disable-line no-alert
      }
      return true;
    },
    saveButtonHandler() {
      this.$emit('save', {
        // add back the ID so the
        id: this.draftValue.id,
        ...this.changesToSave,
      });
    },
  },
  created() {
    this.timerInterval = setInterval(() => {
      this.now = Date.now();
    }, 250);
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
};
</script>

<style lang='less'>
@save-bar-height: 140px;

.save-bar {
  
  > .spacer {
    height: @save-bar-height;
  }

  > .save-bar-content {
    width: 100%;
    height: @save-bar-height;
    position: fixed;
    z-index: 1000;
    left: 0;
    background: @save-bar-color;
    color: white;
    bottom: -@save-bar-height;
    transition: 0.5s all;

    display: flex;
    justify-content: center;
    align-items: center;

    .status {
      font-weight: 700;
      padding-right: 20px;
    }

    &.is-visible {
      bottom: 0;
    }
    &.is-error {
      background: @error-red-bg;
    }
    &.is-success {
      background: @green;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin: 0 10px;
  }

  .button {
    margin: 0 10px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
</style>
