<template lang='pug'>
.two-columns-view
  .left-column(:class='showRight ? "right-shown" : "full-left"')
    slot(name='left-content')
  .right-column(v-if='showRight' :class='showRight ? "right-shown" : "full-left"')
    .right-column-header(v-if='showRightHeader' :class='fixedHeader ? "fixed-header" : "not-fixed-header"')
      .right-back-button(v-if='showBackButton')
        v-button(icon='angle-left' @click='clickHandler')
      .right-back-header-content
        slot(name='right-header')
    .right-column-content
      slot(name='right-content')
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

const components = {};

export default {
  components,
  mixins: [],
  props: {
    showRight: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showRightHeader: {
      type: Boolean,
      default: true,
    },
    fixedHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  methods: {
    clickHandler() {
      this.$emit('back');
    },
  },
  beforeMount() {},
};
</script>

<style lang='less'>
@left-column-width: 320px;
@left-column-border-width: 1px;
@two-columns-offset-from-top: 60px;
@right-column-header-height: 60px;

.two-columns-view {
  .left-column {
    width: @left-column-width + @left-column-border-width;
    overflow: auto;
    
    position: fixed;
    bottom: 0;
    top: @two-columns-offset-from-top;
    border-right: @left-column-border-width solid rgba(0,0,0,0.05);

    @media @mq-small-only {
      width: 100vw;

      &.right-shown {
        display: none;
      }
    }
  }

  .right-column {
    padding-left: @left-column-width;

    @media @mq-small-only {
      width: 100vw;
      padding-left: 0;
    }

    .right-column-header {
      background: @secondary-nav-bar-color;
      color: white;
      height: @two-columns-offset-from-top;

      z-index: 100;
      display: flex;

      &.fixed-header {
        position: fixed;
        top: 60px;
        // width: 100%;
        right: 0;
        left: @left-column-width;

        @media @mq-small-only {
          left: 0;
        }
      }

      .right-back-button {
        flex: 0 0 auto;

        .button {
          margin: 0;
          padding: 0;
          height: @right-column-header-height;
          background: transparent;

          .icon {
            width: 60px;
            height: @right-column-header-height;
            padding: 15px;
          }
        }
      }

      .right-back-header-content {
        flex: 1 0 auto;
      }
    }

    .right-column-content {
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-top: @right-column-header-height;
    }
  }
}
</style>
