<template lang='pug'>
.page-executive-progress-overview
  template(v-if='user.isExecutive')
    template
      template(v-if='getQuestionnairesRequest.isPending')
        h3 Loading questionnaires...
      template(v-else-if='getQuestionnairesRequest.isError')
        error-message(:request-status='getQuestionnairesRequest')
      template(v-else-if='getQuestionnairesRequest.isSuccess')
        //-- Questionnaire Required
        template(v-if='user.questionnaireRequired')
          notification-square(v-if='incompleteQuestionnaire' message='You have an incomplete questionnaire.')
            v-button(:to='{ name: "executive-progress-questionnaire", params: { questionnaireId: incompleteQuestionnaire.id } }' theme='white' size='large') Complete Progress Questionnaire
          notification-square(v-else message='You need to complete a new Progress Questionnaire.')
            v-button(@click='startNewQuestionnaireButtonHandler' theme='white' size='large') Start Progress Questionnaire
            error-message(:request-status='createQuestionnaireRequest')
        //-- Results
        template(v-if='latestCompletedQuestionnaire')
          h3 Most Recent Questionnaire
          p.small You and your coach can use this information to determine what you want to focus on next.
          assessment-results(:assessment='latestCompletedQuestionnaire')
          template(v-if='latestCompletedQuestionnaire.selection')
            p You chose the following topics to work on:
            questionnaire-selection(:questionnaire='latestCompletedQuestionnaire')
        //--- Selection Required
        notification-square(v-if="!user.questionnaireRequired && user.questionnaireSelectionRequired" message='Please choose your areas of focus.')
          v-button(to-named-route='executive-progress-latest-questionnaire' theme='white' size='large') Choose Your Areas of Focus
      
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'assessment-results': require('@/components/other/assessment-results').default,
  'questionnaire-selection': require('@/components/other/questionnaire-selection').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('authUser/profile', {
      user: 'user',
    }),
    ...mapGetters('authUser/questionnaire', {
      latestCompletedQuestionnaire: 'latestCompletedQuestionnaire',
      previousCompletedQuestionnaire: 'previousCompletedQuestionnaire',
      incompleteQuestionnaire: 'incompleteQuestionnaire',
      newQuestionnaireId: 'newQuestionnaireId',
    }),
    ...mapRequestStatuses({
      getQuestionnairesRequest: 'authUser/questionnaire/LOAD_QUESTIONNAIRES',
      createQuestionnaireRequest: 'authUser/questionnaire/CREATE_QUESTIONNAIRE',
    }),
  },
  watch: {
  },
  methods: {
    async startNewQuestionnaireButtonHandler() {
      if (this.createQuestionnaireRequest.isProgress) {
        return;
      }

      await this.$store.dispatch('authUser/questionnaire/createQuestionnaire');
      if (this.createQuestionnaireRequest.isSuccess) {
        this.$router.push({
          name: 'executive-progress-questionnaire',
          params: { questionnaireId: this.newQuestionnaireId },
        });
      }
    },
  },
};
</script>

<style lang='less'>
.page-executive-progress-overview {

}
</style>
