<template lang='pug'>
div
  h2 Connecting your accounts...
  oauth-connect(:callback-payload='this.$route.query')
</template>

<script>
const components = {
  'oauth-connect': require('@/components/other/oauth-connect').default,
};

export default {
  components,
  metaInfo: {
    title: 'Connecting your accounts...',
    meta: [
      { name: 'robots', content: 'noindex' },
    ],
  },
};
</script>

<style lang='less'>


</style>
