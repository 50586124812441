<template lang='pug'>
todo-section.session-detail(
  :title='title'
  :disabled='status === "past" || !!session.cancelledAt'
  :class='computedClasses'
  :start-open='["now", "soon"].includes(status)'
)
  template(slot='status-icon')
    .calendar-date-icon
      .month
        | {{ session.startAtUtc | date('MMM') }}
        .rings.ring-left
        .rings.ring-right
      .date-of-month
        span.num {{ session.startAtUtc | date('d') }}
        span.th {{ session.startAtUtc | date('d') | th }}
      .day-of-week {{ session.startAtUtc | date('iiii') }}

  div(slot='details')
    | {{ session.startAtUtc | time }} - {{ session.duration }} min
    .session-canceled(v-if='session.cancelledAt') Cancelled
    .session-medium(v-else)
      span(v-if='session.medium === "phone"') Phone call
      span(v-if='session.medium === "video"') Video call
      span(v-if='session.medium === "in_person"') In-person

  form-row(no-inputs v-if='status === "now"')
    .remaining-time This session ends in <b>{{ minutesRemaining }} min</b>
    .timer-bar
      .timer-bar-inner(:style='{width: `${percentComplete}%`}')
  form-row.timer-notice(no-inputs v-if='status === "soon"')
    icon(name='exclamation-triangle')
    |  This session begins in
    span.bold  {{ Math.floor(secondsUntilStart / 60) }}m {{ (secondsUntilStart % 60).toString().padStart(2, '0') }}s
    //- span(v-if='session.medium !== "in_person"')  - the zoom meeting will begin when 10 minutes remain


  form-row(v-if='session.type === "interview"')
    form-input(type='container' label='Objective')
      | This call is just a short introduction meant to help determine if you are a good match.

  form-row(v-if='session.medium === "in_person"')
    form-input(type='container' label='Location Name') {{ session.locationDetails.name }}
    form-input(type='container' label='Address') {{ session.locationDetails.addressString }}
    form-input(type='container' label='Notes') {{ session.locationDetails.notes }}
  template(v-else)
    .session-topic-details
      form-row(v-if="session.topicsSelection")
        form-input(type='container' label='I want to work on...')
          questionnaire-selection(:selection='session.topicsSelection')
      form-row(v-if="session.executiveRequest")
        form-input(type='container' label='Request for this session')
          p {{ session.executiveRequest }}
    form-row()
      form-input(type='container' label='Zoom Link')
        a(:href='session.joinUrl' target='_blank') {{ session.joinUrl }}
        .zoom-password(v-if='session.password')
          .password-title Password
          span {{ session.password }}
      form-input(type='container' label='Dial In Number')
        a(:href='`tel:${session.dialIn.number},,${session.dialIn.code}##`' target='_blank')
          | {{ session.dialIn.number }}
      form-input(type='container' label='Zoom Meeting ID') {{ session.zoomMeetingId }}
    form-row
      form-input(type='container' no-label)
        v-button(
          :icon='session.medium === "video" ? "video" : "phone"' theme='blue'
          :href='session.joinUrl' target='_blank'
        ) Join using Zoom app
      form-input(
        type='container' no-label
        :instructions='session.medium === "video" ? "*Not Recommended - use for backup only" : ""'
      )
        v-button(
          icon='phone' :theme='session.medium === "video" ? "transparent-dark" : "blue"'
          :href='`tel:${session.dialIn.number},,${session.dialIn.code}##`' target='_blank'
        ) Dial in {{ session.medium === "video" ? "- audio only*" : "" }}

  template(v-if='["future", "soon"].includes(status)')
    //-- TODO: Add notes/messages.
    form-row
      v-button(
        icon='calendar-times' theme='transparent-red'
        @click='cancelSessionButtonHandler(session.id)' confirm-click
        :request-status='getCancelSessionRequest(session.id)'
      ) Cancel this {{ session.type === "coaching" ? "session" : "interview" }}


</template>

<script>
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';

const components = {
  'timer-icon': require('!vue-svg-loader!@/assets/images/large-icons/1400-web-seo/noun_time management_986317.svg')
    .default,
  'questionnaire-selection': require('@/components/other/questionnaire-selection')
    .default,
};

export default {
  components,
  props: {
    session: Object,
    viewMode: String,
  },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    moduleName() {
      return this.viewMode === 'self'
        ? 'authUser/booking'
        : 'selectedUser/booking';
    },
    computedClasses() {
      return {
        [`session-detail--${this.status}`]: true,
        'session-detail--cancelled': this.session.cancelledAt,
      };
    },
    startDate() {
      return dateFns.parseISO(this.session.startAtUtc);
    },
    endDate() {
      return dateFns.addMinutes(this.startDate, this.session.duration);
    },
    status() {
      if (this.startDate > this.now) {
        if (this.now > dateFns.subMinutes(this.startDate, 60)) return 'soon';
        return 'future';
      }
      if (this.endDate > this.now) return 'now';
      return 'past';
    },
    percentComplete() {
      if (this.status === 'past') return 100.0;
      if (this.status !== 'now') return 0.0;
      return (
        Math.round(
          (1000 * dateFns.differenceInSeconds(this.now, this.startDate))
            / (this.session.duration * 60),
        ) / 10
      );
    },
    minutesRemaining() {
      if (this.status === 'past') return 0;
      if (this.status !== 'now') return this.session.duration;
      return dateFns.differenceInMinutes(this.endDate, this.now);
    },
    secondsUntilStart() {
      if (this.status !== 'soon') return null;
      return dateFns.differenceInSeconds(this.startDate, this.now);
    },
    title() {
      if (this.session.execUser) {
        return `${this.session.execUser.firstName} ${this.session.execUser.lastName} - ${this.session.type}`;
      } else if (this.session.coachUser) {
        return `${this.session.coachUser.firstName} ${this.session.coachUser.lastName} - ${this.session.type}`;
      }
      return 'session';
    },
  },
  methods: {
    getCancelSessionRequest() {
      return this.$store.getters.requestStatus(
        `${this.moduleName}/CANCEL_SESSION`,
        this.session.id,
      );
    },
    async cancelSessionButtonHandler() {
      await this.$store.dispatchApiAction(`${this.moduleName}/CANCEL_SESSION`, {
        sessionId: this.session.id,
      });
    },
    updateTimer() {
      this.now = new Date();
    },
  },
  mounted() {
    if (this.status !== 'past') this.timerInterval = setInterval(this.updateTimer, 1000);
  },
  beforeDestroy() {
    if (this.timerInterval) clearInterval(this.timerInterval);
  },
};
</script>

<style lang='less'>
.session-detail {
  .calendar-date-icon {
    margin-right: 5px;
  }

  .session-medium,
  .session-canceled {
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
  }

  .session-canceled {
    color: @error-red-bg;
  }

  &.session-detail--past {
    .calendar-date-icon {
      opacity: 0.6;
    }
    background: #ddd;
  }
  &.session-detail--now {
  }
  &.session-detail--cancelled {
    background: #ddd;
  }

  .zoom-password {
    .password-title {
      margin-top: 4px;
      font-weight: bold;
      display: block;
    }
  }

  .timer-bar {
    height: 15px;
    width: 100%;
    background: #555;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #555;
  }
  .timer-bar-inner {
    background: @brand-gray-blue;
    height: inherit;
    width: 55%;
    border-bottom-right-radius: none;
    border-top-right-radius: none;
  }
  .timer-notice {
    display: flex;
    .counter {
      display: inline-block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;

      // background: red;
      // background: @brand-blue;
      border-radius: 100%;
      border: 1px solid @brand-purple;
      // font-family:'Courier New', Courier, monospace;
      // float: right;
      font-size: 16px;
      font-weight: 700;
      justify-self: flex-end;
    }
  }
  .todo-section__header {
  }

  .session-topic-details {
    .questionnaire-selection {
      margin: auto;
      text-align: center;

      .selection {
        font-size: 1.2em;
      }
    }
  }
}

.calendar-date-icon {
  background: white;
  border-radius: 4px;
  color: #333;
  text-align: center;
  width: 60px;
  position: relative;
  margin-top: -14px;
  border: 1px solid fade(@brand-gray-blue, 50%);
  border-top: none;
  // box-shadow: 0px 2px 10px rgba(0,0,0,.2);

  .month {
    font-size: 10px;
    line-height: 1em;
    padding: 2px 0 2px;
    text-transform: uppercase;
    background: @brand-gray-blue;
    // border-bottom: 2px solid darken(@brand-gray-blue, 10%);
    border-top: 1px solid lighten(@brand-gray-blue, 8%);
    font-weight: 700;
    // color: white;
    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
  }
  .rings {
    display: none;
    position: absolute;
    width: 3px;
    height: 12px;
    background: #dadada;
    background: linear-gradient(60deg, #888 0%, #fff 100%);

    top: -4px;
    border-radius: 2px;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.15);

    &:after {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      z-index: -1;
      bottom: -2px;
      left: -2px;
    }
    &.ring-left {
      left: 7px;
    }
    &.ring-right {
      right: 7px;
    }
  }
  .date-of-month {
    font-weight: 700;
    font-size: 24px;
    line-height: 1em;
    padding: 4px 0px 1px;

    .num {
      display: inline-block;
      vertical-align: top;
    }
    .th {
      display: inline-block;
      vertical-align: top;
      font-size: 10px;
      line-height: 10px;
      font-weight: normal;
      padding-left: 1px;
      padding-top: 0px;
      display: none;
    }
  }
  .day-of-week {
    font-size: 10px;
    line-height: 1em;
    padding-bottom: 2px;
    text-transform: lowercase;
    color: #aaa;
  }
}
</style>
