<template lang='pug'>
main-layout#page-client-dashboard(
  full-width
  no-footer
)
  two-columns(:showRight='!!selectedClientUserId' @back='backHandler')
    template(v-slot:left-content)
      .client-list
        //- form-row
        //-   form-input(v-model='clientsFilter' type='dropdown' label='Filter')
        //-     form-input-option(:value='null') Everyone
        //-     form-input-option(value='current') Current Clients
        //-     form-input-option(value='potential') Potential Clients
        //-     //- form-input-option(value='current') Past Clients
        //-   form-input(v-model='clientsSort' type='dropdown' label='Sort')
        //-     form-input-option(value='current') Next Appointment
        //-     form-input-option(value='potential') Potential Clients
        //-     //- form-input-option(value='current') Past Clients
        .clients-list-title
          div Executives
          .clients-list-search-input-wrap
            icon(name='search')
            input.clients-list-search-input(type='text' v-model='clientsListSearchQuery')

        .client-list-scroll
          template(v-if='getClientsListRequest.isPending')
            p loading...
          template(v-else)
            .client-list-empty(v-if='!filteredClients.length') No executives
            template(v-else)
              client-list-item(
                v-for='execUser in filteredClients'
                :key='`client-list-${execUser.id}`'
                :user='execUser'
              )
    template(v-slot:right-header)
      .client-dash-header-bar
        template(v-if='getSelectedClientRequest.isPending || !user')
          .client-name-and-title-wrap
            .client-name Loading...
        template(v-else)
          .client-name-and-title-wrap
            .client-name {{ user.fullName }}
            .client-title {{ user.jobTitle }} @ {{ company.name }}
    template(v-slot:right-content)
      .client-details-wrap
        div(v-if='getSelectedClientRequest.isSuccess')
          .client-details-content
            router-view
        div(v-else-if='getSelectedClientRequest.isError')
          error-message(:request-status='getSelectedClientRequest')

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'social-links-widget': require('@/app-public/common/other/social-links-widget')
    .default,
  'profile-photo': require('@/components/other/profile-photo').default,
  'executive-profile': require('./executive-profile/index').default,
  'client-list-item': require('./client-list-item').default,
  'session-details': require('@/app-public/common/other/session-details')
    .default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    selectedClientUserId: Number,
  },
  data() {
    return {
      clientsFilter: null,
      showChat: true,
      clientsListSearchQuery: '',
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      coach: 'user',
      currentCoachId: 'userId',
    }),
    ...mapGetters('authUser/coaching', [
      'clientsList',
      'upcomingSessions',
      'selectedClientUpcomingSessions',
    ]),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
    ...mapRequestStatuses({
      getClientsListRequest: 'authUser/coaching/GET_COACH_CLIENTS_LIST',
      getSessionsRequest: 'authUser/coaching/GET_COACH_SESSIONS',
      getSelectedClientSessionsRequest:
        'authUser/coaching/GET_SELECTED_CLIENT_SESSIONS',
      getSelectedClientRequest: 'selectedUser/profile/GET_USER',
    }),
    coachingMatch() {
      const { coachingMatch = {} } = this.user;
      return coachingMatch;
    },
    filteredClients() {
      const q = this.clientsListSearchQuery.toLowerCase();
      if (!q) return this.clientsList;
      return _.filter(this.clientsList, (client) => `
        ${client.firstName} ${client.lastName} ${client.email}
        ${client.company.name}
      `
        .toLowerCase()
        .includes(q));
    },
  },
  watch: {
    selectedClientUserId() {
      this.$store.dispatch(
        'selectedUser/profile/setUserId',
        this.selectedClientUserId,
      );
    },
  },
  methods: {
    toggleChatVisiblity() {
      this.showChat = !this.showChat;
    },
    backHandler() {
      if (this.selectedClientUserId) {
        this.$router.push({ name: 'coach-dashboard' });
      }
    },
  },
  async beforeMount() {
    let redirect;

    // redirects
    if (!this.coach.isCoach) {
      redirect = 'welcome';
    } else if (!this.coach.isActivatedCoach) {
      redirect = 'complete-profile';
    }

    if (redirect) {
      this.$router.replace({ name: redirect });
    } else {
      this.$store.dispatchApiAction('authUser/coaching/GET_COACH_CLIENTS_LIST');
      this.$store.dispatchApiAction('authUser/coaching/GET_COACH_SESSIONS');
      if (this.selectedClientUserId) {
        this.$store.dispatch(
          'selectedUser/profile/setUserId',
          this.selectedClientUserId,
        );
      }
    }
  },
};
</script>

<style lang='less'>
#page-client-dashboard {
  .main-content {
    height: 100vh;
  }

  .client-list {
    background: white;
    flex-direction: column;
    top: @header-bar-height--desktop;

    .clients-list-title {
      font-size: 11px;
      line-height: 14px;
      text-transform: uppercase;
      background: @secondary-nav-bar-color;
      padding: 5px 10px;
      color: white;
      flex: 60px 0 0;
      height: 60px;
    }

    .clients-list-search-input-wrap {
      .icon {
        position: absolute;
        color: @navy;
        height: 30px;
        width: 30px;
        padding: 5px;
      }
    }

    .clients-list-search-input {
      height: 30px;
      padding-left: 34px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 20px;
      margin-top: 3px;
      outline: none;
      background: rgba(255, 255, 255, 0.3);
      color: white;
      line-height: 30px;
      font-size: 14px;
    }

    .client-list-scroll {
      overflow: auto;
    }
  }

  .client-dash-header-bar {
    .client-name-and-title-wrap {
      padding: 5px 10px;
    }
    .client-name {
      font-weight: 700;
    }
    .tools {
      flex: 1 0 0;
      text-align: right;
      .chat-toggle {
        // margin-right: 300px - 60;
      }
    }

    a {
      display: inline-block;
      height: 100%;
      width: 60px;
      color: white;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .icon {
      width: 60px;
      height: 60px;
      padding: 15px;
    }
  }

  .client-details-wrap {
    padding: 0 20px;
  }

  .potential-match-info {
    background: white;
    border: 1px solid @navy;
    color: @navy;
    padding: 5px;
    // padding-left: 50px;
    border-radius: 10px;
    // color: white;
    position: relative;
    margin: 20px 0;

    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }

  .match-percent {
    position: absolute;
    left: -10px;
    top: -10px;
    font-weight: 700;
    font-size: 18px;
    background: @navy;
    color: white;
    display: inline-block;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
  }
}
</style>
