import _ from 'lodash';
import Vue from 'vue';
import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  namespaced: true,
  state() {
    return {
      evaluation: null,
      evaluationResponse: null,
      userEvaluations: null,
    };
  },
  getters: {
    userId: (s, g, rs, rg) => rg[`${s._moduleParent}profile/userId`],

    evaluation: (state) => state.evaluation,
    evaluationResponse: (state) => state.evaluationResponse,
    userEvaluations: (state) => state.userEvaluations,
    openEvaluation: (state) => _.find(state.userEvaluations, { completedAt: null }),
  },
  ...buildApiActions({
    GET_EVALUATIONS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/evaluations`,
      }),
      mutation: (state, { response }) => {
        state.userEvaluations = response || [];
      },
    },
    CREATE_EVALUATION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/evaluations`,
        params: _.omit(payload, 'id'),
        afterSuccess: (response) => {
          ctx.dispatch('api-GET_EVALUATIONS');
        },
      }),
    },
    // get a single evaluationResponse (e.g. to fill out)
    GET_EVAL_RESPONSE: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/evaluation-responses/${payload.token}`,
      }),
      mutation: (state, { response }) => {
        response.evaluation.evaluationQuestions = _.reject(
          response.evaluation.evaluationQuestions,
          (q) => (q.hideFrom || []).includes(response.evaluationResponse.respondentRole),
        );
        state.evaluationResponse = response.evaluationResponse;
        state.evaluation = response.evaluation;
      },
    },
    SAVE_EVAL_ANSWERS: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/evaluation-responses/${payload.token}/answers`,
        params: _.omit(payload, 'token'),
      }),
      mutation: (state, { response }) => {
        state.evaluationResponse = response;
      },
    },
    // get a group of evaluationResponses (e.g. view completed responses)
    GET_EVAL_RESULTS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/evaluations/${payload.evaluationId}`,
      }),
      mutation: (state, { response }) => {
        state.evaluation = response;
      },
    },
    SEND_EVAL_REMINDER: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/evaluation-responses/${payload.evaluationResponse.id}/send-reminder`,
        params: payload,
        keyRequestStatusBy: payload.evaluationResponse.id,
      }),
      mutation: (state, { response }) => {
        const openEvaluation = _.find(state.userEvaluations, { id: response.evaluationId });
        const responseToUpdateAsReminded = _.find(openEvaluation.evaluationResponses, { id: response.id });
        responseToUpdateAsReminded.lastRemindedAt = response.lastRemindedAt;
      },
    },
    REMOVE_RESPONDENT: {
      action: (ctx, payload) => ({
        method: 'delete',
        url: `/evaluation-responses/${payload.evaluationResponse.id}`,
        afterSuccess: (response) => {
          if (payload.removeFromUsersRespondentsList) {
            ctx.dispatch(`${ctx.state._moduleParent}profile/removeEvaluationRespondentByEmail`,
              payload.evaluationResponse.respondentEmail,
              { root: true });
          }
          // since the eval may now be marked as completed if this was the lone remaining one
          ctx.dispatch('api-GET_EVALUATIONS');
        },
      }),
    },
    UNLOCK_EVALUATION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/evaluations/${payload.evaluationId}/unlock`,
      }),
      mutation: (state, { response }) => {
        state.evaluation = response;
      },
    },
  }),
};
