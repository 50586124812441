<template lang='pug'>
.assessment-results
  template(v-if="computedTraits")
    .trait(v-for='scores, trait in computedTraits' :key='`assessment-result-${trait}`')
      .trait-wrap
        .trait__label {{ trait }}
        .trait__result
          .trait__bar
            .trait__bar-fill.a(:class='(scores.improvement) ? "now" : "previous"' :style='{width: `${scores.a*100}%`}')
            .trait__bar-fill.b(:class='(scores.improvement) ? "previous" : "now"' :style='{width: `${scores.b*100}%`}')
          template(v-if="scores.b")
            span.trait__score {{ Math.round(scores.now * 100) }} ({{ ((scores.difference > 0) ? '+' : '') + Math.round(scores.difference * 100) }})%
          template(v-else)
            span.trait__score {{ Math.round(scores.now * 100) }}%
  template(v-else)
    .no-trait Assessment Info is Unavailable
</template>

<script>
import _ from 'lodash';

const components = {};

export default {
  components,
  props: {
    /**
     *
     */
    assessment: { type: Object },
    /**
     *
     */
    traits: { type: Object },
    /**
     * Second assessment to lay over the other one.
     */
    assessmentCompare: {
      type: Object,
      required: false,
    },
  },
  computed: {
    computedStyles() {
      return {};
    },
    computedTraits() {
      const traitsInput = this.traits;
      const { results } = this.assessment || {};

      let computedTraits = null;
      let secondComputedTraits = {};

      if (traitsInput) {
        computedTraits = traitsInput;
      } else if (results) {
        computedTraits = results;

        const { secondResults } = this.assessmentCompare || {};
        if (secondResults) {
          secondComputedTraits = secondResults;
        }
      }

      let finalTraits;

      if (computedTraits) {
        finalTraits = {};

        _.forOwn(computedTraits, (value, key) => {
          const now = value;
          const previous = secondComputedTraits[key] || 0;

          const improvement = now > previous;
          const difference = now - previous;

          finalTraits[key] = {
            now,
            previous,
            a: improvement ? now : previous,
            b: improvement ? previous : now,
            improvement,
            difference,
          };
        });
      }

      return finalTraits;
    },
  },
};
</script>

<style lang='less'>
@trait-width: 340px;

.assessment-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(@trait-width, 1fr));
  grid-template-rows: 60px auto;

  padding: 20px 0;

  .trait {
    width: @trait-width;
    margin-right: auto;
  }

  .trait-wrap {
    min-height: 60px;
  }

  .trait__label {
    font-weight: 700;
    padding: 0;
  }

  .trait__result {
    line-height: 16px;
  }

  .trait__bar {
    background: #fff;
    height: 20px;
    width: 250px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    margin-right: 10px;
    display: inline-block;
    line-height: inherit;
    vertical-align: middle;
    overflow: hidden;

    .trait__bar-fill {
      height: 18px;
      border-radius: inherit;

      @now-color: @brand-gray-blue;
      @previous-color: fade(darken(@now-color, 30%), 40%);

      &.now {
        background: @now-color;
      }
      &.previous {
        background: @previous-color;
      }

      &.b {
        margin-top: -18px;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }

  .trait__score {
    display: inline-block;
    line-height: inherit;
    vertical-align: middle;
  }
}
</style>