<template lang='pug'>
.coach-info-view(v-if='coach && getEnumsRequest.isSuccess')
  .coach-info-header
    .coach-info-header-flex
      profile-photo(
        :user='coach' size='large'
        :placard-text='placardText'
      )
      .coach-info
        .coach-name {{ coach.fullName }}
        .coach-tagline {{ coach.tagline }}
        .coach-specialties(v-if='coach.coachSettings')
          .industries.tag-list
            ul
              li(v-for='val in coach.coachSettings.industries')
                icon(name='briefcase')
                | {{ industryOptions[val] }}
          .skills.tag-list
            ul
              li(v-for='val in coach.coachSettings.skills')
                icon(name='graduation-cap')
                | {{ skillOptions[val] }}
          .company-sizes.tag-list
            ul
              li(v-for='val in coach.coachSettings.companySizes')
                icon(name='users')
                | {{ companySizeOptions[val].replace(' employees', '') }}
    .coach-actions(v-if='!noActions')
      slot(name='actions')
  .coach-sessions
    slot(name='sessions')
  .coach-info-view-content
    .more-profile-info
      form-row
        form-input(type='container' label='Web + Social Profiles' )
          social-links-widget(:user='coach' theme='dark')
      form-row(v-if='coach.bio')
        form-input(type='container' label='Bio')
          markdown(:source='coach.bio')
      form-row
        form-input(type='container' label='Career History')
          resume-list(:items='coach.careerHistory' type='career')
      form-row
        form-input(type='container' label='Education History')
          resume-list(:items='coach.educationHistory' type='education')
      form-row
        form-input(type='container' label='Certifications & Awards')
          resume-list(:items='coach.accoladeHistory' type='accolade')
      slot
</template>

<script>
import _ from 'lodash';

import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'social-links-widget': require('@/app-public/common/other/social-links-widget')
    .default,
  'profile-photo': require('@/components/other/profile-photo').default,
  'resume-list': require('@/app-public/common/other/resume-list').default,
};

export default {
  components,
  props: {
    coach: {
      type: Object,
      required: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'companySizeOptions',
    ]),
    ...mapRequestStatuses({
      getEnumsRequest: 'enums/GET_ENUMS',
    }),
    placardText() {
      if (this.isMyCoach) {
        return 'Your Coach';
      } else if (_.get(this.coach, 'coachingMatch.matchScore')) {
        return `${Math.round(
          _.get(this.coach, 'coachingMatch.matchScore'),
        )}% Match`;
      }
      return null;
    },
  },
  methods: {},
  beforeMount() {
    this.$store.dispatch('enums/loadEnums');
  },
};
</script>

<style lang='less'>
.coach-info-view {
  .coach-info-header {
    background: @navy;
    border-radius: 25px;
    padding: 15px;
    color: white;
    overflow: hidden;
    margin-bottom: 25px;

    .coach-info-header-flex {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      .profile-photo {
        margin-right: 20px;
        float: left;
      }
    }

    .coach-info {
      flex: 1 1 300px;
      display: inline-block;

      .coach-name {
        font-size: 2em;
        line-height: 1.3em;
        font-weight: 700;
      }

      .coach-tagline {
        margin-bottom: 10px;
      }
    }
  }

  .coach-actions {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid fade(#fff, 20%);
    .button {
      margin-top: 10px;
    }
  }

  .tag-list {
    ul {
      .unstyled-list();
      margin-bottom: 10px;
    }

    li {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;

      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 15px;
      padding: 4px 12px;
      margin-right: 5px;
      margin-bottom: 2px;
      white-space: nowrap;
    }

    .icon-content-wrap {
      margin-right: 5px;
    }
  }
}
</style>
