<template lang='pug'>
main-layout#page-signup()
  template(v-if='signupRequest.isSuccess')
    h2 Thank You!
    p <b>Thanks for signing up. Someone on the AdvisorEY team will contact you shortly.</b>
    p
      | We find that it's best to go over our platform and how it works with a real person, so you can get answers to any questions you may have.
      | We look forward to having you on our team!
  template(v-else-if='getEnumsRequest.isPending')
    h2 loading...
  template(v-else)
    h1 Apply to become a AdvisorEY coach
    p Let us help you do what you do best, and we will take care of the rest.

    p
      icon(name='exclamation-circle')
      |  Are you a company representative looking for coaching for your executives?
      =' '
      router-link(:to='{name: "company-signup"}') Sign up here instead!

    form-row
      form-input(v-model='signupPayload.firstName' label='Your name' placeholder='First name' required)
      form-input(v-model='signupPayload.lastName' placeholder='Last name' required)
    form-row
      form-input(v-model='signupPayload.email' type='email' label='Email' required)
      form-input(v-model='signupPayload.phone' type='phone' label='Phone' required)
      form-input(
        v-model='signupPayload.bestContactTime' type='dropdown' label='Best Time To Contact'
        :options='contactTimeOptions'
      )
    form-row
      form-input(v-model='signupPayload.websiteUrl' type='url' label='Website')
      form-input(v-model='signupPayload.linkedinUrl' type='url' label='LinkedIn profile')

    form-row
      form-input(
        v-model='signupPayload.address.country' type='dropdown'
        label='In what country are you based?'
        :options='countryOptions'
        auto-select
        required
      )

    form-row(inputs-wide-on-small)
      form-input(
        type='dropdown'
        v-model='signupPayload.selfReportedSource'
        label='How did you hear about us?'
        placeholder='- select -'
        :options='SELF_REPORTED_SOURCE_OPTIONS'
      )
      form-input(
        v-model='signupPayload.selfReportedSourceDetail'
        v-if='!!selfReportedSourceDetailLabel'
        :label='selfReportedSourceDetailLabel'
      )

    form-row
      v-button(
        @click='applyButtonHandler'
        :disabled='$vv.$error'
        :request-status='signupRequest' loading-text='Submitting your info...'
      ) Apply Now
      error-message(:request-status='signupRequest')

</template>

<script>
import { makeSeoRefs } from '@/utils/meta';

import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { SELF_REPORTED_SOURCE_OPTIONS, SELF_REPORTED_SOURCE_DETAIL_PROMPTS } from '@/data/self-reported-source';
import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  metaInfo: {
    title: 'Become a Coach',
    link: makeSeoRefs('coach-signup'),
  },
  mixins: [vuelidateGroupMixin],
  data() {
    let detectedTimezone;
    try {
      detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {} // eslint-disable-line no-empty
    return {
      signupPayload: {
        timezone: detectedTimezone,
        address: {}, // we will fill in country only
      },
    };
  },
  computed: {
    ...mapGetters('enums', [
      'countryOptions',
    ]),
    ...mapRequestStatuses({
      signupRequest: 'auth/COACH_SIGNUP',
      getEnumsRequest: 'enums/GET_ENUMS',
    }),
    SELF_REPORTED_SOURCE_OPTIONS() { return SELF_REPORTED_SOURCE_OPTIONS; },
    selfReportedSourceDetailLabel() {
      return SELF_REPORTED_SOURCE_DETAIL_PROMPTS[this.signupPayload.selfReportedSource];
    },
    contactTimeOptions: () => BEST_CONTACT_TIMES,
  },
  methods: {
    async applyButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction('auth/COACH_SIGNUP', this.signupPayload);
      // if (this.signupRequest.isSuccess)
    },
  },
  beforeMount() {
    if (!this.getEnumsRequest.isSuccess) {
      this.$store.dispatchApiAction('enums/GET_ENUMS');
    }
  },
};
</script>

<style lang='less'>
#page-signup {

}
</style>
