/*
  This module, like the profile module, is used both on the front-end and in our admin area
  Not quite ideal, but neither is copy pasting a bunch of code!

  Ideal solution is having webpack include it again and removing some code with a boolean that gets set somehow
  not sure if that is possible...
*/

import _ from 'lodash';
import Vue from 'vue';

import { buildApiActions } from '@/utils/vuex-api-utils';

function updateTargetUser(ctx, response) {
  // Also Update Targeted User
  const { contextUsers } = ctx.getters;

  contextUsers.forEach((user) => {
    if (user && user.id === response.id) {
      user.isExecutive = response.isExecutive;
      user.execLicensePausedAt = response.execLicensePausedAt;
      user.isCompanyAdmin = response.isCompanyAdmin;
    }
  });
}

export default {
  namespaced: true,
  state() {
    return {
      searchResults: {},
      searchTotalCount: 0,

      selectedCompany: {},

      /**
       * Object of the company Id and the list of users.
       */
      selectedCompanyUsers: {
        companyId: 0,
        users: {},
      },
    };
  },
  getters: {
    contextUsers: (s, g, rs, rg) => {
      const authUser = rg['authUser/profile/user'];
      const selectedUser = rg['selectedUser/profile/user'];
      return _.compact([authUser, selectedUser]);
    },

    searchResults: (state) => _.values(state.searchResults),

    searchTotalCount: (state) => state.searchTotalCount,

    selectedCompany: (state) => state.selectedCompany,

    selectedCompanyId: (state) => {
      const { id } = state.selectedCompany;
      return id;
    },

    selectedCompanyUsersCompanyId: (state) => state.selectedCompanyUsers.companyId,

    /**
     * Object keyed by user id.
     */
    selectedCompanyUsersMap(state) {
      return state.selectedCompanyUsers.users;
    },

    /**
     * Returns an array of company users.
     */
    selectedCompanyUsers(state, getters) {
      return _.values(getters.selectedCompanyUsersMap);
    },

    /**
     * Users in the selected company that are active.
     */
    selectedCompanyActiveUsers: (state, getters) => {
      const users = getters.selectedCompanyUsers;
      return _.filter(users, (u) => u.isExecutive && !u.execLicensePausedAt);
    },

    /**
     * Users in the selected company that are passive.
     */
    selectedCompanyPassiveUsers: (state, getters) => {
      const users = getters.selectedCompanyUsers;
      return _.filter(users, (u) => !u.isExecutive || u.execLicensePausedAt);
    },

    companyCanAddExec: (state, getters) => getters.selectedCompanyActiveUsers.length < state.selectedCompany.numExecLicenses,
  },

  ...buildApiActions({

    SEARCH_COMPANIES: {
      action: (ctx, payload) => ({
        method: 'get',
        url: '/companies',
        params: payload,
      }),
      mutation: (state, { response, responseTotalCount }) => {
        state.searchResults = _.keyBy(response, 'id');
        state.searchTotalCount = responseTotalCount;
      },
    },

    CREATE_COMPANY: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/companies',
        params: payload,
      }),
      mutation: (state, { response }) => {
        state.selectedCompany = response;
      },
    },

    GET_COMPANY: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/companies/${ctx.state.selectedCompany.id}`,
      }),
      mutation: (state, { response }) => {
        state.selectedCompany = {
          ...response,
          assessmentType: response.assessmentType || 'leadereq',
          skillsType: response.skillsType || 'leadereq',
          customSkills: response.customSkills || {},
          notifications: response.notifications || {},
        };
      },
    },

    UPDATE_COMPANY: {
      action: (ctx, payload) => ({
        method: 'patch',
        url: `/companies/${ctx.state.selectedCompany.id}`,
        params: payload,
      }),
      mutation: (state, { ctx, response }) => {
        state.selectedCompany = response;

        // Also Update Company In User if applicable
        const { contextUsers } = ctx.getters;

        contextUsers.forEach((user) => {
          if (user && user.company && user.company.id === state.selectedCompany.id) {
            user.company = state.selectedCompany;
          }
        });
      },
    },

    DELETE_COMPANY: {
      action: (ctx, payload) => ({
        method: 'delete',
        url: `/companies/${ctx.state.selectedCompany.id}`,
      }),
      mutation: (state, { response }) => {
        state.selectedCompany = response;
      },
    },

    GET_COMPANY_USERS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/companies/${ctx.state.selectedCompany.id}/users`,
      }),
      mutation: (state, { response }) => {
        state.selectedCompanyUsers = {
          companyId: state.selectedCompany.id,
          users: _.keyBy(response, 'id'),
        };
      },
    },

    INVITE_COMPANY_USER: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/companies/${ctx.state.selectedCompany.id}/users`,
        params: payload,
      }),
      mutation: (state, { response }) => {
        Vue.set(state.selectedCompanyUsers.users, response.id, response);
      },
    },

    UPDATE_COMPANY_USER: {
      action: (ctx, payload) => ({
        method: 'patch',
        url: `/companies/${ctx.state.selectedCompany.id}/users/${payload.id}`,
        params: payload,
      }),
      mutation: (state, { ctx, response }) => {
        // Also Update Targeted User
        updateTargetUser(ctx, response);
        Vue.set(state.selectedCompanyUsers.users, response.id, response);
      },
    },

    TRIGGER_COMPANY_USER_ACTION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/companies/${ctx.state.selectedCompany.id}/users/${payload.id}/${payload.action}`,
        params: _.omit(payload, 'action'),
      }),
      mutation: (state, { ctx, response }) => {
        // Also Update Targeted User
        updateTargetUser(ctx, response);
        Vue.set(state.selectedCompanyUsers.users, response.id, response);
      },
    },

    RESEND_INVITE: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${payload.id}/resend-invite`,
        keyRequestStatusBy: payload.id,
      }),
    },

  }, {
    actions: {

      triggerCompanyAction: async (ctx, { id, action }) => {
        await ctx.dispatch('api-TRIGGER_COMPANY_USER_ACTION', {
          id, action,
        });
      },

      setSelectedCompanyId: async (ctx, companyId) => {
        await ctx.commit('SET_SELECTED_COMPANY_ID', companyId);
        ctx.dispatch('api-GET_COMPANY');
      },

      getCompanyUsers: async (ctx) => {
        if (ctx.getters.selectedCompanyUsersCompanyId !== ctx.getters.selectedCompanyId) {
          ctx.dispatch('api-GET_COMPANY_USERS');
        }
      },

    },
    mutations: {

      SET_SELECTED_COMPANY_ID: (state, companyId) => {
        state.selectedCompany = { id: companyId };
      },

    },
  }),
};
