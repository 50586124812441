import { render, staticRenderFns } from "./document-list.vue?vue&type=template&id=78b0b67c&lang=pug&"
import script from "./document-list.vue?vue&type=script&lang=js&"
export * from "./document-list.vue?vue&type=script&lang=js&"
import style0 from "./document-list.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports