<template lang='pug'>
.user-schedule-tab
  h3 Schedule
  credits-history(:credits-balance="creditsBalance" :credits-history="creditsHistory")
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

const components = {
  'credits-history': require('@/components/other/credits-history').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'User Company Credits',
    };
  },
  data() {
    return {
      user: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdminId: 'userId',
    }),
    ...mapGetters('selectedUser/booking', ['creditsBalance', 'creditsHistory']),
    ...mapRequestStatuses({
      creditsHistoryRequest: 'selectedUser/booking/GET_USER_CREDITS_HISTORY',
    }),
  },
  watch: {},
  methods: {},
  beforeMount() {
    this.$store.dispatchApiAction('selectedUser/booking/GET_USER_CREDITS_HISTORY');
  },
};
</script>

<style lang='less'>
.user-schedule-tab {
}
</style>
