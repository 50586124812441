<template lang='pug'>
.document-list
  template(v-if='hasDocuments')
    .document(v-for='document in documents' :key='`document-${ document.id }`')
      .document__label
        .document__icon
          icon(:name='iconForDocument(document)' scale="1")
        a.document__name(:href='document.fileUrl') {{ document.fileDetails.fileName || 'file' }}
        .document__size {{ document.fileSize | filesize }}
        .document__date ({{ document.createdAt | date }})
      .document__actions(v-if='showDocumentActions(document)')
        //- v-button(size='small' icon='file-upload' theme='dark' @click='onEditDocumentClick') Edit
        v-button(size='small' icon='trash' theme='dark' @click='onDeleteDocumentButtonHandler(document)') Delete
  template(v-else)
    .no-documents {{ noDocumentsMessage }}
  popup(ref='deleteDocumentPopup' title='Delete Document?')
      form-row(v-if='documentToDelete' no-inputs)
        p The document "{{ documentToDelete.fileDetails.fileName }}" will be deleted immediately.

      form-row(slot='bottom')
        error-message(:request-status='deleteDocumentRequest')
        v-button(
          @click='confirmDeleteDocumentHandler'
          :request-status='deleteDocumentRequest' loading-text='Deleting...'
        ) Confirm Delete
</template>

<script>
import _ from 'lodash';

const components = {
};

export default {
  components,
  data() {
    return {
      documentToDelete: null,
    };
  },
  props: {
    documents: { type: Array },
    noDocumentsMessage: { type: String, default: 'No Documents' },
    /**
     * Used to decide whether or not the user can perform edits.
     */
    currentUserId: { },
    isCompanyAdmin: { type: Boolean },
    handleEditDocument: { type: Function },
    handleDeleteDocument: { type: Function },
    editDocumentRequest: {},
    deleteDocumentRequest: {},
  },
  computed: {
    hasDocuments() {
      return this.documents && this.documents.length > 0;
    },
    computedStyles() {
      return {

      };
    },
  },
  methods: {
    iconForDocument(document) {
      let icon;

      if (document.companyUpload) {
        icon = 'building';
      } else if (document.userId !== document.uploaderId) {
        icon = 'graduation-cap'; // Uploaded by the coach, or someone else.
      } else {
        icon = 'user'; // Uploaded by the user.
      }

      return icon;
    },
    showDocumentActions(document) {
      if (document.companyUpload) {
        return this.isCompanyAdmin; // Can edit if it is a company upload and the user is a company admin.
      }

      const isUploader = document.uploaderId === this.currentUserId;
      const documentBelongsToCurrentUser = document.userId === this.currentUserId;

      return isUploader || documentBelongsToCurrentUser;
    },
    onDeleteDocumentButtonHandler(document) {
      this.documentToDelete = document;
      this.$refs.deleteDocumentPopup.open();
    },
    async confirmDeleteDocumentHandler() {
      await this.handleDeleteDocument(this.documentToDelete);
      if (this.deleteDocumentRequest.isSuccess) {
        this.documentToDelete = null;
        this.$refs.deleteDocumentPopup.close();
      }
    },
    onEditDocumentClick() {
      // TODO: Display popup to edit the file info.
    },
  },
};
</script>

<style lang='less'>

.document-list {
  column-count: 2;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .document {
    display: flex;
    margin-bottom: 4px;
  
    .document__label {
      flex: 1 0 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      .document__name {
        font-weight: 700;
        margin-left: 6px;
        padding: 0;
      }
      
      .document__size, .document__date {
        font-size: .9em;
        margin-left: 6px;
        padding: 0;
        font-weight: 400;
        opacity: 0.8;
      }
    }

    .document__actions {
      flex: 0 0 0;
    }
  }

}
</style>