<template lang='pug'>
.onboarding-profile-step
  template(v-if='userIsInDemoMode')
    error-message(warning) THIS IS A DEMO - you can add respondents here but no one will receive an email
    br

  h3 Select 360 Evaluation Respondents
  p
    | An important part of our process are our "360 evaluations" - where we gather feedback about your leadership qualities and EQ (emotional intelligence) from yourself and a group of your bosses, peers, and direct reports.
    | This process helps your and your coach identify your strengths and weaknesses, and most importantly, the best opportunities for growth - which is where to focus attention in your coaching practice.

  p
    b A few important notes about this process:
    ul
      li While you select the respondents, feedback will be provided to you anonymously, grouped by "type" (boss / peer / direct report)
      li The results will NOT be shared with your boss/HR, as it is meant to help you grow, not get anyone in trouble (you can share it with them directly if you like)
      li The feedback gleaned from these evalauations is only as valuable as it is honest and constructively critical
      li Your coach will have a chance to review the results first and they will review them with you

  p
    b How to select your respondents:
    ul
      li They should have frequent interactions with you and know your work
      li Include a balanced set of colleagues that can provide objective feedback, both positive and critical
      li You may want to have your HR manager or boss review your list before you send the evaluations out
      li You should select between 5-10 respondents total to provide feedback

  br

  h4 Select your respondents

  edit-respondents-group(
    v-model='bosses'
    :minimum='1'
    type='boss'
    name='Boss'
    :open-evaluation='!!openEvaluation'
  )

  edit-respondents-group(
    v-model='peers'
    :minimum='2'
    type='peer'
    name="Peer"
    :open-evaluation='!!openEvaluation'
  )

  edit-respondents-group(
    v-model='directReports'
    :minimum='2'
    type='direct_report'
    name="Direct Report"
    :open-evaluation='!!openEvaluation'
  )

  template(v-if='currentUserEvaluations')
    form-row(v-if='currentUserEvaluations.length > 0')
      v-button(
        theme='transparent-dark'
        :to='{name: "executive-evaluations"}'
      ) View Evaluation Requests/Results
    form-row(v-else)
      error-message(warning) Review this list with your HR manager / company admin before proceeding
      v-button(
        @click='sendEvalEmailsButtonHandler'
        :disabled='!shouldEnableSendButton || ($refs.saveBar && $refs.saveBar.hasUnsavedChanges)'
        :request-status='createEvalRequest'
        loading-text='Sending...'
      ) Confirm List &amp; Send Evaluation Requests

  save-bar(
    ref='saveBar'
    :draft-value='newRespondents' :store-value='storeRespondents'
    :save-disabled='$vv.$error'
    :update-request='updateEvalRespondentsRequest'
    @save='saveRespondentsButtonHandler' @reset='resetToStoreState'
  )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'edit-respondents-group': require('./edit-respondents-group').default,
};

export default {
  mixins: [vuelidateGroupMixin],
  components,
  data() {
    return {
      user: {},
      bosses: [],
      peers: [],
      directReports: [],
    };
  },
  watch: {
    storeUser() {
      this.resetToStoreState();
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  computed: {
    ...mapGetters('authUser/profile', {
      storeUser: 'user',
      evaluationRespondentsComplete: 'isEvaluationRespondentsComplete',
    }),
    ...mapGetters('authUser/profile', ['onboardingProgress', 'userIsInDemoMode']),
    ...mapGetters('authUser/evaluations', ['userEvaluations']),
    ...mapRequestStatuses({
      updateEvalRespondentsRequest: 'authUser/profile/UPDATE_USER_EVAL_RESPONDENTS',
      createEvalRequest: 'authUser/evaluations/CREATE_EVALUATION',
    }),
    currentUserEvaluations() {
      const evalauations = this.userEvaluations || [];
      return (evalauations.length === 0) ? undefined : evalauations;
    },
    evaluationRespondents() {
      return this.user.evaluationRespondents || [];
    },
    newRespondents() {
      return {
        bosses: this.bosses,
        peers: this.peers,
        directReports: this.directReports,
      };
    },
    newRespondentsArray() {
      return [...this.bosses, ...this.peers, ...this.directReports];
    },
    storeRespondents() {
      return {
        bosses: _.filter(this.user.evaluationRespondents, { role: 'boss' }),
        peers: _.filter(this.user.evaluationRespondents, { role: 'peer' }),
        directReports: _.filter(this.user.evaluationRespondents, { role: 'direct_report' }),
      };
    },
    storeRespondentsArray() {
      if (this.storeUser.evaluationRespondents && this.storeUser.evaluationRespondents.length) {
        return this.storeUser.evaluationRespondents;
      }
      return [];
    },
    openEvaluation() {
      if (this.userEvaluations && this.userEvaluations.length) {
        return _.find(this.userEvaluations, (evaluation) => !evaluation.completedAt);
      }
      return null;
    },
    mostRecentEvaluationAge() {
      if (this.userEvaluations && this.userEvaluations.length) {
        return dateFns.parseISO(this.userEvaluations[0].date);
      }
      return null;
    },
    today() {
      return dateFns.parseISO(dateFns.format(new Date(), 'yyyy-MM-dd'));
    },
    monthsSinceLastEvaluation() {
      return dateFns.differenceInMonths(this.today, this.mostRecentEvaluationAge);
    },
    shouldEnableSendButton() {
      if (this.userEvaluations && !this.userEvaluations.length && this.evaluationRespondentsComplete) {
        return true;
      }
      // TODO: figure out UX for evals after the first
      // if (this.monthsSinceLastEvaluation > 4 && this.evaluationRespondentsComplete) {
      //   return true;
      // }
      return false;
    },
  },
  methods: {
    _reject: _.reject,
    resetToStoreState() {
      this.user = _.cloneDeep(this.storeUser);
      this.bosses = _.filter(this.user.evaluationRespondents, { role: 'boss' });
      this.peers = _.filter(this.user.evaluationRespondents, { role: 'peer' });
      this.directReports = _.filter(this.user.evaluationRespondents, { role: 'direct_report' });
    },
    saveRespondentsButtonHandler() {
      if (this.$hasError()) return;
      this.$store.dispatchApiAction('authUser/profile/UPDATE_USER_EVAL_RESPONDENTS', {
        id: this.user.id,
        evaluationRespondents: this.newRespondentsArray,
      });
    },
    showName(item) {
      if (item.firstName && item.lastName) {
        return `${item.firstName} ${item.lastName}`;
      } else if (item.firstName) {
        return item.firstName;
      }
      return null;
    },
    sendEvalEmailsButtonHandler() {
      this.$store.dispatchApiAction('authUser/evaluations/CREATE_EVALUATION');
    },
  },
};
</script>

<style lang='less'>
  #evaluation-respondents, #evaluation-actions{
    .center{
      text-align: center;
      width: 100%;
    }
  }

  .center > .form-row-inner {
      justify-content: center;
    }

</style>
