<template lang='pug'>
credits-history(:credits-balance="creditsBalance" :credits-history="creditsHistory")
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'credits-history': require('@/components/other/credits-history').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      user: {},
      updatePasswordPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/booking', ['creditsBalance', 'creditsHistory']),
    ...mapRequestStatuses({
      creditsHistoryRequest: 'authUser/booking/GET_USER_CREDITS_HISTORY',
    }),
  },
  beforeMount() {
    this.$store.dispatchApiAction('authUser/booking/GET_USER_CREDITS_HISTORY');
  },
  methods: {
  },
};
</script>
<style lang='less'>
</style>
