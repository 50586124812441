<template lang='pug'>
  schedule-delegation-settings(:delegationSettings="user" :handleToggleDelegation="toggleDelegation" :toggleDelegationRequest="toggleDelegationRequest")
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'schedule-delegation-settings': require('@/components/other/schedule-delegation-settings').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('authUser/profile', {
      user: 'user',
    }),
    ...mapRequestStatuses({
      toggleDelegationRequest: 'authUser/profile/TOGGLE_COACH_SCHEDULE_DELEGATION',
    }),
  },
  methods: {

    toggleDelegation(enabled) {
      this.$store.dispatch('authUser/profile/toggleCoachScheduleDelegation', enabled);
    },

  },
};
</script>
<style lang='less'>
</style>
