<template lang='pug'>
.coach-approval-subpage
  template(v-if='user.coachStatus === "rejected"')
    h3 Sorry - your coaching profile is currently on our waitlist
    p We will contact you if anything changes!

  template(v-else-if='user.coachStatus === "activated"')
    h3 You're Approved!

  template(v-else-if='!everythingComplete')
    h3 Incomplete profile
    p Before we can match you with clients, please make sure everything is complete!

    ul
      li(v-if='!onboardingProgress.profile') Please complete all parts of your profile
      li(v-if='!onboardingProgress.assessment') Please complete the LeaderMatch assessment
      li(v-if='!onboardingProgress.availability') Please enter your availability and link your calendar

  template(v-else)
    h3 Your profile is under review
    p Thanks for completing everything! Someone on our our team will review your profile and reach out soon!
</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('authUser/profile', ['user', 'onboardingProgress']),
    everythingComplete() {
      return this.onboardingProgress.profile
        && this.onboardingProgress.assessment
        && this.onboardingProgress.availability;
    },
  },
  methods: {
  },

};
</script>

<style lang='less'>
.coach-approval-subpage {

}

</style>
