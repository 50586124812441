<template lang='pug'>
popup(ref="popup" title="Important")
  .deploy-notification
    p The version of the app has been updated.
    p Please refresh your browser to get the latest changes.
    v-button.full(@click="refreshBrowser") click here to refresh
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      intervalId: null,
      currentHash: null,
    };
  },
  props: {
    fileName: {
      type: String,
      required: true,
    },
  },
  methods: {
    async fetchNew() {
      const { intervalId, fileName } = this;
      if (window.document.visibilityState !== 'visible') return;

      try {
        const url = `${window.location.origin}/manifest.json?timestamp=${Date.now()}`;
        const res = await axios(url, {
          headers: { 'Cache-Control': 'no-cache' },
        });

        if (res.status !== 200) throw new Error('server offline');
        if (!this.currentHash) {
          this.currentHash = res.data[fileName];
        } else if (this.currentHash !== res.data[fileName]) {
          this.$refs.popup.open();
        }
      } catch (err) {
        window.clearInterval(intervalId);
      }
    },
    refreshBrowser() {
      window.location.reload(true);
    },
  },
  mounted() {
    // do nothing if not running a deployed version
    if (process.env.NODE_ENV !== 'production') return;
    this.fetchNew();
    this.intervalId = window.setInterval(this.fetchNew, 2 * 60 * 1000);
  },
  beforeDestroy() {
    window.clearInterval(this.invervalId);
  },
};
</script>

<style lang='less'>
.deploy-notification {
  padding: 15px;
  text-align: center;
}
</style>

