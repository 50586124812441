<template lang='pug'>
.onboarding-availability-step
  h3 When are you available for coaching?
  p Your clients (and potential clients) will be able to book meetings with you directly.
  p In order to minimize scheduling conflicts, we base your availability on a regular schedule, as well as events from your calendar(s).

  todo-section(
    title='Set your regular AdvisorEY schedule'
    :complete='scheduleStepComplete'
  )
    div(slot='closed-details')
      ul
        li(v-for='day in DAYS' :key='`days-list-${day.key}`')
          | <b>{{ day.label }}</b>
          = ' - '
          span(v-if='!storeUser.availability[day.key]') Not set
          template(v-else)
            span(v-if='!storeUser.availability[day.key].start') No availability
            span(v-else) {{ storeUser.availability[day.key].start | date('h:mma') }} - {{ storeUser.availability[day.key].end  | date('h:mma') }}
            span(v-if='storeUser.availability[day.key].breakStart')
              |  / break
              |  {{ storeUser.availability[day.key].breakStart | date('h:mma')}}
              |  - {{ storeUser.availability[day.key].breakEnd | date('h:mma')}}

    form-row(no-inputs)
      p
        | Only times within these windows will be available for scheduling sessions.
        br
        | Events on your calendar(s) will also block off those specific times as unavailable.

    schedule-editor(v-model='availability')

  todo-section(
    title='Connect your external calendars (Optional)'
    :complete='calendarStepComplete'
  )
    div(slot='closed-details')
      ul
        li(v-if='!importantCalendars.length') No external calendars linked to your availability
        template(v-else)
          li(v-for='calendar in importantCalendars' :key='`summary-cal-${calendar.id}`')
            | {{ calendar.label }} ({{ calendar.provider }})

    template(v-if='storeUser.coachGoogleCalendarId')
      form-row
        form-input(type='container' label='Program Access')
          p Your AdvisorEY Coaching Calendar can be set shared to allow programs to access your calendar. Do not share this link publically. This link cannot be changed.
          template(v-if='coachCalendarIsShared')
            //--p Your calendar is currently shared: 
            //--  a.coach-calendar-link.program-link(:href='coachGoogleCalendarUrl' target='_blank') {{ coachGoogleCalendarUrl }}
            p iCal Link
              a.coach-calendar-link.program-link(:href='icalCoachGoogleCalendarUrl' target='_blank') {{ icalCoachGoogleCalendarUrl }}
          v-button(
            v-if='coachCalendarIsShared' icon='user-tie'
            theme='transparent-red'
            @click='disableShareCalendarButtonHandler' confirm-click
          ) Disable Program Access
          v-button(
            v-else icon='user-tie' theme='transparent-blue'
            @click='enableShareCalendarButtonHandler' confirm-click
          ) Enable Program Access

    form-row
      form-input(type='container' label='Google Account')
        | {{ storeUser.googleAuthDetails.id ? storeUser.googleAuthDetails.label : "Not Linked"}}

      v-button(@click='connectButtonHandler(true)' inline icon='google-color-g' theme='white-with-border')
        span(v-if='calendarConnectionStatus === "not_connected"') Link your Google Account
        span(v-else-if='calendarConnectionStatus === "insufficient_permissions"') Allow Calendar Access
        span(v-else) Re-Authorize

      v-button(
        v-if='calendarConnectionStatus === "connected"'
        @click='refreshCalendarsButtonHandler'
        :request-status='refreshCalendarsRequest'
        loading-text='Syncing calendars...'
        icon='sync-alt'
        inline
        theme='white-with-border'
      ) Refresh Calendars

    template(v-if='storeUser.googleAuthDetails.id')
      form-row
        form-input(type='container' label='Calendars' instructions='Click on a calendar to toggle')
          p
            a.coach-calendar-link(:href='linkCoachGoogleCalendarUrl' target='_blank') Click to add AdvisorEY Google Calendar
          span(v-if='!allCalendars.length') No calendars found
          todo-section(
            v-else
            v-for='calendar in allCalendars' :key='`cal-${calendar.id}`'
            :icon='calendar.affectsAvailability ? "toggle-on" : "toggle-off"'
            :title='calendar.label'
            :details=`calendar.affectsAvailability ?
              "Events on this calendar will block your schedule as unavailable"
              : "Events on this calendar DO NOT affect your AdvisorEY schedule"
            `
            :click-action='() => toggleCalendarAvailabilityButtonHandler(calendar.id)'
            :theme='calendar.affectsAvailability ? "default" : "transparent"'
          )

  todo-section(
    title='Are you accepting new clients?'
    :icon='storeUser.coachPausedAt ? "pause-circle" : "check-circle"'
    :theme='storeUser.coachPausedAt ? "red" : "green"'
  )
    div(slot='details')
      template(v-if='storeUser.coachPausedAt') You are NOT accepting new clients
      template(v-else) You are currently accepting new clients

    form-row(no-inputs)
      p While not accepting clients, you will not be matched with any new executives and existing matches will not be able to book an interview with you or select you as their coach.
      p Use this toggle to temporarily disable matching while on vacation or if you have too many clients to handle new ones.
    form-row
      error-message(:request-status='togglePauseRequest')
      v-button(
        @click='togglePauseButtonHandler'
        :icon='storeUser.coachPausedAt ? "play-circle" : "pause-circle"'
        :request-status='togglePauseRequest'
        :theme='storeUser.coachPausedAt ? "gray-blue" : "transparent-red"'

      )
        span(v-if='storeUser.coachPausedAt') Resume accepting new clients
        span(v-else) Pause accepting new clients

  //- this page is not used for execs right now, but that might change!
  form-row(v-if='(storeUser.isExecutive && !storeUser.currentCoachId) || (storeUser.isCoach && storeUser.coachStatus !== "activated")')
    v-button(
      :to-named-route='storeUser.isCoach ? "coach-approval" : "coach-matches"' size='large' icon='arrow-right' theme='transparent-dark'
      :disabled='!onboardingProgress.availability'
    ) Continue

  oauth-connect(
    ref='oauthConnect'
    :provider-config='oauthProviderConfig'
    @error='oauthFailure' @code='oauthSuccess'
  )

  save-bar(
    ref='saveBar'
    :draft-value='{coachSettings, availability}' :store-value='{coachSettings: storeUser.coachSettings, availability: storeUser.availability}'
    :update-request='updateUserRequest'
    :save-disabled='$vv.$error'
    @save='saveButtonHandler' @reset='resetToStoreState'
  )


</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'oauth-connect': require('@/components/other/oauth-connect').default,
  'schedule-editor': require('@/components/other/schedule-editor').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      coachSettings: {},
      availability: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['oauthProviderConfig']),
    ...mapGetters('authUser/profile', {
      storeUser: 'user',
      coachCalendarIsShared: 'coachCalendarIsShared',
      coachGoogleCalendarId: 'coachGoogleCalendarId',
      coachGoogleCalendarUrl: 'coachGoogleCalendarUrl',
      icalCoachGoogleCalendarUrl: 'icalCoachGoogleCalendarUrl',
      linkCoachGoogleCalendarUrl: 'linkCoachGoogleCalendarUrl',
      calendarConnectionStatus: 'calendarConnectionStatus',
      calendarStepComplete: 'isCalendarConnectComplete',
      scheduleStepComplete: 'isScheduleComplete',
      onboardingProgress: 'onboardingProgress',
    }),
    ...mapRequestStatuses({
      updateUserRequest: 'authUser/profile/UPDATE_USER',
      refreshCalendarsRequest: 'authUser/profile/REFRESH_CALENDARS',
      togglePauseRequest: 'authUser/profile/TOGGLE_COACH_PAUSE',
    }),

    allCalendars() {
      // return this.storeUser.externalCalendarDetails;
      return _.sortBy(this.storeUser.externalCalendarDetails, 'id');
      // return _.sortBy(this.storeUser.externalCalendarDetails, (cal) => `${cal.affectsAvailability ? 'A' : 'Z'}cal.id`);
    },
    importantCalendars() {
      return _.filter(this.storeUser.externalCalendarDetails, {
        affectsAvailability: true,
      });
    },
    ignoredCalendars() {
      return _.filter(
        this.storeUser.externalCalendarDetails,
        (c) => !c.affectsAvailability,
      );
    },

    DAYS() {
      return [
        { label: 'Monday', key: 1 },
        { label: 'Tuesday', key: 2 },
        { label: 'Wednesday', key: 3 },
        { label: 'Thursday', key: 4 },
        { label: 'Friday', key: 5 },
        { label: 'Saturday', key: 6 },
        { label: 'Sunday', key: 0 },
      ];
    },
  },
  watch: {
    storeUser: {
      // deep: true,
      handler() {
        this.resetToStoreState();
      },
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.coachSettings = _.cloneDeep(this.storeUser.coachSettings);
      this.availability = _.cloneDeep(this.storeUser.availability);
    },

    async enableShareCalendarButtonHandler() {
      await this.$store.dispatchApiAction('authUser/profile/TOGGLE_SHARE_CALENDAR', {
        shareToggle: true,
      });
    },

    async disableShareCalendarButtonHandler() {
      await this.$store.dispatchApiAction('authUser/profile/TOGGLE_SHARE_CALENDAR', {
        shareToggle: false,
      });
    },

    async saveButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction('authUser/profile/UPDATE_USER', {
        availability: this.availability,
        coachSettings: this.coachSettings,
      });
    },

    async connectButtonHandler(withCalendarScopes) {
      this.$refs.oauthConnect.launch('google', {
        ...(withCalendarScopes && {
          additionalScopes: [
            'https://www.googleapis.com/auth/calendar.events.readonly',
            'https://www.googleapis.com/auth/calendar.readonly',
          ],
        }),
      });
    },
    async refreshCalendarsButtonHandler() {
      await this.$store.dispatchApiAction('authUser/profile/REFRESH_CALENDARS');
    },
    async toggleCalendarAvailabilityButtonHandler(calendarId) {
      const cal = _.find(this.allCalendars, { id: calendarId });
      await this.$store.dispatchApiAction('authUser/profile/UPDATE_CALENDAR', {
        id: calendarId,
        affectsAvailability: !cal.affectsAvailability,
      });
    },
    async togglePauseButtonHandler() {
      await this.$store.dispatchApiAction(
        'authUser/profile/TOGGLE_COACH_PAUSE',
        {
          pause: !this.storeUser.coachPausedAt,
        },
      );
    },

    oauthFailure() {},
    oauthSuccess(payload) {
      // payload should have { provider, code } and any other info from oauth flow
      // console.log(payload);
      this.$store.dispatchApiAction(
        'authUser/profile/CONNECT_CALENDAR_ACCOUNT',
        payload,
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    return next(this.$refs.saveBar.warnAboutUnsavedChanges());
  },
};
</script>

<style lang='less'>
.coach-calendar-link {
  &.program-link {
    overflow-wrap: anywhere;
    display: block;
    background: lightgray;
    color: blue;
    border-radius: 6px;
    padding: 6px;
  }
}

.onboarding-availability-step {
  .time-inputs {
    padding: 15px 0;
  }
  .time-input-row {
    border: none;
    .form-row-inner {
      padding: 2px 0;
    }
    .form-input-label {
      min-width: 50px;
      text-align: center;
    }
  }
}
</style>
