<template lang='pug'>
.coach-client
  button-nav-container
    router-link(:to='{name: "client-overview"}') Overview
    router-link(:to='{name: "client-profile"}') Profile
    router-link(v-if='isCoach' :to='{name: "client-progress"}') Progress
    router-link(v-if='isCoach' :to='{name: "client-documents"}') Documents
    router-link(:to='{name: "client-messages"}') Messages
  .coach-client-content
    router-view

</template>

<script>
import { mapGetters } from 'vuex';

import _ from 'lodash';

const components = {};

export default {
  components,
  props: {},
  computed: {
    ...mapGetters('authUser/profile', {
      coach: 'user',
      currentCoachId: 'userId',
    }),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
    isCoach() {
      return this.user.currentCoachId === this.coach.id;
    },
  },
  methods: {},
};
</script>

<style lang='less'>
.coach-client {
  .button-nav-container {
    .button-nav-links {
      margin-left: -20px;
      text-align: left;

      @media @mq-small-only {
        margin-left: -10px;
        text-align: center;
      }
    }
  }

  .profile-photo {
    float: left;
    margin-right: 15px;
  }
  .executive-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.4em;
  }
  .smaller {
    transform: scale(0.9);
  }
}
</style>
