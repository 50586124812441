<template lang='pug'>
//- TODO: v-if='value' is stopping some errors when the address being passed in is undefined
//- need to fix this and how we bind here...

//- TODO make required and requiredWarning work with a single error
//- message for the whole block

.value-identity-input
  form-row
    form-input(v-model='value.firstName' label='First name' required)
    form-input(v-model='value.lastName' label='Last name' required)
    form-input(
      v-if='value.companyId'
      v-model='value.jobTitle' type='string' label='Current Job Title'
      :instructions='jobTitleInstructions'
      :max-length='150'
    )
    form-input(
      v-if='value.isCoach'
      v-model='value.slug' type='slug' label='Slug (URL friendly name)'
      placeholder='ex: dr-john-smith'
    )
    form-input(type='container' no-label grow='none'
      :value='value.profilePic'
      :required-warning='value.isCoach')
      profile-photo(:user='value' :size='100' @click='openProfilePictureWidget' editable)

  form-row
    form-input(v-model='value.email' type='email' label='Email' required)
    form-input(v-model='value.phone' type='phone' label='Phone' :required='value.isCoach')
  form-row
    form-input(
      v-model='value.bestContactTime' type='dropdown' label='Best Time To Contact'
      :options='contactTimeOptions'
    )
    form-input(v-model='value.timezone' type='dropdown' label='Home Timezone' :options='timezoneOptions' required)

  form-row
    form-input(v-model='value.birthday' type='date' label='Birthday')
    form-input(v-model='value.gender' type='dropdown' label='Gender Identity' required)
      form-input-option(value='male') Male
      form-input-option(value='female') Female
      form-input-option(value='other') Other
      form-input-option(value='prefer_not_say') Prefer not to say

</template>

<script>
import { mapGetters } from 'vuex';

import loadExternalScriptsMixin from '@/mixins/load-external-scripts';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

import {
  stateOptionsForCountry, postalCodeLabel, stateLabel,
} from '@/utils/local';

// TODO: fix v-model handling
// https://github.com/vuejs/vue/issues/4373
// technically we shouldnt be changing the passed in object directly
// but keeping a local copy and calling `$emit('input')` on changes

const components = {
  'profile-photo': require('@/components/other/profile-photo').default,
};

export default {
  components,
  mixins: [loadExternalScriptsMixin],
  externalScripts: [
    'https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js',
  ],
  props: {
    value: {
      type: Object, // passed in via v-model
      default: () => {},
    },
    compact: Boolean,
    required: Boolean,
    requiredWarning: Boolean,
    requiredMessage: String,
    disabled: Boolean,
  },
  computed: {
    contactTimeOptions: () => BEST_CONTACT_TIMES,
    timezoneOptions: () => TIMEZONES,
    ...mapGetters('enums', ['countryOptions']),
    jobTitleInstructions() {
      const user = this.value;
      let instructions;

      if (user && user.company) {
        instructions = `at ${user.company.name}`;
      }

      return instructions;
    },
  },
  methods: {
    stateOptionsForCountry,
    postalCodeLabel,
    stateLabel,
    async openProfilePictureWidget() {
      const user = this.value;

      // uploadcare widget loaded by external cdn script
      this.dialog = window.uploadcare.openDialog(user.profilePic, null, {
        publicKey: process.env.UPLOADCARE_PUBLIC_KEY,
        imagesOnly: true,
        imageShrink: '1280x1280 20%', // Resize images before uploading them.
        crop: '1:1',
        tabs: 'file camera instagram',
      });

      try {
        const uploadInfo = await this.dialog;
        console.log('widget success!', uploadInfo);
        user.profilePic = uploadInfo.cdnUrl;
      } catch (err) {
        console.log('widget error!');
      }
    },
  },
};
</script>

<style lang='less'>


</style>
