<template lang='pug'>
main-layout#page-executive-documents(
  full-width
  no-footer
)
  template(v-if='user.isExecutive && user.currentCoachId')
    .documents-content
      template(v-if='getDocumentsRequest.isPending')
        h3 Loading documents list...
      template(v-else-if='getDocumentsRequest.isError')
        error-message(:request-status='getDocumentsRequest')
      template(v-else-if='getDocumentsRequest.isSuccess')
        page-with-chatbox(:user='user' :coach='coach')
          template(v-slot:link)
            router-link.icon-link(:to='{name: "executive-dashboard"}')
              icon(name='angle-left')
              span.icon-link-text Dashboard
          template(v-slot)
            .page-header
              h2 Documents
              document-list(:current-user-id='userId' 
              :handle-delete-document='handleDeleteDocument' :delete-document-request='deleteDocumentRequest'
              :handle-edit-document='handleEditDocument' 
              :documents='documents')
              document-upload(:handle-upload='handleUploadFile')
            
</template>

<script>
import _ from 'lodash';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { mapGetters } from 'vuex';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'page-with-chatbox': require('@/app-public/common/layout/page-with-chatbox')
    .default,
  'document-list': require('@/components/other/document-list').default,
  'document-upload': require('@/components/other/document-upload').default,
};

export default {
  components,
  mixins: [],
  metaInfo: {
    title: 'Documents',
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('authUser/profile', ['user', 'userId']),
    ...mapGetters('authUser/profile', {
      coach: 'userCurrentCoach',
    }),
    ...mapGetters('authUser/documents', ['documents', 'hasDocumentsLoaded']),
    ...mapRequestStatuses({
      getEnumsRequest: 'enums/GET_ENUMS',
      getDocumentsRequest: 'authUser/documents/LOAD_DOCUMENTS',
      uploadDocumentRequest: 'authUser/documents/UPLOAD_DOCUMENT',
      deleteDocumentRequest: 'authUser/documents/DELETE_DOCUMENT',
    }),
  },
  watch: {},
  methods: {
    async handleDeleteDocument(document) {
      return this.$store.dispatch(
        'authUser/documents/deleteDocument',
        document.id,
      );
    },
    async handleEditDocument() {
      // TODO: Add Edit Document later if necessary.
    },
    async handleUploadFile(file) {
      return this.$store.dispatch('authUser/documents/uploadFile', file);
    },
  },
  beforeMount() {
    let redirect;

    // redirects
    if (this.user.isExecutive) {
      // if the user does not have a coach yet, we take them back to the onboarding
      if (!this.user.currentCoachId) {
        redirect = 'welcome';
      }
    } else {
      redirect = 'welcome';
    }

    if (redirect) {
      this.$router.replace({ name: redirect });
    } else if (!this.getDocumentsRequest.success) {
      this.$store.dispatch('authUser/profile/loadCurrentUserCoach');
      this.$store.dispatch('authUser/documents/loadDocuments');
    }
  },
};
</script>

<style lang='less'>
#page-executive-documents {
}
</style>
