<template lang='pug'>
.page-with-chat-sidebar
  page-with-sidebar(sidebarName="chat" openSidebarIcon="comment" closeSidebarIcon="comment-slash" @toggle='toggleHandler')
    template(v-slot:link)
      slot(name='link')
    template(v-slot:title)
      slot(name='title')
    template(v-slot:tools)
      slot(name='tools')
    template(v-slot:sidebar-icon)
      chat-unread-indicator(:coach-user-id='coach.id')
    template(v-slot:sidebar)
      .chat-container(:class='chatIsOpen ? "chat-open" : ""')
        .user-info-card
          profile-photo(:user='coach' :size='50')
          .user-info-wrap
            .user-name {{ coach.fullName }}
        chat-box(
          :coach-user-id='coach.id'
          :exec-user-id='user.id'
        )
    template(v-slot)
      slot
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'page-with-sidebar': require('@/app-public/common/layout/page-with-sidebar')
    .default,
  'chat-box': require('@/app-public/common/other/chat-box').default,
  'profile-photo': require('@/components/other/profile-photo').default,
  'chat-unread-indicator': require('@/app-public/common/other/chat-unread-indicator').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    coach: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  computed: {
    chatIsOpen() {
      return this.sidebarOpen;
    },
  },
  watch: {},
  methods: {
    toggleHandler(showSidebar) {
      this.sidebarOpen = showSidebar;
    },
  },
};
</script>

<style lang='less'>
.page-with-chat-sidebar {

  .chat-unread-indicator {
    position: absolute;
    right: 11px;
    top: 26px;
    padding: 3px 9px;
    border-radius: 20px;
    font-size: 15px;
  }

  .chat-container {
    width: @sidebar-page-sidebar-width;
    position: fixed;
    background: white;

    top: @sidebar-page-top-bar-offset;
    bottom: 0;
    right: -@sidebar-page-sidebar-width;
    z-index: 100;

    .chat {
      border-left: 1px solid rgba(0,0,0,0.1);
    }

    .user-info-card {
      background: @secondary-nav-bar-color;
      color: white;
      padding: 5px;
      height: @sidebar-page-top-bar-height;
      width: @sidebar-page-sidebar-width;
      position: absolute;
      display: flex;

      .user-name {
        font-size: 16px;
        line-height: 1.2em;
        padding-left: 10px;
        margin-top: 6px;
        font-weight: 700;
      }

      .user-info-wrap {
        display: flex;
        align-items: center;
      }
    }

    &.chat-open {
      right: 0px;

      @media @mq-small-only {
        left: 0;
        width: 100%;

        .user-info-card {
          width: 100%;
        }
      }
    }

    .messages-container {
      margin-top: @sidebar-page-top-bar-height;
    }
  }
}
</style>
