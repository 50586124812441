<template lang='pug'>
main-layout
  p
  notification-square(
    message='Agency360 has acquired LeaderEQ to form AdvisorEY.'
  )
    a.acquired(href='https://markets.businessinsider.com/news/stocks/agency360-acquires-leadereq-to-form-advisorey-1031356899') Read More

  h2 Sign In

  form(@submit.prevent='loginWithPasswordButtonHandler')
    form-group(theme='dark')
      form-row
        form-input(
          name='user[email]',
          autocomplete='on',
          type='email',
          v-model='email',
          label='Email',
          placeholder='Your email',
          required,
          required-message='Please enter your email'
        )
        form-input(
          name='user[password]',
          autocomplete='on',
          type='password',
          v-model='password',
          label='Password',
          placeholder='Your password',
          required,
          required-message='Please enter your password'
        )
        form-input(type='container')
          v-button(
            :disabled='$vv.$error',
            :request-status='loginRequest',
            loading-text='Logging you in...',
            type='submit'
          ) Sign in
        error-message(:request-status='loginRequest')

      form-row
        div(slot='instructions')
          .align-center - OR -
        v-button(
          icon='google-color-g',
          @click='loginWithGoogleButtonHandler',
          :request-status='oauthLoginRequest',
          loading-text='Logging you in...',
          theme='white-with-border'
        ) Sign in with Google
        error-message(:request-status='oauthLoginRequest')

  form-row(inputs-wide-on-small)
    v-button(to-named-route='forgot-password', theme='transparent-dark') Forgot your password?
    v-button(to-named-route='company-signup', theme='transparent-dark') No account? Sign up here!

  oauth-connect(
    ref='oauthConnect',
    :provider-config='oauthProviderConfig',
    @error='oauthFailure',
    @code='oauthSuccess'
  )
</template>

<script>
import { mapGetters } from 'vuex';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'oauth-connect': require('@/components/other/oauth-connect').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Login',
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['userIsLoggedIn', 'oauthProviderConfig']),
    ...mapRequestStatuses({
      loginRequest: 'auth/LOGIN',
      oauthLoginRequest: 'auth/OAUTH_LOGIN',
    }),
    passwordMode() {
      return !!this.email;
    },
  },
  mounted() {
    console.log('mounted', this.userIsLoggedIn);
    if (this.userIsLoggedIn) {
      this.$router.push({ name: 'welcome' });
    }
  },
  methods: {
    async loginWithPasswordButtonHandler() {
      const { email, password } = this;
      if (this.$hasError()) {
        return;
      }

      await this.$store.dispatchApiAction('auth/LOGIN', { email, password });
      if (this.loginRequest.isSuccess) {
        this.loginSuccess();
      }
    },

    loginWithGoogleButtonHandler() {
      this.email = null; // used to toggle where to show loading/errors
      this.$refs.oauthConnect.launch('google');
    },
    async oauthSuccess(payload) {
      await this.$store.dispatchApiAction('auth/OAUTH_LOGIN', {
        ...payload,
      });

      if (this.oauthLoginRequest.isSuccess) {
        this.loginSuccess();
      }
    },
    oauthFailure() {},

    loginSuccess() {
      // analytics.track('fe_login');
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else {
        this.$router.push({ name: 'executive-dashboard' });
      }
    },
  },
};
</script>

<style lang='less'>
a.acquired {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}
</style>
