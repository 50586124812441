<template lang='pug'>
booking-popup(
  ref='bookingpopup'
  :coach='coach',
  :session-type='sessionType',
  :availability='availability',
  :fetch-availability-with-config='fetchAvailability',
  :get-availability-request='getAvailabilityRequest',
  :handle-confirm-booking-session='handleConfirmBookingSession',
  :create-session-request='createSessionRequest'
  :handle-schedule-success='handleScheduleSuccess'
)
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'booking-popup': require('./booking-popup').default,
};

export default {
  components,
  mixins: [],
  props: {
    coach: { type: Object, required: true },
    sessionType: { type: String, default: 'coaching' },
    handleScheduleSuccess: { type: Function, required: false },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('selectedUser/booking', ['availability']),
    ...mapRequestStatuses({
      getAvailabilityRequest: 'selectedUser/booking/GET_COACH_AVAILABILITY',
      createSessionRequest: 'selectedUser/booking/CREATE_SESSION',
    }),
  },
  watch: {},
  methods: {
    open(openToDateStr) {
      this.$refs.bookingpopup.open(openToDateStr);
    },
    fetchAvailability(config) {
      this.$store.dispatchApiAction('selectedUser/booking/GET_COACH_AVAILABILITY', config);
    },
    async handleConfirmBookingSession(config) {
      await this.$store.dispatchApiAction(
        'selectedUser/booking/CREATE_SESSION',
        config,
      );
    },
  },
  mounted() {},
};
</script>

<style lang='less'>

</style>
