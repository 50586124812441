<template lang='pug'>
.company-profile-subpage
  h2 Company Profile
  form-row
    form-input(
      v-model='company.name'
      label='Company name'
      required
    )
    form-input(
      v-model='company.dbaName'
      label='Other names (DBA)'
      instructions='Other names used to conduct business'
    )
    form-input(
      v-model='company.country' type='dropdown'
      label='Home country'
      :options='countryOptions'
    )
  form-row
    form-input(
      v-model='company.size' type='dropdown'
      label='Company size'
      :options='companySizeOptions'
    )
    form-input(
      v-model='company.industry' type='dropdown'
      label='Industry'
      :options='industryOptions'
    )
    form-input(
      v-if='company.industry === "other"'
      v-model='company.industryOther'
      label='Other industry?'
      required
    )

  form-row
    form-input(
      v-model='company.phone'
      label='Phone'
    )
    form-input(
      v-model='company.billingEmail'
      label='Billing Email'
    )

  address-input(
    v-model='company.billingAddress'
    label='Billing Address'
  )

  form-group(label='Web & Social')
    form-row
      form-input(v-model='company.websiteUrl' type='url' label='Website')
      form-input(v-model='company.facebookUrl' type='url' label='Facebook URL')
      form-input(v-model='company.linkedinUrl' type='url' label='LinkedIn URL')
    form-row
      form-input(v-model='company.twitterUsername' type='string' label='Twitter Handle')
      form-input(v-model='company.instagramUsername' type='string' label='Instagram Handle')
  
  h3 Settings
  form-group(label='360 Assessment')
    form-row
      form-input(
        v-model='company.assessmentType' type='dropdown'
        label='Company Assessment'
        instructions='If your company uses a different assessment, you can disable the AdvisorEY one from showing up here.'
        :options='companyAssessmentOptions'
      )
      
  company-skills-input(v-model='company')

  form-group(label='Notifications')
    form-row
      form-input(
        v-model='company.notifications.userProfileCompleted' type='checkbox'
        label='Company User Profile Complete'
        instructions='Admins will recieve notifications when a new user finishes their profile for the first time.'
      )

  form-group(label='Emails')
    form-row
      form-input(
        v-model='company.notifications.newUserEmail' type='textarea'
        label='New User Info Email'
        :maxLength='20000'
        instructions='Additional information/text that is sent to your new users. You may put HTML here.'
      )
  
  save-bar(
    ref='saveBar'
    :draft-value='company' :store-value='storeCompany'
    :update-request='updateCompanyRequest'
    :save-disabled='$vv.$error'
    @save='saveButtonHandler' @reset='resetToStoreState'
  )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {
  'address-input': require('@/components/forms/address-input').default,
  'company-skills-input': require('@/components/forms/company-skills-input')
    .default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      company: {},
    };
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'companySizeOptions',
      'countryOptions',
      'companyAssessmentOptions',
    ]),
    ...mapGetters('authUser/profile', {
      storeUser: 'user',
    }),
    ...mapGetters('companies', {
      storeCompany: 'selectedCompany',
    }),
    ...mapRequestStatuses({
      getCompanyRequest: 'companies/GET_COMPANY',
      updateCompanyRequest: 'companies/UPDATE_COMPANY',
    }),
  },
  watch: {
    storeCompany: {
      // deep: true,
      handler() {
        this.resetToStoreState();
      },
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.company = _.cloneDeep(this.storeCompany);
    },
    async saveButtonHandler() {
      if (this.$hasError()) return;

      console.log('Saving company: ', this.company);
      await this.$store.dispatchApiAction(
        'companies/UPDATE_COMPANY',
        this.company,
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    return next(this.$refs.saveBar.warnAboutUnsavedChanges());
  },
};
</script>

<style lang='less'>
.onboarding-profile-step {
  .bio-input textarea {
    min-height: 250px;
  }
}
</style>
