<template lang='pug'>
#credits-ledger
  h4 Credits
  p
    | Current balance = <b>{{ creditsBalance }} hour{{creditsBalance === 1 ? '' : 's'}}</b>
    = ' '
    router-link(:to="{name: 'credits-faq'}" target="_blank") (whats this?)

  template(v-if='showAdminActions')
    v-button(@click='onAddCreditsButtonHandler()' size="small") Add Credits

  table-component.compact(
    :data="creditsHistory"
    :show-filter="false" :show-caption="false" :sortable='false'
    filter-no-results="No credits history"
  )
    table-column2(label="Date" show="date" type="date")
    table-column2(label="Label" show="label")
    table-column2(label="# Credits" show="numHours" type="delta")

    template(v-if='showAdminActions')
      table-column2(label="Deleted At" show="deletedAt" type="date")
      table-column(label="Actions")
        template(slot-scope="row" v-if="row.creditId")
            v-button(v-if='!row.deletedAt' @click='confirmDeleteCreditButtonHandler(row.creditId)' size="small") Delete Credit
            v-button(v-else @click='confirmUndoDeleteCreditButtonHandler(row.creditId)' size="small") Undo Delete Credit

  popup(ref='addCreditsPopup' title='Add Credits')
    form-row
      form-input(type='number' v-model='creditsToAdd' label='Credits To Add')
    form-row(slot='bottom')
      error-message(:request-status='addCreditsRequest')
      v-button(
        @click='confirmAddCreditsHandler'
        :request-status='addCreditsRequest' loading-text='Adding...'
      ) Confirm Add Credits

</template>

<script>
import _, { isNumber } from 'lodash';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    showAdminActions: {
      default: false,
    },
    creditsBalance: {},
    creditsHistory: {},
    handleAddCredits: { type: Function },
    addCreditsRequest: {},
    handleDeleteCredit: { type: Function },
    deleteCreditRequest: {},
    handleUndoDeleteCredit: { type: Function },
    undoDeleteCreditRequest: {},
  },
  data() {
    return {
      creditsToAdd: null,
    };
  },
  computed: {},
  methods: {
    onAddCreditsButtonHandler() {
      this.$refs.addCreditsPopup.open();
    },
    async confirmAddCreditsHandler() {
      if (this.$hasError()) {
        return;
      }

      if (isNumber(this.creditsToAdd)) {
        await this.handleAddCredits(this.creditsToAdd);

        if (this.addCreditsRequest.isSuccess) {
          this.creditsToAdd = null;
          this.$refs.addCreditsPopup.close();
        }
      }
    },
    async confirmDeleteCreditButtonHandler(creditId) {
      await this.handleDeleteCredit(creditId);
    },
    async confirmUndoDeleteCreditButtonHandler(creditId) {
      await this.handleUndoDeleteCredit(creditId);
    },
  },
};
</script>

<style lang='less'>
</style>
