<template lang='pug'>
.chat-unread-indicator(v-if='unreadCount') {{ unreadCount }}
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  props: {
    allChannels: Boolean,
    coachUserId: { type: Number },
    execUserId: { type: Number },
  },
  computed: {
    ...mapGetters('auth', ['authUserId']),
    unreadCount() {
      if (this.allChannels) {
        return this.$store.getters['chat/totalUnreadMessageCount'];
      } else if (this.execUserId) {
        return this.$store.getters['chat/getUnreadMessageCount'](this.authUserId, this.execUserId);
      }

      return this.$store.getters['chat/getUnreadMessageCount'](this.coachUserId, this.authUserId);
    },
  },
};
</script>

<style lang='less'>
// @unread-chat-red: #f12323;

.chat-unread-indicator {
  background: @brand-gray-blue;
  color: white;
  padding: 2px 5px 0;
  min-width: 22px;
  line-height: 16px;
  text-align: center;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 700;
  position: relative;

  @tail-size: 6px;
  &:after {

    content: '';
    position: absolute;
    top: 100%;
    left: 30%;
    width: 0;
    height: 0;
    border: @tail-size solid transparent;
    border-top-color: @brand-gray-blue;
    border-bottom: 0;
    border-left: 0;
    // margin-left: 100%;
    // margin-bottom: @tail-size;
  }
}
</style>

