<template lang='pug'>
main-layout
  h2 What are credits?

  p Credits are what we use to track coaching hours. Each credit is good for 1 hour (60 minutes) of time spent in a session with your coach. Sessions can be as short as 30 minutes or as long as 2 hours, and can be in-person or remote (video or phone).
  p While your account is active and in good standing, on the 1st day of each month, you get 2 credits to use that month (some subscriptions may vary slightly). Plus, you start with 1 bonus credit to help with getting to know your coach at the beginning of your relationship.
  p We usually recommend scheduling a one-hour coaching session about every two weeks, but we understand that business leaders have busy schedules, so unused credits roll over and you can use them to make up missed sessions later.
  p The credit balance you see in our app will reflect your balance as of the end of the current month - showing you how many credits will be left to roll over. We also allow you to use up to 1 hour of next month's credits if you need to, resulting in a negative balance until the month ends.
  p You can still schedule sessions in future months as much as you like. When the new month starts and you get your new credits, if you don't have enough to cover your booked sessions, we'll reach out to fix it - either by canceling sessions or purchasing more credits.
  p If you ever feel you need more time with your coach, you can purchase more credits as needed - talk to your plan administrator or reach out directly to <a href='mailto:sales@app.advisorey.com'>sales@app.advisorey.com</a>

  br
  h4.faq-section-title More questions?
  br
  faq-question(q='Do my credits expire?')
    p As long as your account is active and in good standing, your credits will not expire and you can use them later. We do encourage you to book your sessions regularly rather than sporadically to get the most out of your coaching experience. Reach out to support if you need to pause a subscription to use built-up credits.
  faq-question(q='Can I transfer credits between users at my company?')
    p Generally no - however we will make some exceptions. Reach out to support and we'll try to help.

</template>

<script>
const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'faq-question': require('./faq/faq-question').default,
};

export default {
  components,
  metaInfo: {
    title: 'Credits FAQ',
  },
};
</script>

<style lang='less'>

</style>
