<template lang='pug'>
.notification-square(:class='theme + "-theme"')
  p {{ message }}
  slot
</template>

<script>
import _ from 'lodash';

const components = {
  icon: require('./icon').default,
};

export default {
  components,
  props: {
    label: String,
    message: String,
    theme: {
      type: String,
      default: 'gray-blue',
    },
  },
};
</script>

<style lang='less'>
.notification-square {
  padding: 12px 8px;
  border-radius: 8px;
  text-align: center;
  color: white;
  font-size: 1.1em;

  &.gray-blue-theme {
    background: @brand-gray-blue;
  }

  &.white-theme {
    background: none;
    color: black;
  }

  &.navy-theme {
    background: @navy;
  }
}
</style>
