<template lang='pug'>
#page-company-users-dashboard
  h2 Users Dashboard

  //- company status and number of licenses
  subscription-status

  //- users list
  template(v-if='getCompanyUsersRequest.isPending')
    div Loading users...
  template(v-if='getCompanyUsersRequest.isError')
    error-message(:request-status='getCompanyUsersRequest')
  template(v-if='getCompanyUsersRequest.isSuccess')

    h3 Active Executives
    error-message(v-if='!activeUsers.length') No active executives receiving coaching
    template(v-else)
      users-list-item(v-for='user in activeUsers' :key='`company-user-${user.id}`' :user='user')

    template(v-if='passiveUsers.length')
      br
      h3 Other Users
      users-list-item(v-for='user in passiveUsers' :key='`company-user-${user.id}`' :user='user')

    br
    todo-section(
      icon='plus-circle'
      title='Add another user'
      :click-action='addUserButtonHandler'
      theme='add'
    )

  popup(ref='addUserPopup' title='Invite another user')
    form-row
      form-input(
        type='checkbox'
        v-model='addUserPayload.isExecutive'
        label='Should this user receive coaching'
        :required='!addUserPayload.isCompanyAdmin' required-message='User must be an admin or an executive'
      )
        | Yes - this user is an executive and will receive coaching
    form-row
      form-input(
        type='checkbox'
        v-model='addUserPayload.isCompanyAdmin'
        label='Is this user a company administrator'
      )
        | Yes - this user can manage company info and users
    form-row
      form-input(v-model='addUserPayload.firstName' label='First name' required)
      form-input(v-model='addUserPayload.lastName' label='Last name' required)
    form-row
      form-input(v-model='addUserPayload.email' type='email' label='Email' required)
    form-row
      form-input(v-model='addUserPayload.companyNotes' type='textarea' label='Notes To Coach')
    form-row
      v-button(
        @click='confirmAddUserButtonHandler'
        :request-status='inviteUserRequest' loading-text='Creating user...'
        :disabled='$vv.$error'
      ) Send invite
      error-message(:request-status='inviteUserRequest')


</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {
  'users-list-item': require('./users-list-item').default,
  'subscription-status': require('../shared/subscription-status').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      addUserPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdmin: 'user',
    }),
    ...mapGetters('companies', {
      company: 'selectedCompany',
      companyUsers: 'selectedCompanyUsers',
      activeUsers: 'selectedCompanyActiveUsers',
      passiveUsers: 'selectedCompanyPassiveUsers',
      companyCanAddExec: 'companyCanAddExec',
    }),
    ...mapRequestStatuses({
      getCompanyRequest: 'companies/GET_COMPANY',
      getCompanyUsersRequest: 'companies/GET_COMPANY_USERS',
      inviteUserRequest: 'companies/INVITE_COMPANY_USER',
    }),
  },
  beforeMount() {
    this.$store.dispatch('companies/getCompanyUsers');
  },
  methods: {
    addUserButtonHandler() {
      this.$refs.addUserPopup.open();
    },
    async confirmAddUserButtonHandler() {
      if (this.$hasError()) {
        return;
      }

      await this.$store.dispatchApiAction(
        'companies/INVITE_COMPANY_USER',
        this.addUserPayload,
      );
      if (this.inviteUserRequest.isSuccess) {
        this.addUserPayload = {};
        this.$refs.addUserPopup.close();
      }
    },
  },
};
</script>

<style lang='less'>
</style>
