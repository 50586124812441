<template lang='pug'>
#page-executive-progress-questionnaire
  h2 Progress Questionnaire
  .assessment-subpage
    div(v-if='getQuestionRequest.isPending || getQuestionnairesRequest.isPending') Loading...
    div(v-else-if='getQuestionRequest.isError')
      error-message(:request-status='getQuestionRequest')
    div(v-else-if='getQuestionnairesRequest.isError')
      error-message(:request-status='getQuestionRequest')
    template(v-else-if='getQuestionRequest.isSuccess && getQuestionnairesRequest.isSuccess')
      template(v-if='!assessment.completedAt && question')
        .total-questions Question \#{{ question.currentIndex + 1 }} of {{ question.totalQuestions }}
        .progress-bar
          .progress-bar-fill(:style='{width: `${progressPercent}%`}')

        .current-question(:class='emptyResponseWarning ? "is-missing-answer" : ""')
          .question-prompt {{ question.prompt }}
          .question-options
            label.question-response-option(v-for='index in [0,1,2,3,4]' :key='`q-${question.id}-${index}`')
              //- .answer-quick-key {{ keysArray[index].toUpperCase() }}
              input.answer-input(
                type='radio' name='currentQuestion' :value='index'
                @change='radioChangeHandler'
                :checked='index === currentQuestionAnswer'
              )
              .answer-radio {{ keysArray[index].toUpperCase() }}
              .answer-label(v-if='index === 0') Disagree<br/>Strongly
              .answer-label(v-if='index === 5') Agree<br/>Strongly

          .likert-instruction choose the option that best describes you

          v-button.submit-answer-button(
            @click='submitAnswerButtonHandler'
            :request-status='answerQuestionRequest' loading-text='Saving Response...'
            size='large'
          ) Submit Response
          .keyboard-instructions
            | Want to go faster? Use your keyboard!
            br
            | Hit the number key to select an option and submit your response.
      template(v-else-if='assessment.completedAt || (question && question.assessmentComplete)')
        h3 Thank you for completing the progress questionnaire.
        template(v-if='getQuestionnairesRequest.isPending')
          p Loading your results...
          icon(scale='10' name='spinner')
        template(v-else-if='getQuestionnairesRequest.isError')
          error-message(:request-status='getQuestionnairesRequest')
        template(v-else)
          p This questionnaire is used to help you and your coach track your progress.</b>
          p.small Please view the results and choose two topics to work on with your coach.
          assessment-results(:assessment='assessment')
          //-- Make selection
          .topic-selection-view(v-if='!assessment.selection')
            p Choose two topics that you would like to work on with your coach.
            p.bold You cannot change your response until your next progress report.
            .topic-selection-input
              form-row
                form-input(
                  v-model='selection.topics' type='multi-checkbox'
                  label='Topics'
                  :max-length='4'
                  :options='topicOptions'
                )
                .topic-selection-input-error
                  error-message(v-if='tooManyTopics' warning) Select only 2 topics!
              form-row
                form-input(
                  v-model='selection.note' type='textarea'
                  label='Is there anything you would like to address?'
                  :maxLength='1000'
                )

            .topic-selection-submit
              v-button(
                :disabled='!canSubmit'
                @click='saveTopicsButtonHandler'
                :request-status='makeSelectionRequest'
                confirm-click
                theme='red'
              ) Save Topics
              error-message(:request-status='makeSelectionRequest')
          template(v-else)
            p You chose the following topics to work on:
            questionnaire-selection(:questionnaire='assessment')

          //-- TODO: Select two topics to focus on.

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'assessment-results': require('@/components/other/assessment-results').default,
  'questionnaire-selection': require('@/components/other/questionnaire-selection').default,
};

const INPUT_KEYS = '12345'.split('');

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Progress Questionnaire',
  },
  data() {
    return {
      currentQuestionAnswer: null,
      emptyResponseWarning: false,
      selection: {
        topics: [],
      },
    };
  },
  props: {
    questionnaireId: { type: Number },
  },
  watch: {
    questionnaireId() {
      this.$store.dispatch('authUser/questionnaire/setCurrentQuestionnaireId', this.questionnaireId);
    },
  },
  computed: {
    ...mapGetters('authUser/profile', {
      user: 'user',
    }),
    ...mapGetters('authUser/questionnaire', {
      question: 'nextQuestion',
      assessment: 'currentQuestionnaire',
    }),
    ...mapRequestStatuses({
      getQuestionnairesRequest: 'authUser/questionnaire/LOAD_QUESTIONNAIRES',
      getQuestionRequest: 'authUser/questionnaire/GET_NEXT_QUESTION',
      answerQuestionRequest: 'authUser/questionnaire/ANSWER_QUESTION',
      makeSelectionRequest: 'authUser/questionnaire/MAKE_SELECTION',
    }),
    keysArray() {
      return INPUT_KEYS;
    },
    progressPercent() {
      return 100 * (this.question.currentIndex + 1) / this.question.totalQuestions;
    },
    topicOptions() {
      return _.keys(this.assessment.results);
    },
    tooManyTopics() {
      return this.selection.topics.length > 2;
    },
    canSubmit() {
      return this.selection.topics && this.selection.topics.length === 2;
    },
  },
  beforeMount() {
    this.$store.dispatch('authUser/questionnaire/setCurrentQuestionnaireId', this.questionnaireId);
    window.addEventListener('keypress', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.keyHandler);
  },
  methods: {
    radioChangeHandler(event) {
      this.currentQuestionAnswer = parseInt(event.target.value);
    },
    async keyHandler($event) {
      if (this.answerQuestionRequest.isPending) return;
      if (this.getQuestionRequest.isPending) return;

      // some checks copied from vue router
      const {
        altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented,
      } = $event;

      // don't handle with modifier keys
      if (metaKey || altKey || ctrlKey || shiftKey) return;
      if (defaultPrevented) return; // don't handle when preventDefault called

      const answer = this.keysArray.indexOf($event.key.toLowerCase());
      if (answer === -1) return;
      this.currentQuestionAnswer = answer;
      this.submitAnswerButtonHandler();
    },
    loadFirstQuestion() {
      this.$store.dispatchApiAction('authUser/questionnaire/GET_NEXT_QUESTION');
    },
    async submitAnswerButtonHandler() {
      if (this.currentQuestionAnswer === null) {
        this.emptyResponseWarning = true;
        return;
      }

      await this.$store.dispatchApiAction('authUser/questionnaire/ANSWER_QUESTION', {
        questionId: this.question.id,
        answer: this.currentQuestionAnswer,
      });

      this.currentQuestionAnswer = null;
      this.emptyResponseWarning = false;
    },
    async saveTopicsButtonHandler() {
      if (this.selection.topics.length !== 2 || this.makeSelectionRequest.inProgress) {
        return;
      }

      await this.$store.dispatchApiAction('authUser/questionnaire/MAKE_SELECTION', this.selection);
    },
  },
};
</script>

<style lang='less'>
#page-executive-progress-questionnaire {
  .scrollable-page-content {
    padding: 15px;
  }

  .topic-selection-view {
    .form-input-wrapper > label {
      display: inline-block;
      min-width: 300px;
    }
  
    .topic-selection-submit {
      margin-top: 12px;
    }
  }
}
</style>
