<template lang='pug'>
popup.booking-popup(
  ref='popup'
  :title='popupTitle'
  full-screen
  @scrollBarWidthChanged='(newWidth) => scrollBarWidth = newWidth'
)
  template(v-if='getAvailabilityRequest.isPending')
    form-row(no-inputs)
      icon(name='spinner' scale='3')
      br
      | Loading {{ coach.firstName }}'s schedule
  template(v-else-if='getAvailabilityRequest.isError') Error
  template(v-else-if='getAvailabilityRequest.isSuccess')
    div(slot='top')
      form-row
        p.align-center(
          v-if='sessionType === "interview"' slot='instructions'
        ) This is just a brief introductory call to meet {{ coach.firstName }}. You can meet all your matches before selecting the coach you like best.
        form-input(
          type='dropdown' inline-label
          v-model='newSession.duration'
        )
          icon(name='clock' slot='label')
          form-input-option(:value='30') 30 min{{ sessionType === "coaching" ? " - 0.5 credits" : ""}}
          template(v-if='sessionType === "coaching"')
            form-input-option(:value='45') 45 min - .75 credits
            form-input-option(:value='60') 60 min - 1 credit
            form-input-option(:value='90') 90 min - 1.5 credits
            form-input-option(:value='120') 120 min - 2 credits
        form-input(
          type='dropdown'
          inline-label
          v-model='newSession.medium'
        )
          icon(name='portrait' slot='label')
          form-input-option(value='phone') Phone call
          form-input-option(value='video') Video call
          form-input-option(v-if='sessionType === "coaching"' value='in_person') In Person

      //- address-input(v-if='newSession.medium === "in_person"' v-model='locationAddress' compact)
      form-group(v-if='newSession.medium === "in_person"')
        form-row
          p.small.italic.text-red.align-center(slot='instructions')
            icon(name='exclamation-triangle')
            |  Please discuss suitable locations with your coach before booking
          form-input(
            type='text' v-model='newSession.locationDetails.name'
            label='Location Name' inline-label
            placeholder='ex: Globex HQ'
            required
          )
          form-input(
            type='text' v-model='newSession.locationDetails.addressString'
            label='Address' inline-label
            placeholder='ex: 123 Main st, San Francisco'
            required
          )
          form-input(
            type='text' v-model='newSession.locationDetails.notes'
            label='Notes' inline-label
            placeholder='ex: 7th floor, suite 702'
          )

      .date-header-bar
        .date-header-controls
          a.date-header-button(href='#' @click.prevent='prevDatesButtonHandler' :class='browseStartDate === todayStr ? "is-disabled" : ""')
            icon(name='arrow-left')
          .date-header-title
            | <b>{{ formatDate(dates[0], 'MMM d') }}</b> — <b>{{ formatDate(dates[dates.length - 1], 'MMM d') }}</b>
          a.date-header-button(href='#' @click.prevent='nextDatesButtonHandler')
            icon(name='arrow-right')
        .date-headers(:style='{paddingRight: `${scrollBarWidth}px`}')
          .date-header(
            v-for='d in dates' :key='`date-headers-${formatDate(d, "yyyyMMdd")}`'
            :class='{"is-today": formatDate(d, "yyyy-MM-dd") === todayStr}'
          )
            .day-of-week {{ formatDate(d, 'iii') }}
            .date {{ formatDate(d, 'MMM d') }}

    .meeting-options
      .booking-options-date-column(
        v-for='options, dateStr in availability' :key='`date-columns-${dateStr}`'
        :class='{"is-today": dateStr === todayStr, "is-weekend": isDateWeekend(dateStr)}'
      )
        .meeting-option(
          v-for='option in options'
          :key='`meeting-${dateStr}-${option.startTime}`'
          @click='selectMeeting(dateStr, option.startTime)'
          :class='newSession.date === dateStr && newSession.startTime === option.startTime ? "is-selected" : ""'
        ) {{ SLOT_LABELS[option.startTime] }}
        //- - {{ option.endTime }}


    template(slot='bottom')
      form-row
        form-input(
          v-model='newSession.executiveRequest' type='textarea'
          label='(Optional) Message To Coach'
          :maxLength='2000'
        )
      form-row
        error-message(:request-status='createSessionRequest')

        //- form-input(
        //-   type='container' no-label grow='none'
        //- )
        //-   | 1.5&nbsp;Credits&nbsp;Remaining
        //-   br
        //-   | For&nbsp;this&nbsp;month
        v-button(
          :icon='newSession.date ? "check-circle" : ""'
          @click='confirmBookingButtonHandler'
          :request-status='createSessionRequest' loading-text='Confirming your meeting...'
          :disabled='$vv.$error || !newSession.date || !newSession.startTime'
        )
          span(v-if='!newSession.date')
            | select a date + time
          span(v-else)
            | Confirm  - {{ newSession.date | date('MMM do') }} @ {{ SLOT_LABELS[newSession.startTime] }} ({{ newSession.duration }} min, {{ MEDIUM_LABELS[newSession.medium] }})


</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
  'address-input': require('@/components/forms/address-input').default,
};

let d = dateFns.startOfDay(new Date());
const endOfDay = dateFns.endOfDay(new Date());
const SLOT_LABELS = {};
while (d < endOfDay) {
  SLOT_LABELS[dateFns.format(d, 'HH:mm')] = dateFns.format(d, 'h:mm a');
  d = dateFns.addMinutes(d, 15);
}

const MEDIUM_LABELS = {
  phone: 'Phone',
  video: 'Video',
  in_person: 'In-person',
};
const MEDIUM_ICONS = {
  phone: 'phone',
  video: 'video',
  in_person: 'user-friends',
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    coach: { type: Object, required: true },
    sessionType: { type: String, default: 'coaching' },
    availability: { type: Object },
    getAvailabilityRequest: { type: Object },
    fetchAvailabilityWithConfig: { type: Function },
    handleConfirmBookingSession: { type: Function },
    createSessionRequest: { type: Object },
    handleScheduleSuccess: { type: Function, required: false },
  },
  data() {
    const now = new Date();
    return {
      numDaysToShow: 7,
      scrollBarWidth: 0,

      // see open method for defaults
      todayStr: null,
      browseStartDate: null,
      newSession: {},
    };
  },
  computed: {
    SLOT_LABELS: () => SLOT_LABELS,
    MEDIUM_LABELS: () => MEDIUM_LABELS,
    MEDIUM_ICONS: () => MEDIUM_ICONS,
    popupTitle() {
      if (this.sessionType === 'interview') {
        return `Book an interview with ${this.coach.firstName}`;
      } else if (this.sessionType === 'coaching') {
        return `Book a session with ${this.coach.firstName}`;
      }
      return 'Book a meeting'; // should not happen
    },
    selectedDate() {
      return dateFns.parseISO(this.newSession.date);
    },
    dates() {
      return _.map(this.availability, (options, dateStr) => dateFns.parseISO(dateStr));
    },
    selectedDurationLabel() {
      return [this.newSession.duration];
    },
  },
  watch: {
    'newSession.duration': function () {
      this.fetchAvailability();
    },
    browseStartDate() {
      this.fetchAvailability();
    },
    availability() {
      // if a date/time was selected, see if it is still availble
      // this is so if you change the meeting duration it will keep your selection if possible
      if (this.newSession.date && this.availability[this.newSession.date]) {
        const timeExists = _.find(this.availability[this.newSession.date], {
          startTime: this.newSession.startTime,
        });
        if (timeExists) return; // break out and leave the selection as it was
      }
      this.newSession.date = null;
      this.newSession.startTime = null;
    },
  },
  methods: {
    formatDate: dateFns.format,
    isDateWeekend(dateStr) {
      const date = dateFns.parse(dateStr, 'yyyy-MM-dd', new Date());
      console.log(date);
      return dateFns.isWeekend(date);
    },
    fetchAvailability() {
      this.fetchAvailabilityWithConfig({
        coachUserId: this.coach.id,
        date: this.browseStartDate,
        numDays: this.numDaysToShow,
        sessionDuration: this.newSession.duration,
      });
    },
    open(openToDateStr) {
      this.todayStr = dateFns.format(new Date(), 'yyyy-MM-dd');
      this.browseStartDate = openToDateStr || this.todayStr;
      if (openToDateStr < this.todayStr) this.browseStartDate = this.todayStr;
      this.newSession = {
        medium: 'video',
        date: null,
        startTime: null,
        type: this.sessionType,
        duration: this.sessionType === 'interview' ? 30 : 60,
        locationDetails: {},
      };

      this.fetchAvailability();
      this.$refs.popup.open();
    },
    close() {
      this.$refs.popup.close();
    },
    selectMeeting(dateStr, startTime) {
      this.newSession.date = dateStr;
      this.newSession.startTime = startTime;
    },
    prevDatesButtonHandler() {
      const newDate = dateFns.subDays(
        dateFns.parseISO(this.browseStartDate),
        this.numDaysToShow,
      );
      let newDateStr = dateFns.format(newDate, 'yyyy-MM-dd');
      if (newDateStr <= this.todayStr) newDateStr = this.todayStr;
      this.browseStartDate = newDateStr;
    },
    nextDatesButtonHandler() {
      const newDate = dateFns.addDays(
        dateFns.parseISO(this.browseStartDate),
        this.numDaysToShow,
      );
      this.browseStartDate = dateFns.format(newDate, 'yyyy-MM-dd');
    },
    async confirmBookingButtonHandler() {
      if (this.$hasError()) return;

      await this.handleConfirmBookingSession({
        coachUserId: this.coach.id,
        // execUserId defaults to currently selected user
        ...this.newSession,
      });

      if (this.createSessionRequest.isSuccess) {
        if (this.handleScheduleSuccess) {
          this.handleScheduleSuccess();
        }

        this.$refs.popup.close();
      }
    },
  },
  mounted() {},
};
</script>

<style lang='less'>
.booking-popup {
  .date-headers,
  .meeting-options {
    display: flex;
    text-align: center;
    > div {
      flex: 1 0 0;
    }
  }
  .date-header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: @navy;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;
  }
  .date-header-title {
    // font-weight: 700;
    padding: 0 20px;
    font-size: 18px;
    text-transform: uppercase;
  }
  .date-header-button {
    color: white;
    padding: 9px;
    transition: 0.2s all;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
    &:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:last-child {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    &.is-disabled {
      color: rgba(255, 255, 255, 0.2);
      pointer-events: none;
    }
    .icon {
      width: 26px;
      height: 26px;
    }
  }
  .duration-select {
    .icon {
      margin-right: 5px;
    }
  }

  .date-headers {
    background: @navy;
    color: white;
    padding-top: 6px;
    padding-bottom: 6px;
    .day-of-week {
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1em;
    }
    .date {
      font-size: 13px;
      line-height: 1em;
    }
    .date-header {
      &.is-today {
        color: @yellow;
      }
    }
  }
  .booking-options-date-column {
    padding: 5px;
    border-left: 1px solid #dadada;
    &:first-child {
      border: none;
    }
    &.is-today {
    }
    &.is-weekend {
      background: #f0f0f0;
    }
  }

  .meeting-option {
    border-radius: 20px;
    background: #ddd;
    margin-bottom: 5px;
    padding: 3px 0 2px;
    &:hover {
      background: lighten(@navy, 10%);
      color: white;
      cursor: pointer;
    }
    &.is-selected {
      background: @brand-gray-blue;
      color: white;
      font-weight: 700;
    }
  }
}
</style>
