<template lang='pug'>
  popup#remove-respondent-popup(ref='popup' title='Remove respondent')
    form-row(no-inputs)
      p Are you sure you want to remove {{evaluationResponse.respondentFirstName}} {{evaluationResponse.respondentLastName}} from your 360 evaluation?
      p The link in their email will not be valid anymore and their response will no longer be requried to complete the evaluation.
    form-row
      form-input(
        type='radio'
        label='Save for future evaluations?'
        v-model='removeFromUsersRespondentsList'
      )
        form-input-option(:value='true') Also remove this respondent from future 360 evalautions
        form-input-option(:value='false') Save this respondent for future 360 evalautions
    form-row
      error-message(:request-status='removeRespondentRequest')
      v-button(@click='confirmButtonHandler' :request-status='removeRespondentRequest') Yes - remove them
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

export default {
  props: {
  },
  computed: {
    ...mapRequestStatuses({
      removeRespondentRequest: 'authUser/evaluations/REMOVE_RESPONDENT',
    }),
  },
  data() {
    return {
      evaluationResponse: {},
      removeFromUsersRespondentsList: false,
    };
  },
  methods: {
    open(evaluationResponse) {
      this.evaluationResponse = evaluationResponse;
      this.$refs.popup.open();
    },
    close() {
      this.$refs.popup.close();
      this.removeFromUsersRespondentsList = false;
    },
    async confirmButtonHandler() {
      await this.$store.dispatchApiAction('authUser/evaluations/REMOVE_RESPONDENT', {
        evaluationResponse: this.evaluationResponse,
        removeFromUsersRespondentsList: this.removeFromUsersRespondentsList,
      });

      this.close();
    },
  },
};
</script>

<style lang='less'>

</style>
