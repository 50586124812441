<template lang='pug'>
main-layout#page-360-evaluation(no-nav)

  template(v-if='isInternetExplorer')
    br
    error-message Internet Explorer is not currently supported - please use a different browser!
    br

  template(v-if='userEvaluationEnabled')
    template(v-if='!$route.query.token || getEvalRequest.isError')
      h2 Invalid evaluation link - please contact support
      error-message(:request-status='getEvalRequest')

    template(v-else-if='getEvalRequest.isPending')
      h2 Loading...

    template(v-else-if='(evaluationResponse && evaluationResponse.completedAt || demoComplete)')
      h2 Thank you!
      p Your feedback and input is extremely helpful.

    template(v-else-if='getEvalRequest.isSuccess && evaluationResponse')

      template(v-if='isDemo')
        br
        error-message(warning) THIS IS A DEMO - you can see and answer the questions but they will not actually be submitted

      template(v-if='role === "self"')
        h2 360 Self-Evaluation
        p This 360 assessment is designed to help you understand where your strengths and weaknesses are. Your own ratings will be compared to those of your colleagues to get a better understanding of where your perception may be different from those around you.

      template(v-else)
        h2 360 Feedback for {{ evaluation.execUser.firstName }} {{ evaluation.execUser.lastName }}
        h4 {{ evaluation.company.name }}

        p
          | This survey will be filled out by a group of colleagues including bosses, peers, direct reports, and your colleague themselves. It is designed to help them identify their strengths and weaknesses and to create a personalized development plan part of an executive coaching program with AdvisorEY. Please try to provide honest and constructive feedback and to use concrete examples whenever possible.  Your responses will be anonymous and combined with other raters of the same type (boss/peer/etc). It will not be shared with HR or used for performance reviews.

        p Completing this survey should take about 15 minutes and should ideally be done in one sitting.

        p
          b Thank you so much for your important feedback!

      form-group(
        v-if='questionsByCategory[slug]'
        v-for='category, slug in EVAL_CATEGORIES' :key='`category-${slug}`'
        :label='category.label'
      )

        form-row(
          v-for='question in questionsByCategory[slug]' :key='question.id'
        )

          //- Text questions
          form-input(
            v-if='question.type === "text"'
            v-model='answers[question.id]'
            type='textarea'
            :label='$options.filters.capitalize(question.prompt)'
            :required='!question.isOptional'
          )

          //- Likert questions (agreement scale)
          form-input.likert-input(
            v-else
            type='container'
            :value='answers[question.id]'
            :label='$options.filters.capitalize(question.prompt)'
            :required='!question.isOptional'
          )
            .likert-options
              label.question-response-option(
                v-for='index in [0,1,2,3,4,5,-1]'
                :key='`q-${question.id}-${index}`'
              )
                input.answer-input(
                  type='radio' :name='`question-${question.id}-response`' :value='index'
                  @change='(event) => answers[question.id] = (event.target.value).toString()'
                  :checked='index === answers[question.id]'
                )
                .answer-radio {{ index >= 0 ? index + 1 : ''}}
                .answer-label(v-if='index === 0') Disagree<br/>Strongly
                .answer-label(v-if='index === 5') Agree<br/>Strongly
                .answer-label(v-if='index === -1') N / A
      form-row
        error-message(:request-status='saveAnswersRequest')
        error-message(v-if='$vv.$allDirty && $vv.$error') Please answer all required questions above

        v-button(
          loading-text='Saving...'
          :request-status='saveAnswersRequest'
          @click='saveResponsesButtonHandler'
          :disabled='$vv.$error'
        ) Submit 360 Feedback
        error-message(:request-status='saveAnswersRequest')

      br
      br
  template(v-else)
    error-message 360 Evaluations are disabled for your account.

</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { replaceWord, chooseWords, REPLACE_EXP } from '@/app-public/common/other/evaluation-results-popup';

import { EVAL_CATEGORIES } from '@/data/eval-categories';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    if (this.evaluationResponse && this.evaluation.execUser) {
      return { title: `360 Evaluation for ${this.evaluation.execUser.firstName} ${this.evaluation.execUser.lastName}` };
    }
    return { title: '360 Evaluation' };
  },
  data() {
    return {
      answers: {},
      demoComplete: false,
    };
  },
  computed: {
    ...mapGetters('window', ['isInternetExplorer']),
    ...mapGetters('authUser/profile', ['userEvaluationEnabled']),
    ...mapGetters('authUser/evaluations', ['evaluationResponse', 'evaluation']),
    ...mapRequestStatuses({
      getEvalRequest: 'authUser/evaluations/GET_EVAL_RESPONSE',
      saveAnswersRequest: 'authUser/evaluations/SAVE_EVAL_ANSWERS',
    }),
    EVAL_CATEGORIES() { return EVAL_CATEGORIES; },
    role() {
      return this.evaluationResponse.respondentRole;
    },
    roleIndex() {
      return this.role === 'self' ? 1 : 0;
    },
    firstName() {
      return this.role === 'self' ? 'you' : this.evaluation.execUser.firstName;
    },
    pronoun() {
      return this.role === 'self' ? 'your' : 'their';
    },
    questions() {
      const questions = _.cloneDeep(this.evaluation.evaluationQuestions);
      const inOrder = _.orderBy(questions, ['order']);
      _.each(inOrder, (question) => {
        question.prompt = _.replace(question.prompt, /\[name\]/g, this.firstName);
        question.prompt = _.replace(question.prompt, /\[company\]/g, this.evaluation.company.name);
        question.prompt = chooseWords(question.prompt, this.roleIndex);
      });

      return inOrder;
    },
    isDemo() {
      return this.$route.query.token === 'demo';
    },
    questionsByCategory() {
      return _.groupBy(this.questions, 'category');
    },
  },
  async beforeMount() {
    const { token } = this.$route.query;
    if (!token) return;
    await this.$store.dispatchApiAction('authUser/evaluations/GET_EVAL_RESPONSE', { token });
    if (this.getEvalRequest.isSuccess) {
      this.answers = _.zipObject(_.map(this.evaluation.evaluationQuestions, 'id'));
    }
  },
  methods: {
    saveResponsesButtonHandler() {
      if (this.$hasError()) return;
      if (this.isDemo) {
        this.demoComplete = true;
        return;
      }

      const { token } = this.$route.query;
      this.$store.dispatchApiAction('authUser/evaluations/SAVE_EVAL_ANSWERS', {
        ...this.answers,
        token,
      });
    },
  },
};
</script>

<style lang='less'>

#page-360-evaluation{
  .form-group {
    margin-top: 30px;
  }

  .likert-input {
    .likert-options {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .question-response-option {
      display: block;
      flex: 1 0 0;
      text-align: center;
      cursor: pointer;
      padding: 10px;

      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }

      .answer-label {
        margin-top: 8px;
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
      }
      .answer-radio {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-top: 8px;
        background: white;
        border: 2px solid @navy;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        font-weight: normal;
        color: #BBB;
        position: relative;
        font-size: 13px;

        // radio fill
        &:after {
          content: '';
          display: block;
          position: absolute;
          background: @brand-gray-blue;
          top: 2px;
          bottom: 2px;
          right: 2px;
          left: 2px;
          border-radius: 50%;
          opacity: 0;
        }
      }

      input:checked ~ .answer-radio {
        &:after {
          opacity: 1;
        }
      }

      &:nth-child(1), &:nth-child(6) {
        .answer-radio {
          width: 40px;
          height: 40px;
          line-height: 40px;
          margin-top: 0px;
        }
      }

      &:nth-child(2), &:nth-child(5) {
        .answer-radio {
          width: 35px;
          height: 35px;
          line-height: 35px;
          margin-top: 4px;
        }
      }
      // N/A option
      &:nth-child(7) {
        border-left: 1px solid #DDD;
        margin-left: 10px;
        padding-left: 10px;
        .answer-radio {
          // background: #EEE;

        }
      }

      @media @mq-small-only{
        .answer-radio{
          transform: scale(0.7);
        }
        padding: 0 0 10px 0;
      }

      &:hover {
        .answer-radio:after {
          opacity: .3;
        }
      }
    }
  }
}

</style>
