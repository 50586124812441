<template lang='pug'>
.client-documents-tab
  h3 Overview
  template
    template(v-if='user.currentCoachId === coach.id')
      .potential-match-info
        icon(name='check-circle')
        | {{ user.firstName }} is your coaching client

    template(v-else-if='!user.currentCoachId')
      .potential-match-info
        icon(name='exclamation-circle')
        | {{ user.firstName }} is a new potential client - you have a {{ Math.round(coachingMatch.matchScore) }}% match

    template(v-else)
      | {{ user.firstName }} has decided to choose another coach

    h4 Upcoming Sessions
    div(v-if='getSelectedClientSessionsRequest.isPending') Loading sessions info...
    template(v-else-if='getSelectedClientSessionsRequest.isError')
      error-message(:request-status='getSelectedClientSessionsRequest')
    template(v-else)
      template(v-if='selectedClientUpcomingSessions.length')
        session-details(
          v-for='session in selectedClientUpcomingSessions' :key='session.id'
          :session='session'
          view-mode='coach'
        )
      template(v-else)
        p No upcoming sessions.
    
    template(v-if='coachCanScheduleSessions')
      h4 Schedule A session
      p {{ user.firstName }} has allowed you to schedule sessions on their behalf.
      v-button(@click='clickScheduleSession' size='small') Schedule a session

    selected-user-booking-popup(
      ref='bookingPopup'
      :coach='coach'
      session-type='coaching'
      :handleScheduleSuccess='handleClientScheduleSuccess'
    )
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'profile-photo': require('@/components/other/profile-photo').default,
  'session-details': require('@/app-public/common/other/session-details')
    .default,
  'selected-user-booking-popup': require('@/app-public/common/other/selected-user-booking-popup').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Client Documents',
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      coach: 'user',
      currentCoachId: 'userId',
    }),
    ...mapGetters('authUser/coaching', ['selectedClientUpcomingSessions']),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
    ...mapRequestStatuses({
      getSelectedClientSessionsRequest:
        'authUser/coaching/GET_SELECTED_CLIENT_SESSIONS',
    }),
    coachCanScheduleSessions() {
      return (this.user || {}).coachCanScheduleSessions || false;
    },
    coachingMatch() {
      const { coachingMatch = {} } = this.user;
      return coachingMatch;
    },
  },
  watch: {},
  methods: {
    clickScheduleSession(args) {
      this.$refs.bookingPopup.open(args);
    },
    handleClientScheduleSuccess() {
      this.$store.dispatchApiAction('authUser/coaching/GET_SELECTED_CLIENT_SESSIONS');
    },
  },
  beforeMount() {
    // Client sessions are loaded with the user.
  },
};
</script>

<style lang='less'>
</style>
