<template lang='pug'>
.matching-coaches

  template(v-if='userIsInDemoMode')
    error-message(warning) THIS IS A DEMO - actual matches require your full profile and LeaderMatch personality assessment results
    br

  //- user has a coach selected
  template(v-if='user.currentCoachId')
    template(v-if='getCoachRequest.isPending') Loading...
    template(v-else)
      h3 You have selected a coach!

      .coach-card.current-coach
        profile-photo(:user='userCurrentCoach' size='large')
        .coach-name {{ userCurrentCoach.fullName }}
        .tagline {{ userCurrentCoach.tagline }}

        v-button(
          :to='{name: "coach-profile", params: { coachSlug: userCurrentCoach.slug }}'
          theme='transparent-light'

        ) View {{ userCurrentCoach.firstName }}'s Profile

      change-coach-view

  //- user does not yet have a coach
  template(v-else-if='!userIsInDemoMode && (!onboardingProgress.assessment || !onboardingProgress.profile)')
    error-message You must complete your profile and assessment before we can match you with coaches!
  template(v-else-if='user.isCoachLocked')
    error-message Your account is restricted from matching. To match with a coach, please contact support.
  template(v-else)

    div(v-if='getMatchingCoachesRequest.isPending') loading...
    template(v-else)

      template(v-if='!userMatchingCoaches.length')
        h3 Let's match!
        p You don't have any matching coaches yet!
        p Are you ready to begin your coaching journey?
        v-button(
          @click='runMatchingButtonHandler'
          :request-status='triggerMatchingRequest' loading-text='Finding your ideal coach...'
          size='large'
        ) Find me the perfect coach
        error-message(:request-status='triggerMatchingRequest')

      template(v-else)
        h3 Matching Coaches
        p Here are your matching coaches! Please view their profiles, and set up meet and greet sessions with them to see if you want to work together. When you find the right coach, you can select them as your coach to begin your journey together!

        .coaches-cards-wrapper
          .coach-card(
            v-for='coach in userMatchingCoaches' :key='`coach-card-${coach.id}`'
            @click='$router.push({name: "coach-profile", params: { coachSlug: coach.slug }})'
          )
            profile-photo(:user='coach' size='large' :placard-text='`${Math.round(coach.coachingMatch.matchScore)}% match`')
            .coach-name {{ coach.fullName }}
            .tagline {{ coach.tagline }}

            v-button(
              :to='{name: "coach-profile", params: { coachSlug: coach.slug }}'
              theme='transparent-light'

            ) View {{ coach.firstName }}'s Profile
      
        .coach-iterviews-list(v-if='getSessionsRequest.isSuccess && interviewSessions.length')
          h4 Scheduled Interviews
          .coach-iterviews-list-entry(v-for='session in interviewSessions')
            session-details(
              :session='session'
              view-mode='self'
            )

</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'profile-photo': require('@/components/other/profile-photo').default,
  'session-details': require('@/app-public/common/other/session-details').default,
  'change-coach-view': require('@/app-public/pages/coach-profile/shared/change-coach-view').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('authUser/profile', [
      'userIsInDemoMode',
      'user',
      'userCurrentCoach',
      'userMatchingCoaches', 'onboardingProgress',
    ]),
    ...mapRequestStatuses({
      getMatchingCoachesRequest: 'authUser/profile/GET_USER_MATCHING_COACHES',
      getCoachRequest: 'authUser/profile/GET_USER_CURRENT_COACH',
      triggerMatchingRequest: 'authUser/profile/TRIGGER_USER_MATCHING',
      fireCoachRequest: 'authUser/profile/FIRE_USER_COACH',
      getSessionsRequest: 'authUser/booking/GET_USER_SESSIONS',
    }),
    interviewSessions() {
      return this.$store.getters['authUser/booking/getAllCoachInterviewSessions'];
    },
  },
  methods: {
    runMatchingButtonHandler() {
      this.$store.dispatchApiAction('authUser/profile/TRIGGER_USER_MATCHING');
    },
  },
  beforeMount() {
    if (this.user.currentCoachId) {
      this.$store.dispatchApiAction('authUser/profile/GET_USER_CURRENT_COACH');
    } else {
      this.$store.dispatchApiAction('authUser/profile/GET_USER_MATCHING_COACHES');
      this.$store.dispatchApiAction('authUser/booking/GET_USER_SESSIONS');
    }
  },
};
</script>

<style lang='less'>
.matching-coaches {
  .coaches-cards-wrapper {
    display: flex;
    place-content: center center;
    flex-wrap: wrap;
  }

  .change-coach-view {
    margin-top: 40px;
  }

  .coach-card {
    max-width: 350px;
    background: @navy;
    color: white;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
    cursor: pointer;
    &:hover {
      background: lighten(@navy, 5);
    }

    .coach-name {
      margin: 25px 0 10px;
      font-weight: 700;
      font-size: 24px;
    }
    .button {
      margin-top: 10px;
    }
    .tagline {
      font-style: italic;
      padding: 10px 0;
      flex: 1 0 0;
    }
  }
}
.coach-iterviews-list {
  margin-top: 10px;
}
</style>
