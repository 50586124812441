import _ from 'lodash';
import Vue from 'vue';
// import * as Sentry from '@sentry/browser';

import {
  buildApiActions,
} from '../utils/vuex-api-utils';

export default {
  namespaced: true,
  state() {
    return {
      enumsLoaded: false,
      // Default Enums
      contactTimes: {},
      companySizes: {},
      industries: {},
      languages: {},
      skills: {},
      countries: {},
      companyStatus: {
        new: 'New',
        demo: 'Demo',
        active: 'Active',
        paused: 'Paused',
        canceled: 'Cancelled',
      },
      companyAssessmentOptions: {
        leadereq: 'AdvisorEY',
        disabled: 'Disabled',
      },
      companySkillsOptions: {
        leadereq: 'AdvisorEY',
        custom: 'Custom',
      },
    };
  },
  getters: {
    enumsLoaded: (state) => state.enumsLoaded,
    contactTimeOptions: (state) => state.contactTimes,
    companySizeOptions: (state) => state.companySizes,
    industryOptions: (state) => state.industries,
    languageOptions: (state) => state.languages,
    emailSharingOptions: (state) => state.emailSharingOptions,
    skillOptions: (state) => state.skills,
    countryOptions: (state) => state.countries,
    companyStatusOptions: (state) => state.companyStatus,
    companySkillsOptions: (state) => state.companySkillsOptions,
    companyAssessmentOptions: (state) => state.companyAssessmentOptions,
  },
  ...buildApiActions({
    // single utility route to fetch a bunch of dropdown options
    GET_ENUMS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: '/enums',
      }),
      mutation: (state, {
        response,
      }) => {
        state.enumsLoaded = true;
        // has all the same keys as state
        _.assign(state, response);
      },
    },
  }, {
    actions: {
      loadEnums: async (ctx, userId) => {
        const {
          enumsLoaded,
        } = ctx.getters;

        if (!enumsLoaded) {
          await ctx.dispatch('api-GET_ENUMS');
        }
      },
    },
  }),
};
