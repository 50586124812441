<template lang='pug'>
.month-sessions-list
  .month-header
    h3 {{ headerLabel }}
    //- .credits(v-if='monthIndex === 0')
    //-   | Remaining credits:
    //-   |  {{ creditsBalance }}
    //-   =' '
    //-   icon(name='clock')
    //- icon.add-session-plus(name='plus')

  template(v-if='sessions.length')
    session-details(
      v-for='session in sessions'
      :key='`session-${session.id}`'
      :session='session'
      view-mode='self'
    )
  template(v-else-if='!showScheduleSession')
    span No sessions scheduled.

  //- Schedule Session Buttoon
  todo-section.add-session-todo(
    v-if='showScheduleSession'
    :disabled='disabled'
    title='Schedule a session'
    action-type='add'
    :click-action='bookButtonHandler'
  )
    template(slot='details')
      span(v-if='monthIndex === 0')
        span(v-if='creditsBalance < 0')
          | You have <b>0 credits</b> remaining and have used <b>{{-creditsBalance}} credit{{-creditsBalance !== 1 ? 's' : ''}}</b> from next month a little early
        span(v-else-if='creditsBalance === 0')
          | You have <b>0 credits remaining</b> for this month - you're right on target
        span(v-else)
          | You have <b>{{ creditsBalance }} credit{{ creditsBalance !== 1 ? 's' : '' }}</b> remaining for this month - unused credits will roll-over
        =' '
        router-link(:to='{name: "credits-faq"}')
          icon(name='question-circle')
      //- span(v-if='showMore') No need to book too far ahead, but you can if you like
      //- span(v-else-if='numSessions < 2') We recommend 2 sessions per month

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';

const components = {
  'session-details': require('@/app-public/common/other/session-details').default,
};

export default {
  components,
  props: {
    monthIndex: Number,
    showMore: Boolean,
    disabled: Boolean,
    showScheduleSession: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('authUser/booking', ['creditsBalance', 'userSessions']),
    monthStartDate() {
      const nowMonth = dateFns.startOfMonth(new Date());
      return dateFns.addMonths(nowMonth, this.monthIndex);
    },
    monthEndDate() {
      return dateFns.addMonths(this.monthStartDate, 1);
    },
    headerLabel() {
      if (this.monthIndex === 0) return 'This Month';
      if (this.monthIndex === 1) return 'Next Month';
      if (this.showMore) return 'Later';
      return dateFns.format(this.monthStartDate, "MMMM ''yy");
    },
    sessions() {
      return _.filter(this.userSessions, (s) => {
        const startDate = dateFns.parseISO(s.startAtUtc);
        if (startDate < this.monthStartDate) return false;
        if (!this.showMore && startDate >= this.monthEndDate) return false;
        return true;
      });
    },
    numSessions() {
      return _.filter(this.sessions, { cancelledAt: null }).length;
    },
    addSessionDetail() {
      if (this.showMore) return 'No need to book too far ahead';
      if (this.numSessions < 2) return 'We recommend 2 sessions per month';
      return '';
    },
  },
  beforeMount() {
  },
  methods: {
    bookButtonHandler() {
      this.$emit('book', dateFns.format(this.monthStartDate, 'yyyy-MM-dd'));
    },
  },
};
</script>

<style lang='less'>
.month-sessions-list {
  .month-header {
    h3 {
      font-size: 16px;
      text-transform: uppercase;
    }

    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 2px;
    display: flex;
    color: @navy;
  }

  .add-session-todo {
    color: white;
    background: @button-action-blue-color;
  }
}
</style>
