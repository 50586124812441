<template lang='pug'>
.user-profile-tab
  h3 Profile
  user-identity-input(v-model='user')
  online-presence-input(v-model='user')
  address-input(v-model='user.address')
  user-exec-preferences(v-if='user.isExecutive' v-model='user.executivePreferences' :skill-options='computedSkillOptions' :language-options='languageOptions')

  form-group(label='Company Notes')
    form-row
      form-input(type='text' v-model='user.department'
        label='Department'
        instructions='The department this user is in.'
      )
      form-input(v-if='isDifferentUser' type='textarea' v-model='user.companyNotes'
        label='Notes To Coach'
        instructions='These notes are only visible to the coach.'
      )

  save-bar(
    ref='saveBar'
    :draft-value='user' :store-value='storeUser'
    :update-request='updateUserRequest'
    :save-disabled='$vv.$error'
    @save='saveButtonHandler' @reset='resetToStoreState'
  )
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

const components = {
  'user-identity-input': require('@/components/forms/user-identity-input').default,
  'online-presence-input': require('@/components/forms/online-presence-input').default,
  'address-input': require('@/components/forms/address-input').default,
  'user-exec-preferences': require('@/components/forms/user-exec-preferences').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'User Company Profile',
    };
  },
  data() {
    return {
      user: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdminId: 'userId',
    }),
    ...mapGetters('selectedUser/profile', {
      userId: 'userId',
      storeUser: 'user',
    }),
    ...mapGetters('enums', [
      'skillOptions', 'industryOptions', 'languageOptions', 'companySizeOptions', 'countryOptions',
    ]),
    ...mapRequestStatuses({
      updateUserRequest: 'selectedUser/profile/UPDATE_USER',
    }),
    computedSkillOptions() {
      return buildSkillOptions(this.skillOptions, this.user.company);
    },
    isDifferentUser() {
      return this.userId !== this.currentAdminId;
    },
  },
  watch: {
    storeUser: {
      deep: true,
      handler() { this.resetToStoreState(); },
    },
  },
  mounted() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.user = _.cloneDeep(this.storeUser);
    },
    async saveButtonHandler() {
      if (this.$hasError()) {
        return;
      }

      await this.$store.dispatchApiAction('selectedUser/profile/UPDATE_USER', this.user);
    },
  },
  beforeRouteLeave(to, from, next) {
    return next(this.$refs.saveBar.warnAboutUnsavedChanges());
  },
};
</script>

<style lang='less'>
.user-profile-tab {
}
</style>
