<template lang='pug'>
span.icon-content-wrap(:class='(bubbled) ? "bubbled-icon" : null')
  font-awesome-icon.icon(
    :name='name'
    :spin='name === "spinner"'
    :scale='scale'
  )
</template>

<script>
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/plus-circle';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/times-circle';
import 'vue-awesome/icons/trash';
import 'vue-awesome/icons/spinner';
import 'vue-awesome/icons/sync-alt';
import 'vue-awesome/icons/lock';
import 'vue-awesome/icons/unlock';
import 'vue-awesome/icons/link';
import 'vue-awesome/icons/external-link-square-alt';
import 'vue-awesome/icons/external-link-alt';
import 'vue-awesome/icons/envelope';
import 'vue-awesome/icons/phone';
import 'vue-awesome/icons/video';
import 'vue-awesome/icons/edit';
import 'vue-awesome/icons/info-circle';
import 'vue-awesome/icons/exclamation-circle';
import 'vue-awesome/icons/question-circle';
import 'vue-awesome/icons/address-card';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/check-circle';
import 'vue-awesome/icons/circle';
import 'vue-awesome/icons/square';
import 'vue-awesome/icons/minus';
import 'vue-awesome/icons/minus-circle';
import 'vue-awesome/icons/copy';
import 'vue-awesome/icons/wrench';
import 'vue-awesome/icons/pause-circle';
import 'vue-awesome/icons/play-circle';
import 'vue-awesome/icons/users';
import 'vue-awesome/icons/users-cog';
import 'vue-awesome/icons/user-friends';
import 'vue-awesome/icons/portrait';
import 'vue-awesome/icons/map-marker';
import 'vue-awesome/icons/map-marker-alt';
import 'vue-awesome/icons/exclamation-triangle';
import 'vue-awesome/icons/paper-plane';
import 'vue-awesome/icons/clipboard-check';
import 'vue-awesome/icons/stopwatch';
import 'vue-awesome/icons/hourglass';
import 'vue-awesome/icons/coins';
import 'vue-awesome/icons/building';

import 'vue-awesome/icons/file-upload';

import 'vue-awesome/icons/university';
import 'vue-awesome/icons/industry';
import 'vue-awesome/icons/business-time';
import 'vue-awesome/icons/award';
import 'vue-awesome/icons/graduation-cap';

import 'vue-awesome/icons/user';
import 'vue-awesome/icons/user-plus';
import 'vue-awesome/icons/user-tie';

import 'vue-awesome/icons/search';
import 'vue-awesome/icons/clock';

import 'vue-awesome/icons/briefcase';
import 'vue-awesome/icons/handshake';

import 'vue-awesome/icons/arrow-down';
import 'vue-awesome/icons/arrow-up';
import 'vue-awesome/icons/arrow-right';
import 'vue-awesome/icons/arrow-left';
import 'vue-awesome/icons/angle-down';
import 'vue-awesome/icons/angle-up';
import 'vue-awesome/icons/angle-right';
import 'vue-awesome/icons/angle-left';

import 'vue-awesome/icons/calendar-check';
import 'vue-awesome/icons/calendar-times';
import 'vue-awesome/icons/calendar-plus';
import 'vue-awesome/icons/regular/calendar-plus';

import 'vue-awesome/icons/comment';
import 'vue-awesome/icons/comment-slash';
import 'vue-awesome/icons/comments';

import 'vue-awesome/icons/toggle-on';
import 'vue-awesome/icons/toggle-off';

import 'vue-awesome/icons/brands/twitter';
import 'vue-awesome/icons/brands/linkedin-in';
import 'vue-awesome/icons/brands/facebook-f';
import 'vue-awesome/icons/brands/medium-m';
import 'vue-awesome/icons/brands/instagram';
import 'vue-awesome/icons/brands/google';

const FontAwesomeIcon = require('vue-awesome/components/Icon').default;

// Register some custom icons
FontAwesomeIcon.register({
  'google-color-g': {
    // https://commons.wikimedia.org/wiki/File:Google_%22G%22_Logo.svg
    width: 48,
    height: 48,
    raw:
      '<defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>',
  },
});

export default {
  components: { FontAwesomeIcon },
  props: {
    name: { type: String },
    scale: { type: String },
    bubbled: Boolean,
    title: String,
  },
};
</script>

<style lang='less'>
.icon-content-wrap {
  line-height: 0;
  
  .icon {
    display: inline-block;
  }

  &.bubbled-icon {
    display: inline-flex;
    align-content: center;
    color: @gray-blue;
    padding: 5px;
    background: white;
    border-radius: 50%;
  }
}

.link-with-icon {
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  > span {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
