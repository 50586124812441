import _ from 'lodash';
import Vue from 'vue';
import * as dateFns from 'date-fns';

import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  namespaced: true,
  state() {
    return {
      // summary of all clients + matches with next/last session info
      clientsList: null,

      /*
      NOTE: These are all accessable through the new vuex-store state under selectedUser.

      // select a single client to show details
      selectedClient: {},
      selectedClientSessions: null,
      selectedClientAssessment: null,
      selectedClientEvaluations: null,
      */
      sessions: {},
    };
  },
  getters: {
    userId: (s, g, rs, rg) => rg[`${s._moduleParent}profile/userId`],

    clientsList: (state) => _.values(state.clientsList),
    sessions: (state) => _.values(state.sessions),

    selectedClientId: (s, g, rs, rg) => rg['selectedUser/profile/userId'],
    selectedClient: (s, g, rs, rg) => rg['selectedUser/profile/selectedUser'],
    selectedClientCompany: (state) => _.get(state, 'selectedClient.company', {}),

    selectedClientAssessment: (state) => state.selectedClientAssessment,
    selectedClientEvaluations: (state) => state.selectedClientEvaluations,

    selectedClientUpcomingSessions: (state, getters) => _.filter(getters.upcomingSessions, { execUserId: getters.selectedClientId }),
    // currentClients: (state) => _.values(state.currentClients),
    // potentialClients: (state) => _.values(state.potentialClients),
    upcomingSessions: (state, getters) => {
      const sessions = _.filter(getters.sessions, (s) => dateFns.parseISO(s.endAtUtc) > new Date());
      return _.sortBy(sessions, 'startAtUtc');
    },
  },
  ...buildApiActions({
    GET_COACH_CLIENTS_LIST: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/client-list`,
      }),
      mutation: (state, { response }) => {
        state.clientsList = _.keyBy(response, 'id');
      },
    },
    GET_SELECTED_CLIENT: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.selectedClientId}`,
      }),
      mutation: (state, { response }) => {
        state.selectedClient = response.executive;
        state.selectedClientAssessment = response.assessment;
        state.selectedClientEvaluations = response.evaluations;
        state.selectedClientDocuments = response.documents;
      },
    },
    GET_COACH_SESSIONS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/sessions`,
        params: payload,
      }),
      mutation: (state, { response }) => {
        state.sessions = _.keyBy(response.sessions, 'id');
      },
    },

    /**
     * Returns the list of sessions for the specified client.
     */
    GET_SELECTED_CLIENT_SESSIONS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/sessions`,
        params: {
          // Filter by the exec user...
          execUserId: ctx.getters.selectedClientId,
          ...payload,
        },
      }),
      mutation: (state, { response }) => {
        _.each(response.sessions, (s) => Vue.set(state.sessions, s.id, s));
      },
    },
    /**
     * Cancels a session.
     */
    CANCEL_SESSION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/sessions/${payload.sessionId}/cancel`,
        params: _.omit('payload', 'sessionId'),
        keyRequestStatusBy: payload.sessionId,
      }),
      mutation: (state, { response }) => {
        state.sessions[response.id] = response;
      },
    },
  }, {
    actions: {
      setSelectedClientId: async (ctx, payload) => {
        await ctx.commit('SET_SELECTED_CLIENT_ID', payload.userId);
        if (payload.userId) {
          ctx.dispatch('api-GET_SELECTED_CLIENT');
          ctx.dispatch('api-GET_SELECTED_CLIENT_SESSIONS');
        }
      },
    },
    mutations: {
      SET_SELECTED_CLIENT_ID: (state, clientUserId) => {
        state.selectedClient = { id: clientUserId };
      },
    },
  }),
};
