<template lang='pug'>
.resume-list
  .resume-list__empty(v-if='!items || !items.length') Nothing listed
  .resume-list__item(
    v-for='item, index in items' :key='`resume-${type}-${index}`'
  )
    icon.resume-list__icon(:name='iconName')
    div
      .resume-list__title
        span.resume-list__location {{ item.company || item.school || item.organization }}
        span.resume-list__activity  - {{ item.jobTitle || item.degree || item.title }}
        span.resume-list__years(v-if='item.years')  ({{ item.years }})
      .resume-list__description {{ item.description }}
</template>

<script>

const components = {
};

export default {
  components,
  props: {
    items: Array,
    type: String,
  },
  data() {
    return {
    };
  },
  computed: {
    iconName() {
      return {
        career: 'business-time',
        education: 'university',
        accolade: 'award',
      }[this.type];
    },
  },
  methods: {
  },
};
</script>

<style lang='less'>
.resume-list {
  .resume-list__icon {
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-right: 8px;
    flex: 30px 0 0;
  }
  .resume-list__empty {
    font-style: italic;
    opacity: .5;
  }
  .resume-list__item {
    display: flex;
    padding: 5px 0;
  }
  .resume-list__location {
    font-weight: 700;
    // margin-right: 15px;
  }
  .resume-list__activity, .resume-list__years  {
    // margin-right: 10px;
  }
  .resume-list__description {
    font-style: italic;
    font-size: 13px;
    line-height: 1.3em;
    opacity: .8;
  }

}
</style>
