import _ from 'lodash';
import Vue from 'vue';

export default {
  namespaced: true,
  state() {
    return {
      documentHidden: null,
      windowVisibilityState: null,
      windowHasFocus: null,

      isMobileNavOpen: false,
    };
  },
  getters: {
    documentHidden: (state) => state.documentHidden,
    windowVisibilityState: (state) => state.windowVisibilityState,
    windowHasFocus: (state) => state.windowHasFocus,

    windowVisibleAndFocused: (state) => !state.documentHidden && state.windowHasFocus,

    isMobileNavOpen: (state) => state.isMobileNavOpen,

    isInternetExplorer: () => {
      const ua = window.navigator.userAgent;
      if (ua.indexOf('MSIE ') > -1) return true;
      return false;
    },
  },
  actions: {
    init(ctx) {
      window.addEventListener('focus', () => ctx.dispatch('update', 'focus'));
      window.addEventListener('blur', () => ctx.dispatch('update', 'blur'));
      document.addEventListener('visibilitychange', () => ctx.dispatch('update', 'vizchange'));
      ctx.dispatch('update');
    },
    update(ctx, event) {
      ctx.commit('UPDATE_WINDOW_VISIBILITY', {
        documentHidden: document.hidden,
        windowVisibilityState: document.visibilityState,
        windowHasFocus: document.hasFocus(),
      });
    },

    setMobileNavOpen(ctx, isOpen) {
      ctx.commit('SET_MOBILE_NAV_OPEN', isOpen);
    },
    toggleNavOpen(ctx) {
      ctx.commit('SET_MOBILE_NAV_OPEN', !ctx.state.isMobileNavOpen);
    },
  },
  mutations: {
    UPDATE_WINDOW_VISIBILITY: (state, newValues) => {
      _.assign(state, newValues);
    },
    SET_MOBILE_NAV_OPEN(ctx, isOpen) {
      ctx.isMobileNavOpen = isOpen;
    },
  },
};
