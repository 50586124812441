<template lang='pug'>
main-layout#page-executive-progress(no-footer)
  back-heading-button(toRoute="executive-dashboard" text="Back To Dashboard")
  .page-content
    template(v-if='user.isExecutive')
      template(v-if='getQuestionnairesRequest.isPending')
        h3 Loading questionnaires...
      template(v-else-if='getQuestionnairesRequest.isError')
        error-message(:request-status='getQuestionnairesRequest')
      template(v-else-if='getQuestionnairesRequest.isSuccess')
        router-view
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Executive Progress',
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('authUser/profile', {
      user: 'user',
    }),
    ...mapRequestStatuses({
      getQuestionnairesRequest: 'authUser/questionnaire/LOAD_QUESTIONNAIRES',
    }),
  },
  watch: {},
  beforeMount() {
    let redirect;

    // redirects
    if (!this.user.isExecutive) {
      redirect = 'welcome';
    }

    if (redirect) {
      this.$router.replace({ name: redirect });
    } else if (!this.getQuestionnairesRequest.success) {
      this.$store.dispatch('authUser/questionnaire/loadQuestionnaires');
    }
  },
};
</script>

<style lang='less'>
#page-executive-progress {
  .page-content {
    padding: 15px;
  }
}
</style>
