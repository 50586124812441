<template lang='pug'>
.questionnaire-selection
  template(v-if='computedTopics')
    .selection-content
      .selection(v-for='topic in computedTopics')
        span {{ topic }}
    .selection-message(v-if='computedNote')
      p "{{ computedNote }}"
  template(v-else)
    .no-selection Selection Unavailable
</template>

<script>
import _ from 'lodash';

const components = {};

export default {
  components,
  props: {
    questionnaire: { type: Object },
    selection: { type: Object },
  },
  computed: {
    computedSelection() {
      const { selection = this.selection } = this.questionnaire || {};
      return selection;
    },
    computedTopics() {
      const { topics } = this.computedSelection;
      return topics;
    },
    computedNote() {
      const { note } = this.computedSelection;
      return note;
    },
  },
};
</script>
<style lang='less'>
.questionnaire-selection {
  
  .selection-message {
    font-size: 0.9em;
  }

  .selection {
    font-size: 1.4em;
    line-height: 1.7em;
  }
}

.notification-square {
  .questionnaire-selection {
    padding: 0 30px;
  }
}
</style>