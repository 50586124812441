<template lang='pug'>
#page-company-overview
  h2 Company Dashboard
  subscription-status
  v-button(:to="{ name: 'company-users-dashboard' }") View Company Users
  v-button(:to="{ name: 'company-profile' } ") Edit Company Profile
  
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {
  'subscription-status': require('../shared/subscription-status').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      addUserPayload: {},
    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      currentAdmin: 'user',
    }),
    ...mapGetters('companies', {
      company: 'selectedCompany',
    }),
  },
  beforeMount() {
    this.$store.dispatch('companies/getCompanyUsers');
  },
  methods: {},
};
</script>

<style lang='less'>
</style>
