<template lang='pug'>
.client-documents-tab
  h3 Documents
  form-group(label='Documents')
    form-row(no-inputs)
      template(v-if='getDocumentsRequest.isPending')
        p loading...
      template(v-else-if='getDocumentsRequest.isError')
        error-message(:request-status='getDocumentsRequest')
      template(v-else)
        document-list(:current-user-id='authUserId'
        :handle-delete-document='handleDeleteDocument'
        :delete-document-request='deleteDocumentRequest'
        :handle-edit-document='handleEditDocument'
        :documents='documents')
        document-upload(:handle-upload='handleUploadFile')

</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
  'document-list': require('@/components/other/document-list').default,
  'document-upload': require('@/components/other/document-upload').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Client Documents',
    };
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'companySizeOptions',
    ]),
    ...mapGetters('auth', ['authUserId']),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
    ...mapGetters('selectedUser/documents', [
      'documents',
      'hasDocumentsLoaded',
    ]),
    ...mapRequestStatuses({
      getEnumsRequest: 'enums/GET_ENUMS',
      getDocumentsRequest: 'selectedUser/documents/LOAD_DOCUMENTS',
      uploadDocumentRequest: 'selectedUser/documents/UPLOAD_DOCUMENT',
      deleteDocumentRequest: 'selectedUser/documents/DELETE_DOCUMENT',
    }),
  },
  watch: {},
  methods: {
    async handleDeleteDocument(document) {
      return this.$store.dispatch(
        'selectedUser/documents/deleteDocument',
        document.id,
      );
    },
    async handleEditDocument() {
      // TODO: Add Edit Document later if necessary.
    },
    async handleUploadFile(file) {
      return this.$store.dispatch('selectedUser/documents/uploadFile', file);
    },
  },
  beforeMount() {
    this.$store.dispatch('enums/loadEnums');
    this.$store.dispatch('selectedUser/documents/loadDocuments');
  },
};
</script>

<style lang='less'>
</style>
