<template lang='pug'>
main-layout#page-coach-profile(full-width no-footer)
  template(v-if='getCoachRequest.isPending || getEnumsRequest.isPending') 
    p Loading...
  template(v-else-if='getCoachRequest.isError')
    p Coach not found
  template(v-else)
    auth-user-booking-popup(
      ref='bookingPopup'
      :coach='coach' session-type='interview'
    )

    page-with-chatbox(:user='user' :coach='coach')
      template(v-slot:link)
        router-link.icon-link(v-if='isMyCoach' :to='{name: "executive-dashboard"}')
          icon(name='angle-left')
          span.icon-link-text Dashboard
        router-link.icon-link(v-else :to='{name: "coach-matches"}')
          icon(name='angle-left')
          span.icon-link-text All Matches
      template(v-slot)
        coach-info(:coach='coach')
          template(v-slot:actions)
            template(v-if='!isMyCoach')
              template(v-if='!interviewSession')
                b What's next?
                br
                | Do you think {{ coach.firstName }} seems like a good coach for you?
                br
                v-button(
                  @click='$refs.bookingPopup.open()'
                  :disabled='coach.coachPausedAt'
                ) Book an interview
              template(v-else-if='interviewSession.startAtUtc > new Date().toISOString()')
                b What's next?
                br
                | After your call with {{ coach.firstName }}, you can decide if you'd like to select them as your coach.
              template(v-else)
                b How did the interview go?
                br
                | If you liked {{ coach.firstName }}, you can select them as your coach.
                br
                v-button(@click='selectCoachButtonHandler') Select {{ coach.firstName }} as your coach
          template(v-slot:sessions)
            session-details(
              v-if='interviewSession && interviewSession.startAtUtc > new Date().toISOString()'
              :session='interviewSession'
              view-mode='self'
            )
            error-message(
              v-if='coach.coachPausedAt && !isMyCoach'
            ) {{coach.firstName}} is not accepting new clients right now.
          template(v-slot)
            form-row
              form-input(type='container')
                change-coach-view(v-if='isMyCoach')

    popup.select-coach-popup(
      ref='selectCoachPopup'
      title='Are you sure?'
    )
      form-row(no-inputs)
        .profile-pics
          profile-photo(:user='coach' size='medium')
          icon(name='handshake' scale='5')
          profile-photo(:user='user' size='medium')
      form-row(no-inputs)
        p Fantastic! We're happy you have found a coach that you like.
        p.small.italic
          | You will be able to change your coach later if needed.
      form-row(slot='bottom')
        v-button(
          @click='confirmSelectButtonHandler'
          :request-status='selectCoachRequest' loading-text='Saving...'
        ) Yes - Select {{ coach.firstName }} to be my coach

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'page-with-chatbox': require('@/app-public/common/layout/page-with-chatbox')
    .default,
  'auth-user-booking-popup': require('@/app-public/common/other/auth-user-booking-popup').default,
  'session-details': require('@/app-public/common/other/session-details')
    .default,
  'coach-info': require('@/app-public/common/other/coach-info').default,
  'change-coach-view': require('@/app-public/pages/coach-profile/shared/change-coach-view')
    .default,
};

export default {
  components,
  metaInfo() {
    return {
      title: `Coach Profile - ${_.get(this.coach, 'fullName', this.coachSlug)}`,
    };
  },
  props: {
    coachSlug: { type: String, required: false },
  },
  data() {
    return {
      showChat: false,
    };
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'companySizeOptions',
    ]),
    ...mapGetters('authUser/profile', ['user']),
    ...mapRequestStatuses({
      getCoachRequest() {
        return ['authUser/profile/GET_COACH_BY_SLUG', this.coachSlug];
      },
      getCurrentCoachRequest: 'authUser/profile/GET_USER_CURRENT_COACH',
      selectCoachRequest: 'authUser/profile/SELECT_USER_COACH',
      getEnumsRequest: 'enums/GET_ENUMS',
    }),
    getTargetCoachRequest() {
      return this.coachSlug
        ? this.getCoachRequest
        : this.getCurrentCoachRequest;
    },
    coach() {
      let coach;

      if (this.coachSlug) {
        coach = this.$store.getters['authUser/profile/getCoachProfileBySlug'](
          this.coachSlug,
        );
      } else {
        coach = this.$store.getters['authUser/profile/userCurrentCoach'];
      }

      return coach;
    },
    isMyCoach() {
      return this.coach.id === this.user.currentCoachId;
    },
    interviewSession() {
      if (!this.coach) {
        return [];
      }

      return this.$store.getters['authUser/booking/getInterviewWithCoach'](
        this.coach.id,
      );
    },
  },
  watch: {},
  beforeMount() {
    this.$store.dispatch('enums/loadEnums');

    if (this.coachSlug) {
      // Use the slug.
      this.$store.dispatchApiAction('authUser/profile/GET_COACH_BY_SLUG', {
        slug: this.coachSlug,
      });
    } else {
      // Use the current user's coach.
      this.$store.dispatch('authUser/profile/loadCurrentUserCoach');
    }

    this.$store.dispatchApiAction('authUser/booking/GET_USER_SESSIONS');
  },
  methods: {
    selectCoachButtonHandler() {
      this.$refs.selectCoachPopup.open();
    },
    async confirmSelectButtonHandler() {
      await this.$store.dispatchApiAction(
        'authUser/profile/SELECT_USER_COACH',
        { coachId: this.coach.id },
      );
      if (this.selectCoachRequest.isSuccess) {
        this.$refs.selectCoachPopup.close();
        this.$router.push({ name: 'executive-dashboard' });
      }
    },
    toggleChatVisiblity() {
      this.showChat = !this.showChat;
    },
  },
};
</script>

<style lang='less'>
#page-coach-profile {
  .select-coach-popup {
    .profile-pics {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        margin: 0 10px;
        color: @brand-gray-blue;
      }
    }
  }
}
</style>
