/* eslint-disable import/first */
import Vue from 'vue';
import { polyfillLoader } from 'polyfill-io-feature-detection';
import '@/utils/init-error-tracking';

// https://hackernoon.com/polyfills-everything-you-ever-wanted-to-know-or-maybe-a-bit-less-7c8de164e423
// https://blog.josequinto.com/2017/01/20/getting-react-to-load-polyfills-only-when-needed/
polyfillLoader({
  features: 'Promise,Array.prototype.includes',
  onCompleted: async () => {
    require('@/utils/vue-setup');

    // use require so it can be done in this callback
    const api = require('@/utils/api').default;
    const App = require('./app').default;
    const store = require('./vuex-store').default;
    const router = require('./router').default;

    // This needs to run immediately - before the router handles the first route
    await store.dispatch('bindModuleNamespaces', store); // must go first!
    await store.dispatch('auth/initAuthFromStorage');
    await store.dispatch('window/init');

    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      store,
      router,
      components: { App },
      template: '<App/>',
    });
  },
});
