<template lang='pug'>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  mixins: [],
  computed: {
    ...mapGetters('authUser/questionnaire', ['questionnaires']),
  },
  methods: {},
  beforeMount() {
    const { questionnaires = [] } = this;
    const first = questionnaires[0];
    if (first) {
      this.$router.replace({ name: 'executive-progress-questionnaire', params: { questionnaireId: first.id } });
    } else {
      this.$router.replace({ name: 'executive-progress-overview' });
    }
  },
};
</script>

<style lang='less'>
</style>
