<template lang='pug'>
.client-profile-tab
  h3 Profile
  form-group(:label='`About ${user.firstName}`')
    form-row
      form-input(type='container' no-label)
        profile-photo(:user='user' :size='70')
        .executive-name {{ user.fullName }}
        .executive-job-title {{ user.jobTitle }}
        .executive-email(v-if='user.email')
          a(:href='"mailto:" + user.email') {{ user.email }}
      form-input(type='container' label='Personal Links' )
        social-links-widget(:user='user' theme='dark')
    form-row
      form-input(type='container' label='Company Name')
        | {{ company.name }}
        br
        span.small.italic {{ industryOptions[company.industry] }},  {{ companySizeOptions[company.size] }}
      form-input(type='container' label='Company Links' )
        social-links-widget(:company='company' theme='dark')

    form-row(v-if='user.bio')
      form-input(type='container' label='Bio')
        markdown(:source='user.bio')
    form-row
      form-input(type='container' label='Career History')
        resume-list(:items='user.careerHistory' type='career')
    form-row
      form-input(type='container' label='Education History')
        resume-list(:items='user.educationHistory' type='education')
    form-row
      form-input(type='container' label='Certifications & Awards')
        resume-list(:items='user.accoladeHistory' type='accolade')
    form-group(v-if='completedEvaluations.length' label='360 Evaluations')
      form-row
        form-input(
          type='dropdown'
          label='Date'
          v-model='selectedEvaluation'
          auto-select
        )
          form-input-option(
            v-for='evaluation in completedEvaluations'
            :value='evaluation' :key='evaluation.id'
          ) {{evaluation.date}}
        v-button.smaller(
          @click='$refs.viewEvaluationResultsPopup.open(selectedEvaluation.id);'
          size="small"
          ) See results
    form-row
      form-input(type='container' label='Notes From Company')
        p {{ user.companyNotes }}

  evaluation-results-popup(
    ref='viewEvaluationResultsPopup'
    view-mode='coach'
  )

  form-group(label='Preferences, goals, experience')
    form-row
      form-input(type='container' label='Desired skills to work on')
        .skills.tag-list
          ul
            li(v-for='skill in executivePreferenceSkills')
              icon(name='graduation-cap')
              | {{ skill }}
      form-input(type='container' label='Preferred meeting medium')
        template(v-if='executivePreferences.inPersonOrRemote === "in_person"') In person
        template(v-else-if='executivePreferences.inPersonOrRemote === "remote"') Remote
        template(v-else) No preference
    form-row
      form-input(type='container' label='Prior experience with coaching?')
        template(v-if='executivePreferences.hasPreviousCoachingExperience')
          | Yes - {{ executivePreferences.previousCoachingExperienceNotes }}
        template(v-else) No prior experience

  form-group(label='LeaderMatch Assessment')
    form-row(no-inputs)
      template(v-if='getAssessmentRequest.isPending')
        p loading...
      template(v-else-if='getAssessmentRequest.isError')
        error-message(:request-status='getAssessmentRequest')
      template(v-else)
        template(v-if='user.assessmentCompletedAt')
          assessment-results(:assessment='assessment')
        template(v-else)
          p This user has not completed their assessment.

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';

const components = {
  'profile-photo': require('@/components/other/profile-photo').default,
  'social-links-widget': require('@/app-public/common/other/social-links-widget')
    .default,
  'resume-list': require('@/app-public/common/other/resume-list').default,
  'assessment-results': require('@/components/other/assessment-results')
    .default,
  'document-list': require('@/components/other/document-list').default,
  'document-upload': require('@/components/other/document-upload').default,
  'evaluation-results-popup': require('@/app-public/common/other/evaluation-results-popup')
    .default,
  'questionnaire-selection': require('@/components/other/questionnaire-selection')
    .default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo() {
    return {
      title: 'Client Profile',
    };
  },
  data() {
    return {
      selectedEvaluation: null,
    };
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'companySizeOptions',
    ]),
    ...mapGetters('auth', ['authUserId']),
    ...mapGetters('selectedUser/profile', ['user', 'company']),
    ...mapGetters('selectedUser/assessment', ['assessment']),
    ...mapGetters('selectedUser/documents', [
      'documents',
      'hasDocumentsLoaded',
    ]),
    ...mapGetters('selectedUser/evaluations', {
      evaluations: 'userEvaluations',
    }),
    ...mapGetters('selectedUser/questionnaire', {
      questionnaire: 'latestCompletedQuestionnaire',
    }),
    ...mapRequestStatuses({
      getEnumsRequest: 'enums/GET_ENUMS',
      getDocumentsRequest: 'selectedUser/documents/LOAD_DOCUMENTS',
      uploadDocumentRequest: 'selectedUser/documents/UPLOAD_DOCUMENT',
      deleteDocumentRequest: 'selectedUser/documents/DELETE_DOCUMENT',
      getAssessmentRequest: 'selectedUser/assessment/GET_ASSESSMENT_DETAILS',
      getQuestionnaireRequest: 'selectedUser/questionnaire/LOAD_QUESTIONNAIRES',
    }),
    executivePreferences() {
      const { executivePreferences = {} } = this.user;
      return executivePreferences;
    },
    completedEvaluations() {
      return _.filter(this.evaluations, (evaluation) => evaluation.completedAt);
    },
    computedSkillOptions() {
      return buildSkillOptions(this.skillOptions, this.user.company);
    },
    executivePreferenceSkills() {
      const skillOptionsMap = this.computedSkillOptions;
      const skills = (this.executivePreferences.skills || [])
        .map((x) => skillOptionsMap[x])
        .filter((x) => Boolean(x));
      return skills;
    },
  },
  watch: {},
  beforeMount() {
    this.$store.dispatch('enums/loadEnums');
    this.$store.dispatch('selectedUser/assessment/loadAssessment');
  },
};
</script>

<style lang='less'>
.client-profile-tab {
  .profile-photo {
    float: left;
    margin-right: 15px;
  }
  .executive-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.4em;
  }
  .smaller {
    transform: scale(0.9);
  }
}
</style>
