<template lang='pug'>
//- TODO: v-if='value' is stopping some errors when the address being passed in is undefined
//- need to fix this and how we bind here...

//- TODO make required and requiredWarning work with a single error
//- message for the whole block

form-group.schedule-editor(:label='label' v-if='value')
  form-row(no-inputs)
    .schedule-editor__header
      .schedule-editor__header-start Start
      .schedule-editor__header-break Break
      .schedule-editor__header-end End
    .schedule-editor__day(v-for='day in DAYS' :key='`days-inputs-${day.key}`')
      .schedule-editor__day-label {{ day.label.substr(0,3) }}
      .schedule-editor__time-inputs
        form-input(
          v-model='schedule[day.key].start' type='dropdown'
          :options='dropdownOptions[day.key].start'
          no-label
        )
        form-input.schedule-editor__break-start-input(
          v-if='dropdownOptions[day.key].breakStart'
          v-model='schedule[day.key].breakStart' type='dropdown'
          :options='dropdownOptions[day.key].breakStart'
          no-label
        )

        template(v-if='dropdownOptions[day.key].breakEnd')
          .schedule-editor__break-label -
          form-input.schedule-editor__break-end-input(
            v-model='schedule[day.key].breakEnd' type='dropdown'
            :options='dropdownOptions[day.key].breakEnd'
            :required='!!schedule[day.key].breakStart'
            no-label
          )
        form-input(
          v-if='dropdownOptions[day.key].end'
          v-model='schedule[day.key].end' type='dropdown'
          :options='dropdownOptions[day.key].end'
          no-label
          :required='!!schedule[day.key].start'
        )


</template>


<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';
import * as moment from 'moment';

// TODO: fix v-model handling
// https://github.com/vuejs/vue/issues/4373
// technically we shouldnt be changing the passed in object directly
// but keeping a local copy and calling `$emit('input')` on changes


const slotOptions = [];
let d = moment('2019-01-01 05:00').toDate();

while (d.getDate() === 1) {
  slotOptions.push({ value: dateFns.format(d, 'HH:mm'), label: dateFns.format(d, 'h:mma') });
  d = dateFns.addMinutes(d, 30);
}

const DEFAULT_SCHEDULE = _.times(7, (i) => ({
  start: i === 0 || i === 6 ? null : '09:00',
  end: i === 0 || i === 6 ? null : '17:00',
  breakStart: null,
  breakEnd: null,
}));

export default {
  props: {
    label: { type: String, default: 'Weekly Schedule' },
    value: {
      type: Array, // passed in via v-model
      default: () => [],
    },
  },
  data() {
    // default to 9-5 schedule if it's blank
    if (!this.value || !this.value.length) {
      // initial input event is not fired for this change since we are watching this.schedule
      // for changes, and it is compared to this initial value... so we fire it manually
      setTimeout(() => { this.$emit('input', this.schedule); }, 100);
      return { schedule: _.cloneDeep(DEFAULT_SCHEDULE) };
    }
    // return a new object just to make sure all keys are set correctly
    return {
      schedule: _.times(7, (i) => ({
        start: this.value[i].start,
        breakStart: this.value[i].breakStart,
        breakEnd: this.value[i].breakEnd,
        end: this.value[i].end,
      })),
    };
  },
  computed: {
    DAYS() {
      return [
        { label: 'Monday', key: 1 },
        { label: 'Tuesday', key: 2 },
        { label: 'Wednesday', key: 3 },
        { label: 'Thursday', key: 4 },
        { label: 'Friday', key: 5 },
        { label: 'Saturday', key: 6 },
        { label: 'Sunday', key: 0 },
      ];
    },
    dropdownOptions() {
      const options = {};
      _.each(this.DAYS, ({ key }) => {
        const dayKey = key;
        options[dayKey] = {
          start: [{ value: null, label: '- no availability -' }, ...slotOptions],
          ...this.schedule[dayKey].start && {
            breakStart: [
              { value: null, label: '- no break -' },
              ..._.filter(slotOptions, (s) => s.value > this.schedule[dayKey].start),
            ],
          },
          ...this.schedule[dayKey].breakStart && {
            breakEnd: _.filter(slotOptions, (s) => s.value > this.schedule[dayKey].breakStart),
          },

          ...this.schedule[dayKey].start && {
            end: _.filter(slotOptions, (s) => s.value > (this.schedule[dayKey].breakEnd || this.schedule[dayKey].breakStart || this.schedule[dayKey].start)),
          },
        };
      });
      return options;
    },
  },
  watch: {
    schedule: {
      deep: true,
      handler() {
        // ensure all values get cleared
        _.each(this.schedule, (day) => {
          if (!day.start) {
            day.end = null;
            day.breakStart = null;
            day.breakEnd = null;
          } else if (!day.breakStart) {
            day.breakEnd = null;
          }
        });
        this.$emit('input', _.cloneDeep(this.schedule));
      },
    },
    value: {
      handler() {
        // this is kind of weird but if the user clicks "UNDO" and it sets the schedul back to `[]`
        // then this will break, so we set it back to the default schedule
        // this will only happen if a new coach is setting their schedule for the first time
        if (!_.isEqual(this.value, this.schedule)) {
          if (this.value.length) this.schedule = this.value;
          else this.schedule = DEFAULT_SCHEDULE;
        }
      },
    },
  },
  methods: {
  },
};
</script>

<style lang='less'>
.schedule-editor {
  .schedule-editor__day {
    display: flex;
    margin-bottom: 4px;
  }
  .schedule-editor__day-label {
    font-weight: 700;
    text-transform: uppercase;
    width: 50px;
    line-height: 40px;
    padding: 3px 0;
    text-align: right;
    padding-right: 5px;
  }

  .schedule-editor__time-inputs {
    flex: 1 0 0;
    display: flex;

    .form-input {
      flex: 1 0 0;
      border: none;
      padding: 3px 6px;
    }
    .form-input-error {
      display: none;
    }

    .schedule-editor__break-start-input {
      background: #EEE;
    }
    .schedule-editor__break-end-input {
      background: #EEE;
    }
  }

  .schedule-editor__break-label {
    line-height: 46px;
    font-weight: 700;
    // padding: 0 1px;
    background: #EEE;

  }


  .schedule-editor__header {
    display: flex;
    padding: 0px 15px;
    font-weight: 700;
    text-transform: uppercase;
    // justify-content: space-between;
    > * {
      flex: 1 0 0;
    }
  }
  .schedule-editor__header-start {
    margin-left: 50px;
  }
  .schedule-editor__header-break {
    text-align: center;
  }
  .schedule-editor__header-end {
    text-align: right;
  }
}

</style>
