export const EVAL_CATEGORIES = {
  relationship_context: {
    label: 'Relationship context',
  },
  planning: {
    label: 'Planning',
  },
  delivering: {
    label: 'Delivering',
  },
  analysis_decision_making: {
    label: 'Analysis & Decision Making',
  },
  communication: {
    label: 'Communication',
  },
  self_development: {
    label: 'Self-Development',
  },
  working_with_others: {
    label: 'Working with others',
  },
  managing_others: {
    label: 'Managing Others',
  },
  leadership: {
    label: 'Leadership',
  },
  general_mood: {
    label: 'General mood',
  },
  stress_management: {
    label: 'Stress management',
  },
  intrapersonal: {
    label: 'Intrapersonal',
  },
  summary: {
    label: 'Summary / Open-Ended',
  },
};
